/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import App from './App.js';
import Dashboard from './views/Admin/Dashboard.js';
import Players from './views/Admin/Players.js';
import Collection from './views/Collection/Collection.js';
import Exchange from './views/Exchange/Exchange.js';
import Premium from './views/Homepage/Premium.js';
import Privacy from './views/Homepage/Privacy.js';
import Terms from './views/Homepage/Terms.js';
import Items from './views/Items/Items.js';
import Orders from './views/Orders/Orders.js';
import Shop from './views/Shop/Shop.js';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    display: {
      en: 'Dashboard',
      fr: 'Dashboard',
      es: 'Dashboard',
    },
    icon: 'tim-icons icon-chart-bar-32',
    component: Dashboard,
    admin: true,
    layout: '/admin',
  },
  {
    path: '/players',
    name: 'Players',
    display: {
      en: 'Players',
      fr: 'Players',
      es: 'Players',
    },
    icon: 'tim-icons icon-badge',
    component: Players,
    admin: true,
    layout: '/admin',
  },
  {
    path: '/pack',
    name: 'Top up',
    display: {
      en: 'Top up',
      fr: 'Recharger',
      es: 'Top up',
    },
    icon: 'tim-icons icon-coins',
    component: Shop,
    layout: '/shop',
  },
  {
    path: '/items',
    name: 'Shop',
    display: {
      en: 'Shop',
      fr: 'Shop',
      es: 'Shop',
    },
    icon: 'tim-icons icon-key-25',
    component: Items,
    layout: '/shop',
  },
  {
    path: '/collection',
    name: 'Collection',
    display: {
      en: 'Collection',
      fr: 'Collection',
      es: 'Collection',
    },
    icon: 'tim-icons icon-wallet-43',
    component: Collection,
    layout: '/shop',
  },
  // {
  //   path: '/exchange',
  //   name: 'Exchange',
  //   display: {
  //     en: 'Exchange',
  //     fr: 'Exchange',
  //     es: 'Exchange',
  //   },
  //   icon: 'tim-icons icon-bank',
  //   component: Exchange,
  //   layout: '/shop',
  // },
  {
    path: '/orders',
    name: 'Orders',
    display: {
      en: 'Orders',
      fr: 'Commandes',
      es: 'Orders',
    },
    icon: 'tim-icons icon-paper',
    component: Orders,
    layout: '/shop',
  },
  {
    path: '/premium',
    name: 'Premium',
    display: {
      en: 'Premium',
      fr: 'Premium',
      es: 'Premium',
    },
    icon: 'tim-icons icon-shape-star',
    externalLink: 'https://www.patreon.com/FakeRPG',
    layout: '/shop',
  },
  {
    path: '/logout',
    name: 'Logout',
    display: {
      en: 'Logout',
      fr: 'Déconnexion',
      es: 'Logout',
    },
    className: 'logout',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-button-power',
    // component: Dashboard,
    layout: '/shop',
  },
  {
    path: '/',
    name: 'Home',
    display: {
      en: 'Home',
      fr: 'Home',
      es: 'Home',
    },
    icon: 'tim-icons icon-key-25',
    component: App,
    layout: '/home',
  },
  {
    path: '/premium',
    name: 'Premium',
    display: {
      en: 'Premium',
      fr: 'Premium',
      es: 'Premium',
    },
    icon: 'tim-icons icon-key-25',
    component: Premium,
    layout: '/home',
  },
  {
    path: '/terms',
    name: 'Terms of Service',
    display: {
      en: 'Terms of Service',
      fr: 'Terms of Service',
      es: 'Terms of Service',
    },
    icon: 'tim-icons icon-key-25',
    component: Terms,
    layout: '/home',
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    display: {
      en: 'Privacy Policy',
      fr: 'Privacy Policy',
      es: 'Privacy Policy',
    },
    icon: 'tim-icons icon-key-25',
    component: Privacy,
    layout: '/home',
  },
  // {
  //   collapse: true,
  //   name: 'Pages',
  //   rtlName: 'صفحات',
  //   icon: 'tim-icons icon-image-02',
  //   state: 'pagesCollapse',
  //   views: [
  //     {
  //       path: '/pricing',
  //       name: 'Pricing',
  //       rtlName: 'عالتسعير',
  //       mini: 'P',
  //       rtlMini: 'ع',
  //       component: Pricing,
  //       layout: '/auth',
  //     },
  //     {
  //       path: '/rtl-support',
  //       name: 'RTL Support',
  //       rtlName: 'صودعم رتل',
  //       mini: 'RS',
  //       rtlMini: 'صو',
  //       component: Rtl,
  //       layout: '/rtl',
  //     },
  //     {
  //       path: '/timeline',
  //       name: 'Timeline',
  //       rtlName: 'تيالجدول الزمني',
  //       mini: 'T',
  //       rtlMini: 'تي',
  //       component: Timeline,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/login',
  //       name: 'Login',
  //       rtlName: 'هعذاتسجيل الدخول',
  //       mini: 'L',
  //       rtlMini: 'هعذا',
  //       component: Login,
  //       layout: '/auth',
  //     },
  //     {
  //       path: '/register',
  //       name: 'Register',
  //       rtlName: 'تسجيل',
  //       mini: 'R',
  //       rtlMini: 'صع',
  //       component: Register,
  //       layout: '/auth',
  //     },
  //     {
  //       path: '/lock-screen',
  //       name: 'Lock Screen',
  //       rtlName: 'اقفل الشاشة',
  //       mini: 'LS',
  //       rtlMini: 'هذاع',
  //       component: Lock,
  //       layout: '/auth',
  //     },
  //     {
  //       path: '/user-profile',
  //       name: 'User Profile',
  //       rtlName: 'ملف تعريفي للمستخدم',
  //       mini: 'UP',
  //       rtlMini: 'شع',
  //       component: User,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Components',
  //   rtlName: 'المكونات',
  //   icon: 'tim-icons icon-molecule-40',
  //   state: 'componentsCollapse',
  //   views: [
  //     {
  //       collapse: true,
  //       name: 'Multi Level Collapse',
  //       rtlName: 'انهيار متعدد المستويات',
  //       mini: 'MLT',
  //       rtlMini: 'ر',
  //       state: 'multiCollapse',
  //       views: [
  //         {
  //           path: '/buttons',
  //           name: 'Buttons',
  //           rtlName: 'وصفت',
  //           mini: 'B',
  //           rtlMini: 'ب',
  //           component: Buttons,
  //           layout: '/admin',
  //         },
  //       ],
  //     },
  //     {
  //       path: '/buttons',
  //       name: 'Buttons',
  //       rtlName: 'وصفت',
  //       mini: 'B',
  //       rtlMini: 'ب',
  //       component: Buttons,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/grid-system',
  //       name: 'Grid System',
  //       rtlName: 'نظام الشبكة',
  //       mini: 'GS',
  //       rtlMini: 'زو',
  //       component: Grid,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/panels',
  //       name: 'Panels',
  //       rtlName: 'لوحات',
  //       mini: 'P',
  //       rtlMini: 'ع',
  //       component: Panels,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/sweet-alert',
  //       name: 'Sweet Alert',
  //       rtlName: 'الحلو تنبيه',
  //       mini: 'SA',
  //       rtlMini: 'ومن',
  //       component: SweetAlert,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/notifications',
  //       name: 'Notifications',
  //       rtlName: 'إخطارات',
  //       mini: 'N',
  //       rtlMini: 'ن',
  //       component: Notifications,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/icons',
  //       name: 'Icons',
  //       rtlName: 'الرموز',
  //       mini: 'I',
  //       rtlMini: 'و',
  //       component: Icons,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/typography',
  //       name: 'Typography',
  //       rtlName: 'طباعة',
  //       mini: 'T',
  //       rtlMini: 'ر',
  //       component: Typography,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Forms',
  //   rtlName: 'إستمارات',
  //   icon: 'tim-icons icon-notes',
  //   state: 'formsCollapse',
  //   views: [
  //     {
  //       path: '/regular-forms',
  //       name: 'Regular Forms',
  //       rtlName: 'أشكال عادية',
  //       mini: 'RF',
  //       rtlMini: 'صو',
  //       component: RegularForms,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/extended-forms',
  //       name: 'Extended Forms',
  //       rtlName: 'نماذج موسعة',
  //       mini: 'EF',
  //       rtlMini: 'هوو',
  //       component: ExtendedForms,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/validation-forms',
  //       name: 'Validation Forms',
  //       rtlName: 'نماذج التحقق من الصحة',
  //       mini: 'VF',
  //       rtlMini: 'تو',
  //       component: ValidationForms,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/wizard',
  //       name: 'Wizard',
  //       rtlName: 'ساحر',
  //       mini: 'W',
  //       rtlMini: 'ث',
  //       component: Wizard,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Tables',
  //   rtlName: 'الجداول',
  //   icon: 'tim-icons icon-puzzle-10',
  //   state: 'tablesCollapse',
  //   views: [
  //     {
  //       path: '/regular-tables',
  //       name: 'Regular Tables',
  //       rtlName: 'طاولات عادية',
  //       mini: 'RT',
  //       rtlMini: 'صر',
  //       component: RegularTables,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/extended-tables',
  //       name: 'Extended Tables',
  //       rtlName: 'جداول ممتدة',
  //       mini: 'ET',
  //       rtlMini: 'هور',
  //       component: ExtendedTables,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/react-tables',
  //       name: 'React Tables',
  //       rtlName: 'رد فعل الطاولة',
  //       mini: 'RT',
  //       rtlMini: 'در',
  //       component: ReactTables,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Maps',
  //   rtlName: 'خرائط',
  //   icon: 'tim-icons icon-pin',
  //   state: 'mapsCollapse',
  //   views: [
  //     {
  //       path: '/google-maps',
  //       name: 'Google Maps',
  //       rtlName: 'خرائط جوجل',
  //       mini: 'GM',
  //       rtlMini: 'زم',
  //       component: GoogleMaps,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/full-screen-map',
  //       name: 'Full Screen Map',
  //       rtlName: 'خريطة كاملة الشاشة',
  //       mini: 'FSM',
  //       rtlMini: 'ووم',
  //       component: FullScreenMap,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/vector-map',
  //       name: 'Vector Map',
  //       rtlName: 'خريطة المتجه',
  //       mini: 'VM',
  //       rtlMini: 'تم',
  //       component: VectorMap,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   path: '/widgets',
  //   name: 'Widgets',
  //   rtlName: 'الحاجيات',
  //   icon: 'tim-icons icon-settings',
  //   component: Widgets,
  //   layout: '/admin',
  // },
  // {
  //   path: '/charts',
  //   name: 'Charts',
  //   rtlName: 'الرسوم البيانية',
  //   icon: 'tim-icons icon-chart-bar-32',
  //   component: Charts,
  //   layout: '/admin',
  // },
  // {
  //   path: '/calendar',
  //   name: 'Calendar',
  //   rtlName: 'التقويم',
  //   icon: 'tim-icons icon-time-alarm',
  //   component: Calendar,
  //   layout: '/admin',
  // },
];

export default routes;
