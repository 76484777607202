/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// react plugin for creating notifications over the dashboard
// import NotificationAlert from 'react-notification-alert';

// core components
import UserNavbar from '../../components/Navbars/UserNavbar.js';
// import Footer from '../../components/Footer/Footer.js';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import FixedPlugin from '../../components/FixedPlugin/FixedPlugin.js';

import routes from '../../routes.js';

// import logo from '../../assets/img/react-logo.png';
// import logo from '../../logo.svg';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import { Card, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import VipPass from '../../components/Vip/VipPass.js';

var ps;

const Global = (props) => {
  const [activeColor, setActiveColor] = React.useState('blue');
  const [sidebarMini, setSidebarMini] = React.useState(true);
  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false); // voir pour activer par defaut
  const mainPanelRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);
  const location = useLocation();
  const { user } = useContext(AuthenticatedContext);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  React.useEffect(() => {
    let innerMainPanelRef = mainPanelRef;
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.classList.add('perfect-scrollbar-on');
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(mainPanelRef.current);
      mainPanelRef.current && mainPanelRef.current.addEventListener('ps-scroll-y', showNavbarButton);
      let tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener('scroll', showNavbarButton);
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.documentElement.classList.add('perfect-scrollbar-off');
        document.documentElement.classList.remove('perfect-scrollbar-on');
        innerMainPanelRef.current && innerMainPanelRef.current.removeEventListener('ps-scroll-y', showNavbarButton);
      }
      window.removeEventListener('scroll', showNavbarButton);
    };
  }, []);
  const showNavbarButton = () => {
    if (document.documentElement.scrollTop > 50 || document.scrollingElement.scrollTop > 50 || (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)) {
      setOpacity(1);
    } else if (document.documentElement.scrollTop <= 50 || document.scrollingElement.scrollTop <= 50 || (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)) {
      setOpacity(0);
    }
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/shop') {
        return <Route exact path={prop.layout + prop.path} component={prop.component} activeColor={activeColor} key={key} />;
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleMiniClick = () => {
    let notifyMessage = 'Sidebar mini ';
    if (document.body.classList.contains('sidebar-mini')) {
      setSidebarMini(false);
      notifyMessage += 'deactivated...';
    } else {
      setSidebarMini(true);
      notifyMessage += 'activated...';
    }
    let options = {};
    options = {
      place: 'tr',
      message: notifyMessage,
      type: 'primary',
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
    document.body.classList.toggle('sidebar-mini');
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle('nav-open');
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove('nav-open');
  };
  return (
    <div className='wrapper'>
      {/* <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div> */}
      <div className='navbar-minimize-fixed' style={{ opacity: opacity }}>
        <button className='minimize-sidebar btn btn-link btn-just-icon' onClick={handleMiniClick}>
          <i className='tim-icons icon-align-center visible-on-sidebar-regular text-muted' />
          <i className='tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted' />
        </button>
      </div>
      <Sidebar
        {...props}
        routes={routes}
        layout={props.layout}
        activeColor={activeColor}
        logo={{
          outterLink: '#',
          text: user.username,
          imgSrc: user.avatar,
        }}
        user={{
          fakecoin: user.money,
          lootcoin: user.lootbox,
          shopcoin: user.shopcoin,
          premium: user.premium,
          ornament: user.ornament,
          username: user.username,
          id: user.id,
          lang: user.lang,
        }}
        closeSidebar={closeSidebar}
      />
      <div id='main-panel' className='main-panel' ref={mainPanelRef} data={activeColor} color={activeColor}>
        <UserNavbar {...props} handleMiniClick={handleMiniClick} brandText={getActiveRoute(routes)} sidebarOpened={sidebarOpened} toggleSidebar={toggleSidebar} />
        <div className='content'>
          <Row>
            <Col xs='12'>
              <VipPass user={user} />
              {/* <Card className='shopbanner'>
                <CardHeader>
                  <Row>
                    <Col className='text-left' sm='6'>
                      <h5 className='card-category'>{}</h5>
                      <CardTitle className='shop-title' tag='h1'>
                        Fake RPG
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
              </Card> */}
            </Col>
          </Row>
          <Switch>
            {getRoutes(routes)}
            <Redirect from='*' to='/shop/items' />
          </Switch>
        </div>
        {
          // we don't want the Footer to be rendered on full screen maps page
          // props.location.pathname.indexOf('full-screen-map') !== -1 ? null : <Footer fluid />
        }
      </div>
      {/* <FixedPlugin activeColor={activeColor} sidebarMini={sidebarMini} handleActiveClick={handleActiveClick} handleMiniClick={handleMiniClick} /> */}
    </div>
  );
};

export default Global;
