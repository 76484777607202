import axios from 'axios';
import { API } from './env';

export const getOrders = async () => {
  const res = await axios.get(`${API}order/get`);
  return res.data;
};

export const getExchanges = async () => {
  const res = await axios.post(`${API}exchange/get/`);
  return res.data;
};

export const createExchange = async (amount, type) => {
  const res = await axios.post(`${API}exchange/new`, {
    exchange: {
      amount: amount,
      type: type,
    },
  });
  return res.data;
};

export const createOrder = async (order) => {
  const res = await axios.post(`${API}admin/orders/new`, {
    order,
  });
  return res.data;
};

export const getPastOrders = async (days) => {
  const res = await axios.post(`${API}admin/orders/get`, {
    days: days,
  });
  return res.data;
};

export const getGameStats = async () => {
  const res = await axios.post(`${API}admin/stats/get/all`);
  return res.data;
};

export const getLoginStats = async () => {
  const res = await axios.get(`${API}admin/stats/login/last`);
  return res.data;
};

export const getAllLoginStats = async () => {
  const res = await axios.get(`${API}admin/stats/login/all`);
  return res.data;
};

export const getPlayers = async (player) => {
  const res = await axios.post(`${API}admin/users/get`, {
    player: player,
  });
  return res.data;
};

export const getPlayerByUid = async (uid) => {
  const res = await axios.post(`${API}admin/users/get/id`, {
    uid: uid,
  });
  return res.data;
};

export const getOrdersByUid = async (uid) => {
  const res = await axios.post(`${API}admin/orders/get/uid`, {
    uid: uid,
  });
  return res.data;
};

export const getExchangesByUid = async (uid) => {
  const res = await axios.post(`${API}admin/exchanges/get/uid`, {
    uid: uid,
  });
  return res.data;
};

export const getPastExchanges = async (limit) => {
  const res = await axios.post(`${API}admin/exchanges/get/`, {
    limit: limit,
  });
  return res.data;
};

// items

export const getItems = async (item, owned) => {
  const res = await axios.post(`${API}items/get`, {
    item: item,
    owned: owned ? true : false,
  });
  return res.data;
};

export const buyAnItem = async (item, id) => {
  const res = await axios.post(`${API}items/buy`, {
    item: item,
    id: id,
  });
  return res.data;
};

export const equipItem = async (item, id) => {
  const res = await axios.post(`${API}items/equip`, {
    item: item,
    id: id,
  });
  return res.data;
};

export const vipLevelup = async () => {
  const res = await axios.post(`${API}items/vip`);
  return res.data;
  // return { status: 'success' };
};
