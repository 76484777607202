import React, { Fragment, useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Table } from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/fr';
import { numberWithSpaces } from '../../helpers/functions';
// core components
// import { chartExample1, chartExample2, chartExample3, chartExample4 } from '../../variables/charts.js';
import { Line, Doughnut } from 'react-chartjs-2';
import { getAllLoginStats, getGameStats, getLoginStats, getPastExchanges, getPastOrders } from '../../helpers/API.js';
import Loading from '../../components/Loading.js';
import { fakecoin, lootcoin, shopcoin } from '../../helpers/env';
import { Link } from 'react-router-dom';
import defaultAvatar from '../../assets/img/default_avatar.png';
import Avatar from '../../components/Misc/Avatar';
import LoginHistory from '../../components/Admin/Players/DataCards/loginHistory';
let chart_1_2_3_options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: 'transparent',
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: '#9a9a9a',
        },
      },
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.1)',
          zeroLineColor: 'transparent',
        },
        ticks: {
          padding: 20,
          fontColor: '#9a9a9a',
        },
      },
    ],
  },
};

moment().format();
moment.locale('fr');

const Dashboard = () => {
  const [bigChartData, setbigChartData] = useState('data1');
  const [yearSales, setYearSales] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [yearLogin, setYearLogin] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [yearEstimatedLogin, setEstimatedYearLogin] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [loadDays, setLoadDays] = useState(10);
  const [yearPlayers, setYearPlayers] = useState([
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
    {
      total: 0,
      fr: 0,
      en: 0,
      es: 0,
    },
  ]);
  const [yearServers, setYearServers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [premiums, setPremiums] = useState([0, 0, 0]);
  const [orders, setOrders] = useState(null);
  const [exchanges, setExchanges] = useState(null);
  const [stats, setStats] = useState(null);
  const [loginHistory, setLoginHistory] = useState(null);
  const now = new Date();
  const baseYear = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const relativeYear = [...baseYear.slice(now.getMonth() + 1), ...baseYear.slice(0, now.getMonth() + 1)];
  let estimated = [...yearSales];
  estimated[estimated.length - 1] = Math.floor((estimated[estimated.length - 1] / now.getDate()) * 31);
  let salesChart = {
    data1: (canvas) => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

      return {
        labels: relativeYear,
        datasets: [
          {
            label: "Chiffre d'affaire",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1f8ef1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1f8ef1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: yearSales,
          },
          {
            label: 'Prévisionnel',
            fill: true,
            backgroundColor: 'transparent',
            borderColor: 'rgb(90, 191, 154)',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: 'rgb(90, 191, 154)',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: 'rgb(90, 191, 154)',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 2,
            data: estimated,
          },
        ],
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors
      return {
        labels: relativeYear,
        datasets: [
          {
            label: 'Joueurs actifs',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1f8ef1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1f8ef1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: yearPlayers.map((p) => p.total),
          },
          {
            label: 'Français',
            fill: true,
            backgroundColor: 'transparent',
            borderColor: '#0055A4',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#0055A4',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#0055A4',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 2,
            data: yearPlayers.map((p) => p.fr),
          },
          {
            label: 'Anglais',
            fill: true,
            backgroundColor: 'transparent',
            borderColor: '#C8102E',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#C8102E',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#C8102E',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 2,
            data: yearPlayers.map((p) => p.en),
          },
          {
            label: 'Espagnol',
            fill: true,
            backgroundColor: 'transparent',
            borderColor: '#f1bf00',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#f1bf00',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#f1bf00',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 2,
            data: yearPlayers.map((p) => p.es),
          },
        ],
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

      return {
        labels: relativeYear,
        datasets: [
          {
            label: 'Serveurs actifs',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1f8ef1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1f8ef1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: yearServers,
          },
        ],
      };
    },
    data4: (canvas) => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

      return {
        labels: relativeYear,
        datasets: [
          {
            label: 'Connexions',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1f8ef1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1f8ef1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: yearLogin,
          },
          {
            label: 'Prévisionnel',
            fill: true,
            backgroundColor: 'transparent',
            borderColor: 'rgb(90, 191, 154)',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: 'rgb(90, 191, 154)',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: 'rgb(90, 191, 154)',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 2,
            data: yearEstimatedLogin,
          },
        ],
      };
    },
    options: chart_1_2_3_options,
  };
  let premiumsChart = {
    data: {
      labels: [1, 2, 3],
      datasets: [
        {
          label: 'Premiums',
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ['rgb(243 212 88)', 'rgb(213 75 66)', 'rgb(90, 191, 154)'],
          borderWidth: 0,
          data: premiums,
        },
      ],
    },
    options: {
      cutoutPercentage: 70,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      scales: {
        x: {
          grid: {
            display: false,
            borderWidth: 0,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
            borderWidth: 0,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };

  const setBgChartData = (name) => {
    setbigChartData(name);
  };
  const fetchAllOrders = async (days) => {
    const fetchOrders = await getPastOrders(days);
    setOrders(fetchOrders.reverse());
  };
  const fetchSomeExchanges = async () => {
    const fetchExchanges = await getPastExchanges(10);
    setExchanges(fetchExchanges);
  };

  const fetchAllStats = async () => {
    const now = new Date();
    const fetchStats = await getGameStats();
    setPremiums([fetchStats.premium.one, fetchStats.premium.two, fetchStats.premium.three]);
    setStats(fetchStats);
    const month = new Date();
    const salesHistory = [];
    const playersHistory = [];
    const serversHistory = [];
    salesHistory.push(fetchStats.history[now.getFullYear()].orders[now.getMonth()]);
    playersHistory.push(fetchStats.history[now.getFullYear()].players[now.getMonth()]);
    serversHistory.push(fetchStats.history[now.getFullYear()].servers[now.getMonth()]);
    for (let i = 1; i < 12; i++) {
      month.setMonth(month.getMonth() - 1);
      salesHistory.push(fetchStats.history[month.getFullYear()].orders[month.getMonth()]);
      playersHistory.push(fetchStats.history[month.getFullYear()].players[month.getMonth()]);
      serversHistory.push(fetchStats.history[month.getFullYear()].servers[month.getMonth()]);
    }
    salesHistory.reverse();
    playersHistory.reverse();
    serversHistory.reverse();
    setStats({
      ...fetchStats,
      orders: {
        ...fetchStats.orders,
        preview: salesHistory.reduce((previousValue, currentValue) => previousValue + currentValue),
      },
    });
    setYearSales(salesHistory);
    setYearPlayers(playersHistory);
    setYearServers(serversHistory);
  };
  useEffect(() => {
    // get number of days of the month
    fetchAllStats();
    fetchAllOrders(loadDays);
    fetchSomeExchanges();
    loadLoginHistory();
    loadAllLoginHistory();
  }, []);

  const loadMoreOrders = (days) => {
    fetchAllOrders(loadDays + days);
    setLoadDays(loadDays + days);
  };

  const loadAllLoginHistory = async () => {
    const rawAllHistory = await getAllLoginStats();
    const tab = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (let history of rawAllHistory) {
      const date = new Date(history._seconds * 1000);
      tab[date.getMonth() % 12] += 1;
    }
    const finalTab = [...tab.slice(now.getMonth() + 1), ...tab.slice(0, now.getMonth() + 1)];
    const estimated = [...finalTab];
    estimated[estimated.length - 1] = Math.floor((estimated[estimated.length - 1] / now.getDate()) * 31);
    setYearLogin(finalTab);
    setEstimatedYearLogin(estimated);
  };

  const loadLoginHistory = async () => {
    // const fetchLoginHistory = await getLoginStats();
    const rawHistory = await getLoginStats();
    rawHistory.reverse();
    const tab = [];
    rawHistory.forEach((history) => {
      tab.push({
        data: [
          {
            text: (
              <Fragment>
                <Link className='login-history' to={`players/${history.user.id}`}>
                  <div className='photo mr-3'>
                    <img alt={`${history.user.username}'s avatar`} src={history.user.avatar} />
                  </div>{' '}
                  <div>{history.user.username}</div>
                </Link>
              </Fragment>
            ),
            string: history.user.username,
          },
          { text: moment(history.date._seconds * 1000).format('lll'), string: history.date._seconds },
        ],
      });
    });
    if (tab.length === 0) {
      tab.push({
        data: [
          { text: '', string: 'Nothing' },
          { text: '', string: 'Nothing' },
        ],
      });
    }
    setLoginHistory(tab);
  };

  if (orders) {
    var ordersTab = orders.map((order, key) => {
      return (
        <tr key={key}>
          <td className='text-center'>
            <Avatar user={order.user} />
          </td>
          <td>
            <Link to={`players/${order.user.id}`}>{order.user.username}</Link>
          </td>
          <td>{moment(new Date(order.update_time)).format('LLL')}</td>
          <td>{order.description}</td>
          <td className='text-center'>{order.price} €</td>
          <td className='text-center'>
            {order.manual_transaction ? <i className='tim-icons icon-simple-remove text-danger' /> : <i className='tim-icons icon-check-2 text-success' />}
          </td>
        </tr>
      );
    });
  }
  if (exchanges) {
    var exchangesTab = exchanges.map((exchanges, key) => {
      return (
        <tr key={key}>
          <td className='text-center'>
            <div className='photo'>
              <img alt={`${exchanges.user.name} avatar`} src={exchanges.user.avatar} />
            </div>
          </td>
          <td>
            <Link to={`players/${exchanges.user.id}`}>{exchanges.user.name}</Link>
          </td>
          <td>{moment(new Date(exchanges.date._seconds * 1000)).format('LLL')}</td>
          <td className='text-center'>
            {numberWithSpaces(exchanges.amount)} {shopcoin}
          </td>
          {exchanges.type === 'fakecoin' ? (
            <td className='text-center'>
              {numberWithSpaces(exchanges.fake)} {fakecoin}
            </td>
          ) : (
            <td className='text-center'>
              {numberWithSpaces(exchanges.loot)} {lootcoin}
            </td>
          )}
          <td className='text-center'>
            <i className='tim-icons icon-check-2 text-success' />
          </td>
        </tr>
      );
    });
  }
  return (
    <Fragment>
      <Row>
        {/* users stats */}
        <Col lg='3' md='6'>
          <Card className='card-stats'>
            <CardBody>
              <Row>
                <div>
                  <div className='info-icon text-center icon-success'>
                    <i className='tim-icons icon-single-02' />
                  </div>
                </div>
                <div>
                  <div className='numbers'>
                    <p className='card-category'>Joueurs</p>
                    {stats && stats.players ? (
                      <CardTitle tag='h3'>
                        {numberWithSpaces(stats.players.active)} / {numberWithSpaces(stats.players.total)}
                      </CardTitle>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className='stats'>
                <i className='tim-icons icon-refresh-01' />{' '}
                {stats && stats.players ? (
                  <span>
                    Dernière mise à jour <i>{moment(new Date(stats.date._seconds * 1000)).calendar()}</i>
                  </span>
                ) : (
                  <Loading />
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
        {/* servers stats */}
        <Col lg='3' md='6'>
          <Card className='card-stats'>
            <CardBody>
              <Row>
                <div>
                  <div className='info-icon text-center icon-info'>
                    <i className='tim-icons icon-world' />
                  </div>
                </div>
                <div>
                  <div className='numbers'>
                    <p className='card-category'>Serveurs</p>
                    {stats && stats.servers ? <CardTitle tag='h3'>{numberWithSpaces(stats.servers)}</CardTitle> : <Loading />}
                  </div>
                </div>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className='stats'>
                <i className='tim-icons icon-refresh-01' />{' '}
                {stats && stats.players ? (
                  <span>
                    Dernière mise à jour <i>{moment(new Date(stats.date._seconds * 1000)).calendar()}</i>
                  </span>
                ) : (
                  <Loading />
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
        {/* premium stats */}
        <Col lg='3' md='6'>
          <Card className='card-stats'>
            <CardBody>
              <Row>
                <div>
                  <div className='info-icon text-center icon-primary'>
                    <i className='tim-icons icon-shape-star' />
                  </div>
                </div>
                <div>
                  <div className='numbers'>
                    <p className='card-category'>Premium</p>
                    {stats && stats.premium ? <CardTitle tag='h3'>{numberWithSpaces(stats.premium.total)}</CardTitle> : <Loading />}
                  </div>
                </div>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className='stats'>
                <i className='tim-icons icon-refresh-01' />{' '}
                {stats && stats.players ? (
                  <span>
                    Dernière mise à jour <i>{moment(new Date(stats.date._seconds * 1000)).calendar()}</i>
                  </span>
                ) : (
                  <Loading />
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
        {/* orders stats */}
        <Col lg='3' md='6'>
          <Card className='card-stats'>
            <CardBody>
              <Row>
                <div>
                  <div className='info-icon text-center icon-warning'>
                    <i className='tim-icons icon-cart' />
                  </div>
                </div>
                <div>
                  <div className='numbers'>
                    <p className='card-category'>Chiffre d'affaire</p>
                    {stats && stats.orders ? (
                      <CardTitle tag='h3'>
                        {numberWithSpaces(Math.floor(stats.orders.total.amount), true)} €
                        <div className='text-muted'>{stats.orders.preview && numberWithSpaces(Math.floor(stats.orders.preview / 12), true)} €/mois</div>
                      </CardTitle>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className='stats'>
                <i className='tim-icons icon-refresh-01' />{' '}
                {stats && stats.players ? (
                  <span>
                    Dernière mise à jour <i>{moment(new Date()).calendar()}</i>
                  </span>
                ) : (
                  <Loading />
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      {/* Charts */}
      <Row>
        <Col xs='12'>
          <Card className='card-chart'>
            <CardHeader>
              <Row>
                <Col className='text-left' sm='6'>
                  <h5 className='card-category'>{new Date().getFullYear()}</h5>
                  <CardTitle tag='h2'>Statistiques</CardTitle>
                </Col>
                <Col sm='6'>
                  <ButtonGroup className='btn-group-toggle float-right' data-toggle='buttons'>
                    <Button
                      color='info'
                      id='0'
                      size='sm'
                      tag='label'
                      className={classNames('btn-simple', {
                        active: bigChartData === 'data1',
                      })}
                      onClick={() => setBgChartData('data1')}
                    >
                      <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>Ventes</span>
                      <span className='d-block d-sm-none'>
                        <i className='tim-icons icon-cart' />
                      </span>
                    </Button>
                    <Button
                      color='info'
                      id='1'
                      size='sm'
                      tag='label'
                      className={classNames('btn-simple', {
                        active: bigChartData === 'data2',
                      })}
                      onClick={() => setBgChartData('data2')}
                    >
                      <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>Joueurs</span>
                      <span className='d-block d-sm-none'>
                        <i className='tim-icons icon-single-02' />
                      </span>
                    </Button>
                    <Button
                      color='info'
                      id='2'
                      size='sm'
                      tag='label'
                      className={classNames('btn-simple', {
                        active: bigChartData === 'data3',
                      })}
                      onClick={() => setBgChartData('data3')}
                    >
                      <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>Serveurs</span>
                      <span className='d-block d-sm-none'>
                        <i className='tim-icons icon-world' />
                      </span>
                    </Button>
                    <Button
                      color='info'
                      id='2'
                      size='sm'
                      tag='label'
                      className={classNames('btn-simple', {
                        active: bigChartData === 'data4',
                      })}
                      onClick={() => setBgChartData('data4')}
                    >
                      <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>Connexions</span>
                      <span className='d-block d-sm-none'>
                        <i className='tim-icons icon-world' />
                      </span>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className='chart-area'>
                <Line data={salesChart[bigChartData]} options={salesChart.options} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg='8'>
          <Card className='height-max'>
            <CardHeader>
              {/* <div className='tools float-right'>
                <UncontrolledDropdown>
                  <DropdownToggle caret className='btn-icon' color='link' data-toggle='dropdown' type='button'>
                    <i className='tim-icons icon-settings-gear-63' />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                      Action
                    </DropdownItem>
                    <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                      Another action
                    </DropdownItem>
                    <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                      Something else
                    </DropdownItem>
                    <DropdownItem className='text-danger' href='#pablo' onClick={(e) => e.preventDefault()}>
                      Remove Data
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
              <CardTitle tag='h2'>Dernières ventes</CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead className='text-primary'>
                  <tr>
                    <th className='text-center'>#</th>
                    <th>Nom</th>
                    <th>Date</th>
                    <th>Pack</th>
                    <th className='text-center'>Prix</th>
                    <th className='text-center'>Status</th>
                  </tr>
                </thead>
                <tbody>{ordersTab ? ordersTab : <Loading />}</tbody>
              </Table>
              <div className='d-flex justify-content-evenly'>
                <Button color='info' onClick={() => loadMoreOrders(10)}>
                  Charger 10 jours
                </Button>
                <Button color='info' onClick={() => loadMoreOrders(30)}>
                  Charger 30 jours
                </Button>
                <Button color='info' onClick={() => loadMoreOrders(3650)}>
                  Charger toutes les commandes
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <LoginHistory logHistory={loginHistory} />
          <Card className='card-chart card-chart-pie'>
            <CardHeader>
              <CardTitle tag='h2'>Premiums</CardTitle>
            </CardHeader>
            <CardBody className='d-flex justify-content-center'>
              <div className='chart-area'>
                <Doughnut width='50%' height='50%' data={premiumsChart.data} options={premiumsChart.options} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* <Col>
          <Card className='card-chart card-chart-pie'>
            <CardHeader>
              <CardTitle tag='h2'>Premiums</CardTitle>
            </CardHeader>
            <CardBody className='d-flex justify-content-center'>
              <div className='chart-area'>
                <Doughnut width='50%' height='50%' data={premiumsChart.data} options={premiumsChart.options} />
              </div>
            </CardBody>
          </Card>
        </Col> */}
        <Col lg='12'>
          <Card>
            <CardHeader>
              {/* <div className='tools float-right'>
                <UncontrolledDropdown>
                  <DropdownToggle caret className='btn-icon' color='link' data-toggle='dropdown' type='button'>
                    <i className='tim-icons icon-settings-gear-63' />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                      Action
                    </DropdownItem>
                    <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                      Another action
                    </DropdownItem>
                    <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                      Something else
                    </DropdownItem>
                    <DropdownItem className='text-danger' href='#pablo' onClick={(e) => e.preventDefault()}>
                      Remove Data
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
              <CardTitle tag='h2'>Derniers Échanges</CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead className='text-primary'>
                  <tr>
                    <th className='text-center'>#</th>
                    <th>Nom</th>
                    <th>Date</th>
                    <th className='text-center'>From</th>
                    <th className='text-center'>To</th>
                    <th className='text-center'>Status</th>
                  </tr>
                </thead>
                <tbody>{exchangesTab ? exchangesTab : <Loading />}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Dashboard;
