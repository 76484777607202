import { langsList } from "./env";

export const numberWithSpaces = (x, forceSpace, lang) => {
  if (x !== null && x !== undefined && x !== '' && x.length !== 0) {
    if (x > 100000 && !forceSpace) {
      return formatCash(x, lang);
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // TODO utiliser la langue pour afficher un . au lieu d'un espace pour les nombres >1000
  } else {
    return x;
  }
};

const formatCash = (num, lang) => {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(num);
};

export const getRandomInt = (min, max) => {
  return Math.floor(min + Math.random() * Math.floor(max - min));
};

export const randDisctinctInt = (min, max, n) => {
  let numbers = [];
  let tmp;
  if (max - min < n) {
    return null;
  }
  for (let i = 0; i < n; i++) {
    while (numbers.length < n) {
      tmp = Math.floor(min + Math.random() * Math.floor(max - min));
      if (numbers.indexOf(tmp) === -1) {
        numbers.push(tmp);
      }
    }
  }
  return numbers;
};

export const capitalize = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const getLang = () => {
  const savedLang = localStorage.getItem('lang');
  return langsList.includes(savedLang) ? savedLang : 'en'
}