import React from 'react';

const Privacy = () => {
    return (
        <section className='terms'>
            <h1>Privacy Policy</h1>
            <p>We are very much aware about how important privacy is for our users. We take a lot of steps to make sure your data remains confidential and protected.</p>
            <h2>DATA WE COLLECT</h2>
            <p>By using the bot you understand, and consent us to, collect the following data:</p>
            <ul>
                <li>Verify you by using IP Address to prevent alts and bad actors</li>
                <li>Usernames, email, guilds, avatars, and discriminators</li>
            </ul>
            <p>By visiting or logging in on to the website fake.gg or any of its related domains or subdomains, we may collect the following data:</p>
            <ul>
                <li>User Id, Username and Email</li>
                <li>IP Address</li>
            </ul>
            <h2>HOW WE USE YOUR DATA</h2>
            <p>Your data is used to provide essential features in the game. From providing in depth stats about gameplay and performance of your server, to providing ranks to users and servers so they can compete with each other. We also use your data to combat alters and cheaters.</p>
            <h2>WHO HAS ACCESS</h2>
            <p>Only the owner and the developers of the game have access to your data. Under no circumstance, anyone other than the mentioned above staff will be provided access to it. We take adequate measures to keep it safe and secured. We will never sell or share your information with a third party.</p>
            <h2>DATA DELETION</h2>
            <p>Any user can request us to delete their data by joining the support server. It can take up to 48 hours to remove your informations from the database and backups. We will still retain the user IDs of the players and server who are blacklisted from the game to prevent them from exploiting it.</p>
            <h2>POLICY UPDATES</h2>
            <p>Any future changes or updates to this policy, will be made aware of to our users. Keep an eye on this space to check for our policy and give us feedback to what we could do better. Feel free to contact us in our support server if you have any questions regarding our privacy policy.</p>
        </section>
    );
};

export default Privacy;