import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Modal, Progress, UncontrolledTooltip } from 'reactstrap';
import { Accordion } from 'react-bootstrap';
import { defaultAvatar, getDescriptionFromReward, getImageFromReward, shopcoin } from '../../helpers/env';
import { numberWithSpaces } from '../../helpers/functions';
import party from 'party-js';
import achievement from '../../assets/sound/achievement.mp3';
import useSound from 'use-sound';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import { vipLevelup } from '../../helpers/API';
import { getLocale } from '../../helpers/lang';
import { capitalize } from '../../helpers/functions';

const baseVipRewards = [
  {
    id: 0,
    amount: 150000,
    type: 'fakecoin',
    cost: 1000,
  },
  {
    id: 1,
    amount: 200,
    type: 'lootcoin',
    cost: 2000,
  },
  {
    id: 2,
    amount: 1,
    type: 'randomSkin',
    cost: 3000,
  },
  {
    id: 3,
    amount: 1,
    type: 'ornament',
    cost: 5000,
  },
  {
    id: 4,
    amount: 50,
    type: 'energy',
    cost: 8000,
  },
  {
    id: 5,
    amount: 5,
    type: 'energyMax',
    cost: 13000,
  },
  {
    id: 6,
    amount: 1,
    type: 'randomSkin',
    cost: 21000,
  },
  {
    id: 7,
    amount: 5,
    type: 'energyMax',
    cost: 34000,
  },
  {
    id: 8,
    amount: 1,
    type: 'ornament',
    cost: 50000,
  },
  {
    id: 9,
    amount: 7,
    type: 'premium',
  },
  {
    id: 10,
    amount: 100,
    type: 'energy',
  },
  {
    id: 11,
    amount: 5,
    type: 'energyMax',
  },
  {
    id: 12,
    amount: 1,
    type: 'randomSkin',
  },
  {
    id: 13,
    amount: 1,
    type: 'chestSilver',
  },
  {
    id: 14,
    amount: 500000,
    type: 'fakecoin',
  },
  {
    id: 15,
    amount: 500,
    type: 'lootcoin',
  },
  {
    id: 16,
    amount: 1,
    type: 'chestGold',
  },
  {
    id: 17,
    amount: 500000,
    type: 'fakecoin',
  },
  {
    id: 18,
    amount: 500,
    type: 'lootcoin',
  },
  {
    id: 19,
    amount: 1,
    type: 'chestLegendary',
  },
];

const nextVipRewards = [
  {
    id: 20,
    amount: 500000,
    type: 'fakecoin',
  },
  {
    id: 21,
    amount: 500,
    type: 'lootcoin',
  },
  {
    id: 22,
    amount: 500000,
    type: 'fakecoin',
  },
  {
    id: 23,
    amount: 500,
    type: 'lootcoin',
  },
  {
    id: 24,
    amount: 7,
    type: 'premium',
  },
  {
    id: 25,
    amount: 500000,
    type: 'fakecoin',
  },
  {
    id: 26,
    amount: 500,
    type: 'lootcoin',
  },
  {
    id: 27,
    amount: 500000,
    type: 'fakecoin',
  },
  {
    id: 28,
    amount: 500,
    type: 'lootcoin',
  },
  {
    id: 29,
    amount: 7,
    type: 'premium',
  },
  {
    id: 30,
    amount: 500000,
    type: 'fakecoin',
  },
  {
    id: 31,
    amount: 500,
    type: 'lootcoin',
  },
  {
    id: 32,
    amount: 500000,
    type: 'fakecoin',
  },
  {
    id: 33,
    amount: 500,
    type: 'lootcoin',
  },
  {
    id: 34,
    amount: 7,
    type: 'premium',
  },
  {
    id: 35,
    amount: 500000,
    type: 'fakecoin',
  },
  {
    id: 36,
    amount: 500,
    type: 'lootcoin',
  },
  {
    id: 37,
    amount: 500000,
    type: 'fakecoin',
  },
  {
    id: 38,
    amount: 500,
    type: 'lootcoin',
  },
  {
    id: 39,
    amount: 7,
    type: 'premium',
  },
];

const generatePageRewards = (level, page) => {
  page = parseInt(page);
  switch (page) {
    case 1:
      return baseVipRewards;
    case 2:
      return nextVipRewards;
    default:
      return nextVipRewards.map((reward, key) => ({ ...reward, id: key + parseInt(level / 20) * 20 }));
  }
};

const buttonNames = [
  `Thanks!`,
  `Let's go!`,
  'Awesome!',
  `Merci!`,
  `Incredible!`,
  `Fantastic!`,
  `Great!`,
  `Nice!`,
  `No cap!`,
  `Super!`,
  `Wow!`,
  `Amazing!`,
  `Seriously?!`,
  `Gimme that!`,
  `Now we're talking.`,
  `Does anyone read buttons?`,
  `42`,
  `Vip cheat activated.`,
  `00111100 00110011`,
  `Elo? Jacky? Is that you?`,
];

var claimed = new Date();
var tmpLevel = null;

const VipPass = () => {
  const { user, setUser } = useContext(AuthenticatedContext);
  const [vipRewards, setVipRewards] = useState(generatePageRewards(user.vip.level, 1 + parseInt(user.vip.level) / 20));
  const [claimOpen, setClaimOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reward, setReward] = useState(null);
  const ref = useRef([]);
  const [play] = useSound(achievement);
  const getRewardClassname = (vip) => {
    let cn = 'reward-case';
    if (vip.id < user.vip.level) {
      cn += ' reward-past';
    } else if (vip.id === user.vip.level) {
      if (canLevelUp(vip.id)) {
        cn += ' reward-unlock';
      } else {
        cn += ' reward-current';
      }
    }
    return cn;
  };
  async function confetti(id) {
    const now = new Date();
    const prevVip = user.vip.date._seconds ? new Date(user.vip.date._seconds * 1000) : user.vip.date;
    const delay = 2 * 1000;
    if (
      id !== user.vip.level ||
      user.vip.level !== tmpLevel ||
      user.shop.coin.total + user.shop.pass.day * 270 < vipRewards[user.vip.level % 20].cost ||
      now - prevVip < delay ||
      !canLevelUp(user.vip.level)
    ) {
      return;
    }
    tmpLevel = user.vip.level + 1;
    const res = await vipLevelup();
    if (res.status === 'success') {
      play();
      party.confetti(ref.current[id], {
        count: party.variation.range(300, 350),
        size: party.variation.range(1.0, 1.4),
      });
      if (res.reward) {
        setReward(res.reward);
        if (res.reward.type && res.reward.type.startsWith('chest')) {
          setUser({
            ...user,
            money: res.reward.fakecoin > 0 ? user.money + res.reward.fakecoin : user.money,
            lootbox: res.reward.lootbox > 0 ? user.lootbox + res.reward.lootbox : user.lootbox,
          });
        }
      } else {
        setReward(null);
      }
      setIsModalOpen(true);
      claimed = new Date();
      setError(null);
    } else {
      setError(`An error has occurred.`);
      setUser({
        ...user,
        vip: {
          level: user.vip.level,
          date: new Date(),
        },
      });
    }
  }

  useEffect(() => {
    if (tmpLevel) {
      setIsModalOpen(false);
      if ((user.vip.level + 1) % 20 === 0) {
        setVipRewards(generatePageRewards(user.vip.level + 1, 1 + parseInt(user.vip.level) / 20));
      }
      setUser({
        ...user,
        vip: {
          level: user.vip.level + 1,
          date: claimed,
        },
        money: vipRewards[user.vip.level % 20].type === 'fakecoin' ? user.money + vipRewards[user.vip.level % 20].amount : user.money,
        lootbox: vipRewards[user.vip.level % 20].type === 'lootcoin' ? user.lootbox + vipRewards[user.vip.level % 20].amount : user.lootbox,
      });
    }
  }, [claimed]);

  useEffect(() => {
    tmpLevel = user.vip.level;
  }, []);

  const canLevelUp = (level) => {
    let cost = getCostLevel(level);
    if (user.shop.coin.total + user.shop.pass.day * 270 >= cost) {
      return true;
    } else {
      return false;
    }
  };
  const getDiffXp = () => {
    let cost = getCostLevel(user.vip.level);
    if (user.shop.coin.total + user.shop.pass.day * 270 > cost) {
      return getDisplayXp(user.vip.level);
    } else {
      const diff = cost - (user.shop.coin.total + user.shop.pass.day * 270);
      return getDisplayXp(user.vip.level) - diff;
    }
  };
  const getCostLevel = (level) => {
    return level > 8 ? 50000 + 25000 * (level - 8) : vipRewards[level % 20].cost;
  };

  const getDisplayXp = (level) => {
    if (level === 0) return vipRewards[level % 20].cost;
    return level > 8 ? 25000 : vipRewards[level % 20].cost - vipRewards[(level - 1) % 20].cost;
  };
  const getBadge = (vip) => {
    if (vip.id < user.vip.level) {
      return (
        <Badge id={`claim-${vip.id}`} color='dark'>
          {getLocale(user.lang, 'vip', 'claimed')}
        </Badge>
      );
    } else if (vip.id === user.vip.level) {
      if (canLevelUp(vip.id)) {
        return (
          <Badge id={`claim-${vip.id}`} color='success'>
            {getLocale(user.lang, 'vip', 'claim')}
          </Badge>
        );
      } else {
        return (
          <Badge id={`claim-${vip.id}`} color='secondary'>
            {getLocale(user.lang, 'vip', 'current')}
          </Badge>
        );
      }
    }
  };

  const modalDescription = (vip) => {
    if (reward && reward.type) {
      switch (reward.type) {
        case 'skin':
          return (
            <div className='d-flex justify-content-center mb-3 display-2 text-center flex-column'>
              <div>{reward.name && reward.name}</div>
              <div>{reward.image && <img src={reward.image} alt={reward.name} />}</div>
            </div>
          );
        case 'chestSilver':
          return (
            <Fragment>
              <div className='text-center display-4 mb-3'>
                {getImageFromReward({ type: 'chestSilver' }, true)} {getLocale(user.lang, 'vip', 'silverChestOpen')}
              </div>
              <div className='d-flex flex-wrap justify-content-evenly mb-3 display-2 align-items-center text-center'>
                {reward.lootbox > 0 && (
                  <div>
                    {reward.lootbox}
                    {getImageFromReward({ type: 'lootcoin' }, true)}
                  </div>
                )}
                {reward.fakecoin > 0 && (
                  <div>
                    {numberWithSpaces(reward.fakecoin, true)}
                    {getImageFromReward({ type: 'fakecoin' }, true)}
                  </div>
                )}
                {reward.energy > 0 && (
                  <div>
                    {reward.energy}
                    {getImageFromReward({ type: 'energy' }, true)}
                  </div>
                )}
                {reward.energyMax > 0 && (
                  <div>
                    {reward.energyMax}
                    {getImageFromReward({ type: 'energyMax' }, true)}
                  </div>
                )}
                {reward.legendaryCoin > 0 && (
                  <div>
                    {reward.legendaryCoin}
                    {getImageFromReward({ type: 'legendarycoin' }, true)}
                  </div>
                )}
              </div>
            </Fragment>
          );
        case 'chestGold':
          return (
            <Fragment>
              <div className='text-center display-4 mb-3'>
                {getImageFromReward({ type: 'chestGold' }, true)} {getLocale(user.lang, 'vip', 'goldenChestOpen')}
              </div>
              <div className='d-flex flex-wrap justify-content-evenly mb-3 display-2 align-items-center text-center'>
                {reward.lootbox > 0 && (
                  <div>
                    {reward.lootbox}
                    {getImageFromReward({ type: 'lootcoin' }, true)}
                  </div>
                )}
                {reward.fakecoin > 0 && (
                  <div>
                    {numberWithSpaces(reward.fakecoin, true)}
                    {getImageFromReward({ type: 'fakecoin' }, true)}
                  </div>
                )}
                {reward.energy > 0 && (
                  <div>
                    {reward.energy}
                    {getImageFromReward({ type: 'energy' }, true)}
                  </div>
                )}
                {reward.energyMax > 0 && (
                  <div>
                    {reward.energyMax}
                    {getImageFromReward({ type: 'energyMax' }, true)}
                  </div>
                )}
                {reward.legendaryCoin > 0 && (
                  <div>
                    {reward.legendaryCoin}
                    {getImageFromReward({ type: 'legendarycoin' }, true)}
                  </div>
                )}
              </div>
            </Fragment>
          );
        case 'chestLegendary':
          return (
            <Fragment>
              <div className='text-center display-4 mb-3'>
                {getImageFromReward({ type: 'chestLegendary' }, true)} {getLocale(user.lang, 'vip', 'legendaryChestOpen')}
              </div>
              <div className='d-flex flex-wrap justify-content-evenly mb-3 display-2 align-items-center text-center'>
                {reward.lootbox > 0 && (
                  <div>
                    {reward.lootbox}
                    {getImageFromReward({ type: 'lootcoin' }, true)}
                  </div>
                )}
                {reward.fakecoin > 0 && (
                  <div>
                    {numberWithSpaces(reward.fakecoin, true)}
                    {getImageFromReward({ type: 'fakecoin' }, true)}
                  </div>
                )}
                {reward.energy > 0 && (
                  <div>
                    {reward.energy}
                    {getImageFromReward({ type: 'energy' }, true)}
                  </div>
                )}
                {reward.energyMax > 0 && (
                  <div>
                    {reward.energyMax}
                    {getImageFromReward({ type: 'energyMax' }, true)}
                  </div>
                )}
                {reward.legendaryCoin > 0 && (
                  <div>
                    {reward.legendaryCoin}
                    {getImageFromReward({ type: 'legendarycoin' }, true)}
                  </div>
                )}
              </div>
            </Fragment>
          );
        default:
          return (
            <div className='d-flex justify-content-center mb-3 display-2 align-items-center'>
              {getImageFromReward(vip, true)} x{numberWithSpaces(vip.amount, true)}
            </div>
          );
      }
    }
    return (
      <div className='d-flex justify-content-center mb-3 display-2 align-items-center'>
        {getImageFromReward(vip, true)} x{numberWithSpaces(vip.amount, true)}
      </div>
    );
  };
  return (
    <Card className='vip'>
      <CardBody>
        <div className='d-flex'>
          <div>
            <img
              className='vip-avatar'
              alt={`${user.username} avatar`}
              src={user.avatar}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = defaultAvatar;
              }}
            />
          </div>
          <div className='vip-level mx-3 align-self-center'>
            <div className='d-flex justify-content-between'>
              <div>VIP {user.vip.level}</div>
              <div>
                {getLocale(user.lang, 'vip', 'nextLevel')} {numberWithSpaces(getDiffXp(), true)} / {numberWithSpaces(getDisplayXp(user.vip.level), true)} {shopcoin}
              </div>
            </div>
            <Progress
              color='primary'
              value={getDiffXp()}
              min={0}
              max={getDisplayXp(user.vip.level)}
              animated
              style={{
                height: '12px',
              }}
              className='my-1'
            />
            <Accordion defaultActiveKey={claimOpen ? 'vipPass' : ''} className='mt-2 '>
              <Accordion.Item eventKey='vipPass'>
                <div className='d-flex justify-content-end'>
                  <Accordion.Button className='d-flex btn  btn-twitter' onClick={() => setClaimOpen(!claimOpen)}>
                    <div className='d-flex align-items-center'>
                      <div className='mr-1'>
                        {capitalize(getLocale(user.lang, 'vip', 'reward', []))} {numberWithSpaces(vipRewards[user.vip.level % 20].amount, true)}
                      </div>
                      {getImageFromReward(vipRewards[user.vip.level % 20], false)}
                    </div>
                    <div className='ml-2'>
                      <i className={`tim-icons icon-minimal-${claimOpen ? 'up' : 'down'}`} />
                    </div>
                  </Accordion.Button>
                </div>
                {error && <Alert color='danger'>{error}</Alert>}
                <Accordion.Body className='d-flex flex-wrap'>
                  {vipRewards.map((vip, key) => (
                    <div className={getRewardClassname(vip)} onClick={() => confetti(vip.id)} ref={(el) => (ref.current[vip.id] = el)} key={vip.id}>
                      <div className='reward-body'>
                        <div className='d-flex justify-content-between'>
                          <div>VIP {vip.id + 1}</div>
                          <div id={`description-${key + 1}`}>
                            <i className='bi bi-info-circle'></i>
                          </div>
                          <UncontrolledTooltip flip placement='bottom' target={`description-${key + 1}`}>
                            {getDescriptionFromReward(vip)}
                          </UncontrolledTooltip>
                        </div>
                        <div className='d-flex justify-content-center'>{getImageFromReward(vip, true)}</div>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div>{getBadge(vip)}</div>
                          <div className='text-right'>{numberWithSpaces(vip.amount)}</div>
                        </div>
                      </div>
                      <Modal className='vip-rewards' toggle={() => setIsModalOpen(false)} isOpen={isModalOpen && vip.id === user.vip.level}>
                        <Card className='paypal-card'>
                          <CardHeader>
                            <CardTitle tag='h2'>{getLocale(user.lang, 'vip', 'rewardClaimed')}</CardTitle>
                          </CardHeader>
                          <CardBody>{modalDescription(vip)}</CardBody>
                          <CardFooter>
                            <Button onClick={() => setIsModalOpen(false)} color='primary' block>
                              {buttonNames[vip.id % buttonNames.length]}
                            </Button>
                          </CardFooter>
                        </Card>
                      </Modal>
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default VipPass;
