/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Fragment } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from 'perfect-scrollbar';

// reactstrap components
import { Nav, Collapse, Card } from 'reactstrap';
import { numberWithSpaces } from '../../helpers/functions';
import shopcoin from '../../assets/img/coins/shopcoin.png';
import fakecoin from '../../assets/img/coins/fakecoin.png';
import lootcoin from '../../assets/img/coins/lootcoin.png';
import { ornaments } from '../../helpers/env';

var ps;

const Sidebar = (props) => {
  const [state, setState] = React.useState({});
  const sidebarRef = React.useRef(null);
  const location = useLocation();
  React.useEffect(() => {
    setState(getCollapseStates(props.routes));
  }, []);
  const { activeColor, logo, user } = props;
  // React.useEffect(() => {
  //   // if you are using a Windows Machine, the scrollbars will have a Mac look
  //   if (navigator.platform.indexOf('Win') > -1) {
  //     ps = new PerfectScrollbar(sidebarRef.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }
  //   return function cleanup() {
  //     // we need to destroy the false scrollbar when we navigate
  //     // to a page that doesn't have this component rendered
  //     if (navigator.platform.indexOf('Win') > -1) {
  //       ps.destroy();
  //     }
  //   };
  // });
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const { rtlActive } = props;
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop['state']] = !state[prop.state];
        return (
          <li className={getCollapseInitialState(prop.views) ? 'active' : ''} key={key}>
            <a
              href='#pablo'
              data-toggle='collapse'
              aria-expanded={state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
            >
              {prop.icon !== undefined ? (
                <Fragment>
                  <i className={prop.icon} />
                  <p>
                    {rtlActive ? prop.rtlName : prop.display[user.lang]}
                    <b className='caret' />
                  </p>
                </Fragment>
              ) : (
                <Fragment>
                  <span className='sidebar-mini-icon'>{rtlActive ? prop.rtlMini : prop.mini}</span>
                  <span className='sidebar-normal'>
                    {rtlActive ? prop.rtlName : prop.display[user.lang]}
                    <b className='caret' />
                  </span>
                </Fragment>
              )}
            </a>
            <Collapse isOpen={state[prop.state]}>
              <ul className='nav'>{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      let fullPath = prop.layout + prop.path;
      if (prop.path === '/logout') {
        fullPath = prop.path;
      } else if (prop.externalLink && prop.layout === props.match.path) {
        fullPath = prop.externalLink;
        return (
          <li key={key}>
            <a href={prop.externalLink}>
              {prop.icon !== undefined ? (
                <Fragment>
                  <i className={prop.icon} />
                  <p>{rtlActive ? prop.rtlName : prop.display[user.lang]}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <span className='sidebar-mini-icon'>{rtlActive ? prop.rtlMini : prop.mini}</span>
                  <span className='sidebar-normal'>{rtlActive ? prop.rtlName : prop.display[user.lang]}</span>
                </Fragment>
              )}
            </a>
          </li>
        );
      }
      // if (prop.path === '/logout' || (prop.layout === '/shop' && (!prop.admin || (prop.admin && user.id === '173622375601012737')))) {
      if (prop.path === '/logout' || prop.layout === props.match.path) {
        return (
          <li className={`${activeRoute(prop.layout + prop.path)}${prop.className ? ` ${prop.className}` : ''}`} key={key}>
            <NavLink to={{ pathname: fullPath }} activeClassName='' onClick={props.closeSidebar}>
              {prop.icon !== undefined ? (
                <Fragment>
                  <i className={prop.icon} />
                  <p>{rtlActive ? prop.rtlName : prop.display[user.lang]}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <span className='sidebar-mini-icon'>{rtlActive ? prop.rtlMini : prop.mini}</span>
                  <span className='sidebar-normal'>{rtlActive ? prop.rtlName : prop.display[user.lang]}</span>
                </Fragment>
              )}
            </NavLink>
          </li>
        );
      }
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? 'active' : '';
  };

  const customUsername = () => {
    const found = ornaments.find((o) => o.id === user.ornament);
    if (!found) return <div className='username'>{user.username}</div>;
    return (
      <div className='username'>
        <img className='ornament' alt={`${found.name} left`} src={found.left} />
        {user.username}
        <img className='ornament' alt={`${found.name} right`} src={found.right} />
      </div>
    );
  };
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a href={logo.outterLink} className='simple-text logo-mini' style={{ overflow: 'visible' }} onClick={props.closeSidebar}>
          <div className='logo-img'>
            {user.premium.level > 0 ? (
              <div className='ring-container'>
                <div className={`ringring ringring-p${user.premium.level}`}></div>
                <div className={`circle badge-p${user.premium.level}`}></div>
              </div>
            ) : null}
            <img src={logo.imgSrc} alt='avatar' />
          </div>
        </a>
      );
      logoText = (
        <a href={logo.outterLink} className='simple-text logo-normal' onClick={props.closeSidebar}>
          {user.ornament && user.ornament > 0 ? customUsername() : <div className='username'>{user.username}</div>}
          {/* {logo.text} */}
        </a>
      );
    } else {
      logoImg = (
        <NavLink to={logo.innerLink} className='simple-text logo-mini' onClick={props.closeSidebar}>
          <div className='logo-img'>
            <img src={logo.imgSrc} alt='avatar' />
          </div>
        </NavLink>
      );
      logoText = (
        <NavLink to={logo.innerLink} className='simple-text logo-normal' onClick={props.closeSidebar}>
          {logo.text}
        </NavLink>
      );
    }
  }
  return (
    <div className='sidebar' data={activeColor}>
      <div className='sidebar-wrapper' ref={sidebarRef}>
        {logoImg !== null || logoText !== null ? (
          <div className='logo'>
            {logoImg}
            {logoText}
            <div className='d-flex justify-content-around w-100'>
              <a className='logo-normal text-white'>
                {numberWithSpaces(parseInt(user.shopcoin), true)} <img className='coin' src={shopcoin} />
              </a>
              <a className='logo-normal text-white'>
                {numberWithSpaces(parseInt(user.fakecoin), true)} <img className='coin' src={fakecoin} />
              </a>
              <a className='logo-normal text-white'>
                {numberWithSpaces(parseInt(user.lootcoin), true)} <img className='coin' src={lootcoin} />
              </a>
            </div>
          </div>
        ) : null}
        <Nav>{createLinks(props.routes)}</Nav>
        {/* <div className='login-discord'>Logout</div> */}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(['primary', 'blue', 'green', 'orange', 'red']),
  rtlActive: PropTypes.bool,
  routes: PropTypes.array.isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      innerLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      outterLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ]),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func,
};

export default Sidebar;
