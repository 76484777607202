import moment from 'moment';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { dofusOneEmoji, dofusTwoEmoji, dofusThreeEmoji, dofusFourEmoji, dofusFiveEmoji, dofusSixEmoji, rouletteEmoji } from '../../../../helpers/env';
import { numberWithSpaces } from '../../../../helpers/functions';

const Roulette = ({ dofus, stats }) => {
  return (
    <Card className='card-admin-stats'>
      <CardHeader>
        <CardTitle tag='h3'>{rouletteEmoji} Dofus</CardTitle>
      </CardHeader>
      <CardBody>
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-column-reverse'>
            {numberWithSpaces(stats.one)} {dofusOneEmoji}
          </div>
          <div className='d-flex flex-column-reverse'>
            {numberWithSpaces(stats.two)} {dofusTwoEmoji}
          </div>
          <div className='d-flex flex-column-reverse'>
            {numberWithSpaces(stats.three)} {dofusThreeEmoji}
          </div>
          <div className='d-flex flex-column-reverse'>
            {numberWithSpaces(stats.four)} {dofusFourEmoji}
          </div>
          <div className='d-flex flex-column-reverse'>
            {numberWithSpaces(stats.five)} {dofusFiveEmoji}
          </div>
          <div className='d-flex flex-column-reverse'>
            {numberWithSpaces(stats.six)} {dofusSixEmoji}
          </div>
        </div>
      </CardBody>
      <CardFooter className='text-center text-muted'>{moment(new Date(dofus.date._seconds * 1000)).format('LLL')}</CardFooter>
    </Card>
  );
};

export default Roulette;
