import React, { Fragment, useContext, useState } from 'react';
import { Alert, Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Modal, Row } from 'reactstrap';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import { equipItem } from '../../helpers/API';
import { fakecoin, lootcoin, nitroEmoji, shopcoin } from '../../helpers/env';
import { numberWithSpaces } from '../../helpers/functions';
import { getLocale } from '../../helpers/lang';

const CoinCard = ({ coin, buyItem }) => {
  const { user, setUser } = useContext(AuthenticatedContext);
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const devise = coin.type === 'fakecoin' ? fakecoin : lootcoin;
  const toggleModal = () => {
    setModal(!modal);
  };
  const coinType = {
    fakecoin: 'money',
    lootcoin: 'lootbox',
  };
  const confirmOrderItem = async (coin) => {
    if (user.shopcoin > coin.price) {
      const res = await buyItem('coins', coin.id);
      if (res.status === 'success') {
        setSuccess(`You have successfully bought ${coin.name}!`);
        setError(null);
        setUser({ ...user, shopcoin: user.shopcoin - coin.price, [coinType[coin.type]]: user[coinType[coin.type]] + coin.amount });
      } else {
        setError(`Uh oh: ${res && res.message ? res.message : 'something happened.'}`);
        setSuccess(null);
      }
      setModal(false);
    }
  };
  return (
    <Card className='card-coin text-white'>
      {coin?.bonus > 0 ? (
        <div className='d-flex justify-content-end extra'>
          <Badge className={coin.bonus <= 1 ? 'hidden' : 'text-lowercase'} color='primary'>
            +{coin.bonus}%
          </Badge>
        </div>
      ) : (
        ''
      )}
      <CardBody className='d-flex flex-column justify-content-between'>
        <div className='mt-3'>
          {success ? <Alert color='success'>{success}</Alert> : ''}
          {error ? <Alert color='danger'>{error}</Alert> : null}
          <div className='text-center'>
            <b>{coin.name}</b>
            <div className='d-flex justify-content-center align-items-center'>
              <b className='mr-1'>{numberWithSpaces(coin.amount, true, user.lang)}</b> {devise}
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <img src={coin.image} alt={coin.name} />
        </div>
      </CardBody>
      <Modal isOpen={modal} toggle={toggleModal}>
        <Card className='paypal-card'>
          <CardHeader>
            <CardTitle tag='h2'>{getLocale(user.lang, 'items', 'confirmTitle')}</CardTitle>
          </CardHeader>
          <CardBody>
            <div className='d-flex justify-content-center'>
              <img src={coin.image} alt={coin.name} />
            </div>
            {getLocale(user.lang, 'items', 'confirmDescription1')} <b>{numberWithSpaces(coin.amount, true, user.lang)}</b> {devise}{' '}
            {getLocale(user.lang, 'items', 'confirmDescription2')}{' '}
            <b>
              {numberWithSpaces(coin.price)} {shopcoin}
            </b>
          </CardBody>
          <CardFooter className='pt-0'>
            <Row>
              <Col>
                <Button className='btn-simple' color='danger' size='lg' onClick={() => setModal(false)} block>
                  {getLocale(user.lang, 'global', 'cancel')}
                </Button>
              </Col>
              <Col>
                <Button color='info' size='lg' onClick={() => confirmOrderItem(coin)} block>
                  {getLocale(user.lang, 'global', 'confirm')}
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Modal>
      <Button className='d-flex justify-content-center align-items-center' color='primary' onClick={toggleModal} disabled={user.shopcoin < coin.price} block>
        <div className='mr-1'>{numberWithSpaces(coin.price)}</div> {shopcoin}
      </Button>
    </Card>
  );
};

export default CoinCard;
