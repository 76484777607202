import React, { Fragment } from 'react';
import { Button } from 'reactstrap';

const ConfirmOrder = (props) => {
  const { order } = props;
  return (
    <div className='d-flex align-items-center justify-content-center flex-column'>
      {order.status === 'success' ? (
        <div
          style={{
            width: '80px',
            height: '80px',
            borderWidth: '4px',
            borderStyle: 'solid',
            borderColor: 'rgb(76, 174, 76)',
            borderRadius: '50%',
            margin: '20px auto',
            position: 'relative',
            boxSizing: 'content-box',
          }}
        >
          <div
            style={{
              borderRadius: '120px 0px 0px 120px',
              position: 'absolute',
              width: '60px',
              height: '100px',
              transform: 'rotate(-45deg)',
              top: '-7px',
              left: '-33px',
              transformOrigin: '60px 60px',
            }}
          ></div>
          <span
            style={{
              height: '5px',
              backgroundColor: 'rgb(92, 184, 92)',
              display: 'block',
              borderRadius: '2px',
              position: 'absolute',
              zIndex: '2',
              width: '25px',
              left: '14px',
              top: '46px',
              transform: 'rotate(45deg)',
              animation: '0.75s ease 0s 1 normal none running animateSuccessTip',
            }}
          ></span>
          <span
            style={{
              height: '5px',
              backgroundColor: 'rgb(92, 184, 92)',
              display: 'block',
              borderRadius: '2px',
              position: 'absolute',
              zIndex: '2',
              width: '47px',
              right: '8px',
              top: '38px',
              transform: 'rotate(-45deg)',
              animation: '0.75s ease 0s 1 normal none running animateSuccessLong',
            }}
          ></span>
          <div
            style={{
              width: '80px',
              height: '80px',
              border: '4px solid rgba(92, 184, 92, 0.2)',
              borderRadius: '50%',
              boxSizing: 'content-box',
              position: 'absolute',
              left: '-4px',
              top: '-4px',
              zIndex: '2',
            }}
          ></div>
          <div
            style={{
              width: '5px',
              height: '90px',
              position: 'absolute',
              left: '28px',
              top: '8px',
              zIndex: '1',
              transform: 'rotate(-45deg)',
            }}
          ></div>
          <div
            style={{
              borderRadius: '0px 120px 120px 0px',
              position: 'absolute',
              width: '60px',
              height: '120px',
              transform: 'rotate(-45deg)',
              top: '-11px',
              left: '30px',
              transformOrigin: '0px 60px',
              animation: '4.25s ease-in 0s 1 normal none running rotatePlaceholder',
            }}
          ></div>
        </div>
      ) : (
        <div
          style={{
            width: '80px',
            height: '80px',
            borderWidth: '4px',
            borderStyle: 'solid',
            borderColor: 'rgb(212, 63, 58)',
            borderRadius: '50%',
            margin: '20px auto',
            position: 'relative',
            boxSizing: 'content-box',
            animation: '0.5s ease 0s 1 normal none running animateErrorIcon',
          }}
        >
          <span style={{ position: 'relative', display: 'block', animation: '0.5s ease 0s 1 normal none running animateXMark' }}>
            <span
              style={{
                position: 'absolute',
                height: '5px',
                width: '47px',
                backgroundColor: 'rgb(217, 83, 79)',
                display: 'block',
                top: '37px',
                borderRadius: '2px',
                transform: 'rotate(45deg)',
                left: '17px',
              }}
            ></span>
            <span
              style={{
                position: 'absolute',
                height: '5px',
                width: '47px',
                backgroundColor: 'rgb(217, 83, 79)',
                display: 'block',
                top: '37px',
                borderRadius: '2px',
                transform: 'rotate(-45deg)',
                right: '16px',
              }}
            ></span>
          </span>
        </div>
      )}
      {order.status === 'success' ? (
        <h3 className='text-center'>Order successful.</h3>
      ) : (
        <div className='text-center'>
          <h3 className='mb-0'>Order failed.</h3>
          <p style={{ fontSize: '1rem' }}>
            {order.code && order.code === 422 ? (
              'Your bank declined our attempt to authorize your card.'
            ) : (
              <Fragment>
                Please communicate your order number to the support: <br /> <b>#{order.pay_id}</b>
                {order.error ? (
                  <Fragment>
                    <br />
                    (Error {order.code})
                  </Fragment>
                ) : (
                  ''
                )}
              </Fragment>
            )}
            <br />
            The support is available in the bot direct message.
            <Button className='mt-3' target='_newblank' rel='noreferrer' href='https://discord.gg/fake' color='info'>
              Contact the support
            </Button>
          </p>
        </div>
      )}
    </div>
  );
};

export default ConfirmOrder;
