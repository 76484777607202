import React, { Fragment, useContext, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Modal } from 'reactstrap';
import { shopcoin } from '../../helpers/env';
import { numberWithSpaces } from '../../helpers/functions';

import Paypal from '../Paypal';
import ConfirmOrder from './ConfirmOrder';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import Loading from '../Loading';
import SinglePack from './SinglePack';
import MonthlyPass from './MonthlyPass';
import { getLocale } from '../../helpers/lang';

const plans = [
  {
    title: {
      en: '12 months limited promotion offer',
      es: '12 months limited promotion offer',
      fr: `Offre limité de promotion pour 12 mois`,
    },
    salesTitle: {
      en: '12 months limited promotion offer',
      es: '12 months limited promotion offer',
      fr: `Offre limité de promotion pour 12 mois`,
    },
    amount: 97200,
    price: 11.99,
    months: 12,
    salesOnly: true,
  },
  {
    title: {
      en: '6 Months Subscription Special Offer',
      es: '6 Months Subscription Special Offer',
      fr: `Offre spéciale pour un abonnement de 6 mois`,
    },
    salesTitle: {
      en: '6 Months',
      es: '6 Months',
      fr: `6 mois`,
    },
    amount: 48600,
    price: 12.99,
    months: 6,
    salesOnly: false,
  },
  {
    title: { en: '3 Months', es: '3 Months', fr: '3 mois' },
    salesTitle: { en: '3 Months', es: '3 Months', fr: '3 mois' },
    amount: 24300,
    price: 13.99,
    months: 3,
    salesOnly: false,
  },
  {
    title: { en: '1 Month', es: '1 Month', fr: '1 mois' },
    salesTitle: { en: '1 Month', es: '1 Month', fr: '1 mois' },
    amount: 8100,
    price: 14.99,
    months: 1,
    salesOnly: false,
  },
];

const Pack = (props) => {
  const [checkout, setCheckout] = useState(false);
  const [order, setOrder] = useState(null);
  const { user, setUser } = useContext(AuthenticatedContext);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const toggle = () => {
    setCheckout(!checkout);
    if (order && order.amount > 0) {
      setUser({ ...user, shopcoin: user.shopcoin + order.amount, shop: { ...user.shop, coin: { ...user.shop.coin, total: user.shop.coin.total + order.amount } } });
    }
    setOrder(null);
  };
  const currentPlans = plans.filter((plan) => !plan.salesOnly || props.isSales);
  return (
    <Fragment>
      <Modal isOpen={checkout} toggle={toggle}>
        <Card className='paypal-card'>
          <CardHeader>
            {props.type === 'pass' ? (
              <CardTitle className='text-center' tag='h2'>
                {props.title && props.title} {currentPlans[selectedPlan].title[user.lang || 'en']}
                <br />
                {numberWithSpaces(currentPlans[selectedPlan].amount)} {shopcoin}
              </CardTitle>
            ) : (
              <CardTitle className='text-center' tag='h2'>
                {props.sales && props.sales > 0
                  ? getLocale(user.lang, 'pack', 'packTitle', [
                      numberWithSpaces(user.vip && user.vip.level > 0 ? parseInt(props.sales * (1 + user.vip.level / 100)) : props.sales, true),
                    ])
                  : getLocale(user.lang, 'pack', 'packTitle', [
                      numberWithSpaces(user.vip && user.vip.level > 0 ? parseInt(props.amount * (1 + user.vip.level / 100)) : props.amount, true),
                    ])}
                {shopcoin}
              </CardTitle>
            )}
          </CardHeader>
          <CardBody className='text-center'>
            {!order ? (
              <Paypal
                {...props}
                setCheckout={setCheckout}
                setOrder={setOrder}
                plan={currentPlans[selectedPlan]}
                lang={user.lang}
                vipAmount={
                  props.sales && props.sales > 0
                    ? numberWithSpaces(user.vip && user.vip.level > 0 ? parseInt(props.sales * (1 + user.vip.level / 100)) : props.sales, true)
                    : numberWithSpaces(user.vip && user.vip.level > 0 ? parseInt(props.amount * (1 + user.vip.level / 100)) : props.amount, true)
                }
              />
            ) : null}
            {order && order.loading ? <Loading /> : ''}
            {order && order.status ? <ConfirmOrder order={order} /> : null}
          </CardBody>
        </Card>
      </Modal>
      {props.pass ? (
        <MonthlyPass lang={user.lang || 'en'} plans={currentPlans} toggle={toggle} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} isSales={props.isSales} />
      ) : (
        <SinglePack {...props} toggle={toggle} lang={user.lang} />
      )}
    </Fragment>
  );
};

export default Pack;
