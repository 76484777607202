import React, { Fragment, useContext } from 'react';
import Pack from '../../components/Pricing/Pack.js';
import { Alert, Col, Row } from 'reactstrap';
import { shopcoinLarge } from '../../helpers/env.js';
import { AuthenticatedContext } from '../../contexts/Authenticated.js';
import moment from 'moment';
import 'moment/locale/fr';
import { getLocale } from '../../helpers/lang.js';
moment().format();
moment.locale('en');

const now = new Date();
const sales = [
  {
    name: {
      fr: `Fête de la musique`,
      en: `Music Day`,
      es: `Music Day`,
    },
    emoji: '🎺',
    start: new Date('2022-06-21T00:00:00'),
    end: new Date('2022-06-22T00:00:00'),
    type: 1,
  },
  {
    name: {
      fr: `Fête Nationale Française`,
      en: `French National Day`,
      es: `French National Day`,
    },
    emoji: '🇫🇷',
    start: new Date('2022-07-14T00:00:00'),
    end: new Date('2022-07-15T00:00:00'),
    type: 1,
  },
  {
    name: {
      fr: `Anniversaire de Fake RPG`,
      en: `Fake RPG Anniversary`,
      es: `Fake RPG Anniversary`,
    },
    emoji: '🐉',
    start: new Date('2022-08-01T00:00:00'),
    end: new Date('2022-08-08T00:00:00'),
    type: 1,
  },
  {
    name: {
      fr: `Halloween`,
      en: `Halloween`,
      es: `Halloween`,
    },
    emoji: '🎃',
    start: new Date('2022-10-31T00:00:00'),
    end: new Date('2022-11-02T00:00:00'),
    type: 1,
  },
  {
    name: {
      fr: `Black Friday`,
      en: `Black Friday`,
      es: `Black Friday`,
    },
    emoji: '🖤',
    start: new Date('2022-11-25T00:00:00'),
    end: new Date('2022-11-29T00:00:00'),
    type: 3,
  },
  {
    name: {
      fr: `Noël`,
      en: `Christmas`,
      es: `Christmas`,
    },
    emoji: '🎄',
    start: new Date('2022-12-24T00:00:00'),
    end: new Date('2022-12-26T00:00:00'),
    type: 2,
  },
  {
    name: {
      fr: `Nouvel An`,
      en: `New Year`,
      es: `New Year`,
    },
    emoji: '🥂',
    start: new Date('2022-12-31T00:00:00'),
    end: new Date('2023-01-02T00:00:00'),
    type: 1,
  },
  {
    name: {
      fr: `Saint-Valentin`,
      en: `Valentine's Day`,
      es: `Valentine's Day`,
    },
    emoji: '💝',
    start: new Date('2023-02-14T00:00:00'),
    end: new Date('2023-02-15T00:00:00'),
    type: 1,
  },
  {
    name: {
      fr: `Pâques`,
      en: `Easter`,
      es: `Easter`,
    },
    emoji: '🐇',
    start: new Date('2023-04-09T00:00:00'),
    end: new Date('2023-04-11T00:00:00'),
    type: 1,
  },
];

const isSales = sales.find((sale) => sale.start <= now && sale.end >= now);

const Shop = () => {
  const { user } = useContext(AuthenticatedContext);
  const packs = [
    {},
    {
      num: 1,
      amount: 8100,
      price: 14.99,
      type: 'pass',
      sales: 0,
      bonus: 0,
    },
    {
      num: 2,
      amount: 900,
      price: 4.99,
      type: 'coin',
      sales: isSales ? 990 : 0,
      bonus: isSales ? 10 : 0,
    },
    {
      num: 3,
      amount: 5625,
      price: 24.99,
      type: 'coin',
      extra: 25,
      sales: isSales ? 6750 : 0,
      bonus: isSales ? 25 : 0,
    },
    {
      num: 4,
      amount: 13500,
      price: 49.99,
      type: 'coin',
      extra: 50,
      sales: isSales ? 18000 : 0,
      bonus: isSales ? 50 : 0,
    },
    {
      num: 5,
      amount: 36000,
      price: 99.99,
      type: 'coin',
      extra: 100,
      sales: isSales ? 54000 : 0,
      bonus: isSales ? 100 : 0,
    },
  ];
  return (
    <Fragment>
      {isSales ? (
        <Alert className='fs-1-5 d-flex align-items-center' color='info'>
          {isSales.emoji} <span className='font-weight-bold mx-2'>{isSales.name[user.lang]}</span>
          {getLocale(user.lang, 'global', 'colon')} {getLocale(user.lang, 'pack', 'promotionEnds')} {moment(isSales.end).locale(user.lang).fromNow()}.
        </Alert>
      ) : null}
      <Row className='gap-1'>
        <Col>
          <div className='cards'>
            {packs.map((pack, index) => {
              if (index < 2) return null;
              return (
                <Pack
                  vip={user.vip.level}
                  num={packs[index].num}
                  amount={packs[index].amount}
                  price={packs[index].price}
                  currency='€'
                  type={packs[index].type}
                  sales={packs[index].sales}
                  bonus={packs[index].bonus}
                  extra={packs[index].extra}
                />
              );
            })}
          </div>
          <Fragment>
            {/* <Row>
            <Col lg='6'>
              <Pack
                vip={user.vip.level}
                num={packs[2].num}
                amount={packs[2].amount}
                price={packs[2].price}
                currency='€'
                type={packs[2].type}
                sales={packs[2].sales}
                bonus={packs[2].bonus}
                extra={packs[2].extra}
              />
            </Col>
            <Col lg='6'>
              <Pack
                vip={user.vip.level}
                num={packs[3].num}
                amount={packs[3].amount}
                price={packs[3].price}
                currency='€'
                type={packs[3].type}
                sales={packs[3].sales}
                bonus={packs[3].bonus}
                extra={packs[3].extra}
              />
            </Col>
          </Row>
          <Row>
            <Col lg='6'>
              <Pack
                vip={user.vip.level}
                num={packs[4].num}
                amount={packs[4].amount}
                price={packs[4].price}
                currency='€'
                type={packs[4].type}
                sales={packs[4].sales}
                bonus={packs[4].bonus}
                extra={packs[4].extra}
              />
            </Col>
            <Col lg='6'>
              <Pack
                vip={user.vip.level}
                num={packs[5].num}
                amount={packs[5].amount}
                price={packs[5].price}
                currency='€'
                type={packs[5].type}
                sales={packs[5].sales}
                bonus={packs[5].bonus}
                extra={packs[5].extra}
              />
            </Col>
          </Row> */}
          </Fragment>
        </Col>
        <Col lg='6'>
          <Pack
            num={packs[1].num}
            title='Monthly Pass'
            pass={true}
            spotlight={true}
            amount={packs[1].amount}
            price={packs[1].price}
            currency='€'
            type={packs[1].type}
            isSales={isSales}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Shop;
