import moment from 'moment';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { dailyEmoji, rewardEmoji } from '../../../../helpers/env';
import { numberWithSpaces } from '../../../../helpers/functions';

const Daily = ({ daily, stats }) => {
  return (
    <Card className='card-admin-stats'>
      <CardHeader>
        <CardTitle tag='h3'>{rewardEmoji} Daily</CardTitle>
      </CardHeader>
      <CardBody>
        <div className='d-flex justify-content-evenly text-center'>
          <div className='d-flex flex-column'>
            {dailyEmoji} {daily.day}
          </div>
          <div className='d-flex flex-column'>
            <div>À la suite</div>
            <div>{numberWithSpaces(stats.inARow)}</div>
          </div>
          <div className='d-flex flex-column'>
            <div>Total</div>
            <div>{numberWithSpaces(stats.count)}</div>
          </div>
        </div>
      </CardBody>
      <CardFooter className='text-center text-muted'>{moment(new Date(daily.date._seconds * 1000)).format('LLL')}</CardFooter>
    </Card>
  );
};

export default Daily;
