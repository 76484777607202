import React from 'react';
import { Button, Card, CardBody, CardFooter, Col, Form, Input, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { attackEmoji, defaultAvatar, hpEmoji, levelEmoji, resetEmoji } from '../../../helpers/env';
import { numberWithSpaces } from '../../../helpers/functions';
import moment from 'moment';
import 'moment/locale/fr';
moment().format();
moment.locale('fr');

const PlayerCard = ({ player }) => {
  const history = useHistory();
  return (
    <Card className='card-user text-center cursor-pointer'>
      <CardBody onClick={() => history.push(`/admin/players/${player.id}`, { player: player })}>
        <div className='d-flex justify-content-center align-items-center'>
          <img
            className='avatar'
            src={player.avatar ? player.avatar : defaultAvatar}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultAvatar;
            }}
            alt={`${player.username}'s avatar`}
          />
          {player.premium.level > 0 ? (
            <div className='ring-container'>
              <div className={`card-premium-icon badge-p${player.premium.level}`}></div>
            </div>
          ) : null}
        </div>
        <div>{player.username}</div>
        {player.guild && player.guild.id !== 0 && player.guild.name ? (
          <div className='text-muted'>
            {player.guild.rank === 1 ? '👑' : ''} {player.guild.name}
          </div>
        ) : null}
      </CardBody>
      <CardFooter className='pt-0'>
        <div className='d-flex justify-content-evenly align-items-center pb-2'>
          <div className='d-flex flex-column align-items-center'>
            <span>{resetEmoji}</span>
            {player.stats.reset.count}
          </div>
          <div className='d-flex flex-column align-items-center'>
            {levelEmoji}
            {player.level.level}
          </div>
          <div className='d-flex flex-column align-items-center'>
            {attackEmoji}
            {numberWithSpaces(player.attack)}
          </div>
          <div className='d-flex flex-column align-items-center'>
            {hpEmoji}
            {numberWithSpaces(parseInt(300 * player.level.level * player.ratio))}
          </div>
        </div>
        <div className='text-muted italic'>{player.dofus.date._seconds > 0 ? `Actif le ${moment(new Date(player.dofus.date._seconds * 1000)).format('LLL')}` : `Inactif`}</div>
      </CardFooter>
    </Card>
  );
};

export default PlayerCard;
