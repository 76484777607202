import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import p1Img from '../../assets/img/premium/p1.png';
import p2Img from '../../assets/img/premium/p2.png';
import p3Img from '../../assets/img/premium/p3.png';
import { getLocale } from '../../helpers/lang';

const PricingPlans = ({ lang }) => {
  const [selectedPremium, setSelectedPremium] = React.useState(1);
  const plans = [
    {
      id: 1,
      title: getLocale(lang, 'premium', 'premium', ['1']),
      subtitle: getLocale(lang, 'premium', 'monthlySubscription'),
      price: 4.5,
      devise: `€`,
      features: [
        {
          amount: '+25%',
          detail: getLocale(lang, 'premium', 'perks1'),
          reverse: false,
        },
        {
          amount: '+1',
          detail: getLocale(lang, 'premium', 'perks2'),
          reverse: false,
        },
        {
          amount: '+1',
          detail: getLocale(lang, 'premium', 'perks3'),
          reverse: false,
        },
        {
          amount: '500',
          detail: getLocale(lang, 'premium', 'perks4'),
          reverse: true,
        },
        {
          amount: '+11',
          detail: getLocale(lang, 'premium', 'perks5'),
          reverse: false,
        },
      ],
      image: p1Img,
      link: 'https://www.patreon.com/join/FakeRPG/checkout?rid=6430917',
      highlighted: false,
    },
    {
      id: 3,
      title: getLocale(lang, 'premium', 'premium', ['3']),
      subtitle: getLocale(lang, 'premium', 'monthlySubscription'),
      price: 18,
      devise: `€`,
      features: [
        {
          amount: '+100%',
          detail: getLocale(lang, 'premium', 'perks1'),
          reverse: false,
        },
        {
          amount: '+3',
          detail: getLocale(lang, 'premium', 'perks2'),
          reverse: false,
        },
        {
          amount: '+3',
          detail: getLocale(lang, 'premium', 'perks3'),
          reverse: false,
        },
        {
          amount: '1 000',
          detail: getLocale(lang, 'premium', 'perks4'),
          reverse: true,
        },
        {
          amount: '+11',
          detail: getLocale(lang, 'premium', 'perks5'),
          reverse: false,
        },
      ],
      image: p3Img,
      link: 'https://www.patreon.com/join/FakeRPG/checkout?rid=6430936',
      highlighted: true,
    },
    {
      id: 2,
      title: getLocale(lang, 'premium', 'premium', ['2']),
      subtitle: getLocale(lang, 'premium', 'monthlySubscription'),
      price: 9,
      devise: `€`,
      features: [
        {
          amount: '+50%',
          detail: getLocale(lang, 'premium', 'perks1'),
          reverse: false,
        },
        {
          amount: '+2',
          detail: getLocale(lang, 'premium', 'perks2'),
          reverse: false,
        },
        {
          amount: '+2',
          detail: getLocale(lang, 'premium', 'perks3'),
          reverse: false,
        },
        {
          amount: '750',
          detail: getLocale(lang, 'premium', 'perks4'),
          reverse: true,
        },
        {
          amount: '+11',
          detail: getLocale(lang, 'premium', 'perks5'),
          reverse: false,
        },
      ],
      image: p2Img,
      link: 'https://www.patreon.com/join/FakeRPG/checkout?rid=6430935',
      highlighted: false,
    },
  ];
  return (
    <div className='plans'>
      {plans.map((plan, index) => {
        return (
          <div className={`plan${index === selectedPremium ? ' highlighted' : ''}`} key={index} onClick={() => setSelectedPremium(Number(index))}>
            {index === 1 ? <div className='popular-pill'>{getLocale(lang, 'premium', 'popular')}</div> : null}
            <div className='text-center'>
              <img className='premium-image' src={plan.image} alt={plan.title} />
              <h3>{plan.title}</h3>
              <h4 className='text-muted'>{plan.subtitle}</h4>
              <div className='plan-price'>
                {plan.price.toFixed(2)} {plan.devise}
              </div>
            </div>
            <hr />
            <ul>
              {plan.features.map((feature, index) => {
                return (
                  <li key={index}>
                    {feature.reverse ? (
                      <Fragment>
                        {feature.detail} <span className='font-weight-bold'>{feature.amount}</span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <span className='font-weight-bold'>{feature.amount}</span> {feature.detail}
                      </Fragment>
                    )}
                  </li>
                );
              })}
            </ul>
            <div className='text-center'>
              <Button href='#pricing-table' color='link'>
              {getLocale(lang, 'premium', 'allPerks')}
              </Button>
            </div>
            <div className='become-premium'>
              <Button href={plan.link} target='_newblank' color='primary' className={index === selectedPremium ? '' : 'btn-simple'}>
              {getLocale(lang, 'premium', 'becomePremium', [plan.id])}
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PricingPlans;
