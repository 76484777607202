import React, { createContext, useRef, useState, useEffect } from 'react';
import { API, delTokenAndLogOut, LOGIN_URL } from '../helpers/env';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import Axios from 'axios';
import Loading from '../components/Loading';
// import ReactGA from 'react-ga';

const initialContext = {
  user: null,
  setUser: () => {},
  authenticatedRequest: () => {},
};

export const AuthenticatedContext = createContext(initialContext);

export const AuthenticatedProvider = ({ children, ...props }) => {
  const [user, setUser] = useState(null);
  const [initMade, setInitMade] = useState(false);
  const requestCounterRef = useRef(1);
  const timeRef = useRef(0);
  const requestMadeRef = useRef();
  let pageView;
  if (props.location.pathname === '*') pageView = '404';
  else pageView = props.location.pathname;

  // ReactGA.pageview(pageView);

  useEffect(() => {
    authenticatedRequest(() => {
      setInitMade(true);
    });
  }, []);

  const history = useHistory();

  const authenticatedRequest = async (requestFn) => {
    try {
      const res = await Axios.get(`${API}user/me`);
      if (res.status === 403 || res.status === 401) throw new Error('Token expired');
      if (history.location.pathname.startsWith('/admin') && res.data.user.id !== '173622375601012737') throw new Error('Unauthorized');
      setUser(res.data.user);
      requestCounterRef.current = 1;
      requestFn(res);
    } catch (error) {
      delTokenAndLogOut();
      setUser(null);
      window.location.replace(LOGIN_URL);
    }
  };

  const counter = (time) => {
    if (requestCounterRef.current) {
      timeRef.current = time;
      requestCounterRef.current = 0;
    }
    let delta = time - timeRef.current;
    if (delta > 2 * 60 * 60 * 1000) {
      delTokenAndLogOut();
      window.location.replace(LOGIN_URL);
    } else {
      requestMadeRef.current = requestAnimationFrame(counter);
    }
  };

  useEffect(() => {
    requestMadeRef.current = requestAnimationFrame(counter);
    return () => {
      cancelAnimationFrame(requestMadeRef.current);
    };
  }, [user]);

  useEffect(() => {
    if (initMade) {
      if (!user) {
        return () => {
          window.location.replace(LOGIN_URL);
        };
      }
    }
  }, [initMade]);

  return <AuthenticatedContext.Provider value={{ user, setUser, authenticatedRequest }}>{(!user && <Loading />) || children}</AuthenticatedContext.Provider>;
};

export default withRouter(AuthenticatedProvider);
