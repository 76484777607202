import React, { useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import Loading from '../../../Loading';
import SortingTable from '../../../SortingTable/SortingTable';
import moment from 'moment';
import 'moment/locale/fr';
moment().format();
moment.locale('fr');

const LoginHistory = ({ logHistory }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h2'>Dernières connexions</CardTitle>
      </CardHeader>
      <CardBody>
        {logHistory ? (
          <SortingTable thead={[{ text: 'Nom' }, { text: 'Date' }]} tbody={logHistory} />
        ) : (
          <div className='text-center'>
            <Loading />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default LoginHistory;
