import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import { delTokenAndLogOut } from '../../helpers/env';

const Logout = () => {
  const history = useHistory();
  const { setUser } = useContext(AuthenticatedContext);
  const logoutRequest = async () => {
    delTokenAndLogOut();
    setUser(null);
    history.push('/');
  };
  useEffect(() => {
    logoutRequest();
  }, []);
  return <div>log</div>;
};

export default Logout;
