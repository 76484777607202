import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { LOGIN_URL } from '../../helpers/env';
import { getLocale } from '../../helpers/lang';

const Login = ({ lang }) => {
  const discordLogin = () => {
    window.location.href = LOGIN_URL;
  };
  return (
    <Link className='nav-link' to='shop'>
      <Button color='default' onClick={() => discordLogin()}>
        {getLocale(lang || 'en', 'menu', 'login')}
      </Button>
    </Link>
  );
};

export default Login;
