import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { getLocale } from '../../helpers/lang';

const MainFeature = ({feature, lang}) => {
    return (
        <Row className={feature.order ? 'd-flex reverse-col w-100' : 'd-flex w-100'}>
            <Col className='d-flex justify-content-center'>
            <img src={feature.image.src} alt={feature.image.alt} />
            </Col>
            <Col lg={6}>
            <h3>{getLocale(lang, 'homepage', feature.title)}</h3>
            <p>{getLocale(lang, 'homepage', feature.description)}</p>
            <div>
            <Button 
                href='https://discord.com/oauth2/authorize?client_id=726245823389892699&permissions=1342597200&scope=applications.commands%20bot'
                target='_newblank'
                color='primary'>{getLocale(lang, 'homepage', feature.button.text)}</Button>
            </div>
            </Col>
        </Row >
    );
};

export default MainFeature;