import React from 'react';

const Terms = () => {
    return (
        <section className='terms'>
            <h1>Terms of Service</h1>
            <h2>INTRODUCTION</h2>
            <p>Fake (“us,” “we,” or “our”) owns and operates the Fake.gg website and Fake Discord Bot (the “Service”). Fake Discord Bot functions on Discord (the “Platform”). By using our service you implicitly agree to our terms. You may stop using the service at any time if you no longer agree to these terms.</p>
            <h2>SERVICE TERMS</h2>
            <ul>
                <li>We reserve the right to revoke access to the Service at any time for any reason</li>
                <li>While using the Service, you also agree to obey the rules of the Platform - Discord Terms and Discord Guideline</li>
                <li>Your use of the Service is expected to remain as a virtual entertainment. We are not responsible for any real-life or virtual damages caused by your use of the Service</li>
                <li>Any use of the Service for any illegal and/or malicious behavior may result in revoking your rights to the Service</li>
                <li>We are not responsible for any malicious use of the Service done by others</li>
            </ul>
        </section>
    );
};

export default Terms;