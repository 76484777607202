import moment from 'moment';
import React, { Fragment } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { noEmoji, xpEmoji, yesEmoji } from '../../../../helpers/env';
import { numberWithSpaces } from '../../../../helpers/functions';

const Mission = ({ idle, stats }) => {
  const timer = [
    {
      time: 0.25,
      ratio: 20,
    },
    {
      time: 0.5,
      ratio: 16,
    },
    {
      time: 2,
      ratio: 8,
    },
    {
      time: 6,
      ratio: 5,
    },
    {
      time: 12,
      ratio: 3,
    },
    {
      time: 24,
      ratio: 2,
    },
  ];
  const endMission = new Date(idle.date._seconds * 1000 + timer[idle.type].time * 60 * 60 * 1000);
  const now = new Date();
  return (
    <Card className='card-admin-stats'>
      <CardHeader>
        <CardTitle tag='h3'>{xpEmoji} Mission</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            {idle.date._seconds === 0 ? (
              <div>Aucune mission en cours</div>
            ) : (
              <Fragment>
                <div className='pb-2'>En cours</div>
                <div>Mission de {timer[idle.type].time < 1 ? `${timer[idle.type].time} minutes` : `${timer[idle.type].time} heures`}</div>
                {now > endMission ? <Fragment>{yesEmoji} Terminé </Fragment> : <Fragment>{noEmoji} Termine </Fragment>}
                {moment(endMission).fromNow()}
              </Fragment>
            )}
          </Col>
          <Col>
            <div className='pb-2'>Anti cheat</div>
            <div>~{idle.average ? numberWithSpaces(idle.average.toFixed(2)) : '?'} / mois</div>
            <div>~{idle.average_week ? numberWithSpaces(idle.average_week.toFixed(2)) : '?'} / semaine</div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter className='text-center text-muted'>{moment(new Date(idle.date._seconds * 1000)).format('LLL')}</CardFooter>
    </Card>
  );
};

export default Mission;
