/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch, useLocation, Link, Redirect } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// react plugin for creating notifications over the dashboard
// import NotificationAlert from 'react-notification-alert';

import routes from '../../routes.js';

// import logo from '../../assets/img/react-logo.png';
// import logo from '../../logo.svg';
import HomeNavbar from '../../components/Navbars/HomeNavbar.js';
import { DiscordIcon } from '../../components/Homepage/DiscordIcon.jsx';
import { TwitterIcon } from '../../components/Homepage/TwitterIcon.jsx';
import { PatreonIcon } from '../../components/Homepage/PatreonIcon.jsx';
import { getLocale } from '../../helpers/lang.js';
import { useLangStore } from '../../helpers/store.js';

const Global = (props) => {
  const lang = useLangStore((state) => state.lang);
  const getRoutes = (routes) => {
    return routes
      .filter((r) => r.layout === '/home')
      .reverse()
      .map((prop, key) => {
        if (prop.collapse) {
          return getRoutes(prop.views);
        }
        return <Route exact path={prop.path} render={() => <prop.component lang={lang} />} lang={lang} key={key} />;
      });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.pathname.indexOf(routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  return (
    <div className='home-wrapper wrapper'>
      <HomeNavbar {...props} />
      <div className='content'>
        <Switch>
          {getRoutes(routes)}
          <Redirect from='*' to='/' />
        </Switch>
      </div>
      <footer>
        <div>Copyright © 2020 - {new Date().getFullYear()} Fake RPG</div>
        <div>
          <div className='socials'>
            <a href='https://discord.gg/fake' target='_newblank' alt='Discord support'>
              <DiscordIcon />
            </a>
            <a href='https://twitter.com/FakeDofus' target='_newblank' alt='Discord support'>
              <TwitterIcon />
            </a>
            <a href='https://patreon.com/FakeRPG' target='_newblank' alt='Discord support'>
              <PatreonIcon />
            </a>
          </div>
          <div className='footer-menu'>
            <Link to='terms'>{getLocale(lang, 'footer', 'terms')}</Link>
            <Link to='privacy'>{getLocale(lang, 'footer', 'privacy')}</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Global;
