import React from 'react';
import { Button, Table } from 'reactstrap';
import YesImg from '../../assets/img/emojis/yes.svg';
import NoImg from '../../assets/img/emojis/no.svg';
import FakecoinImg from '../../assets/img/coins/fakecoin.png';
import LootcoinImg from '../../assets/img/coins/lootcoin.png';
import DofusImg from '../../assets/img/emojis/emerald.webp';
import BetImg from '../../assets/img/emojis/bet.png';
import QuestImg from '../../assets/img/emojis/quest.webp';
import { getLocale } from '../../helpers/lang';

const PricingTable = ({ lang }) => {

    const renderEmoji = (emoji) => {
        switch (emoji) {
            case 'yes':
                return YesImg;
            case 'no':
                return NoImg;
            case 'fakecoin':
                return FakecoinImg;
            case 'lootcoin':
                return LootcoinImg;
            case 'dofus':
                return DofusImg;
            case 'bet':
                return BetImg;
            case 'quest':
                return QuestImg;
            default:
                return '';
        }
    }
    const rewards = [
        {
            name: {
                start: getLocale(lang, 'premium', 'perksReward1'),
                code: `/reward`,
                end: getLocale(lang, 'premium', 'perksReward2'),
            },
            p0: [
                {
                    amount: `+0%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+0%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p1: [
                {
                    amount: `+25%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+25%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p2: [
                {
                    amount: `+50%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+50%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p3: [
                {
                    amount: `+100%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+100%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksReward1'),
                code: `/misson`,
                end: getLocale(lang, 'premium', 'perksReward2'),
            },
            p0: [
                {
                    amount: `+0%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+0%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p1: [
                {
                    amount: `+25%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+25%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p2: [
                {
                    amount: `+50%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+50%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p3: [
                {
                    amount: `+100%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+100%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksReward1'),
                code: `/fight`,
                end: getLocale(lang, 'premium', 'perksReward2'),
            },
            p0: [
                {
                    amount: `+0%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+0%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p1: [
                {
                    amount: `+25%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+25%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p2: [
                {
                    amount: `+50%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+50%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p3: [
                {
                    amount: `+100%`,
                    emoji: renderEmoji('fakecoin'),
                },
                {
                    amount: `+100%`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksAmountPerHour1'),
                code: `/dofus`,
                end: getLocale(lang, 'premium', 'perksAmountPerHour2'),
            },
            p0: [
                {
                    amount: `1`,
                    emoji: renderEmoji('dofus'),
                },
            ],
            p1: [
                {
                    amount: `2`,
                    emoji: renderEmoji('dofus'),
                },
            ],
            p2: [
                {
                    amount: `3`,
                    emoji: renderEmoji('dofus'),
                },
            ],
            p3: [
                {
                    amount: `4`,
                    emoji: renderEmoji('dofus'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksAmountPerHour1'),
                code: `/bet`,
                end: getLocale(lang, 'premium', 'perksAmountPerHour2'),
            },
            p0: [
                {
                    amount: `1`,
                    emoji: renderEmoji('bet'),
                },
            ],
            p1: [
                {
                    amount: `2`,
                    emoji: renderEmoji('bet'),
                },
            ],
            p2: [
                {
                    amount: `3`,
                    emoji: renderEmoji('bet'),
                },
            ],
            p3: [
                {
                    amount: `4`,
                    emoji: renderEmoji('bet'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksQuestsPerDay'),
                code: ``,
                end: ``,
            },
            p0: [
                {
                    amount: `6`,
                    emoji: renderEmoji('quest'),
                },
            ],
            p1: [
                {
                    amount: `8`,
                    emoji: renderEmoji('quest'),
                },
            ],
            p2: [
                {
                    amount: `10`,
                    emoji: renderEmoji('quest'),
                },
            ],
            p3: [
                {
                    amount: `12`,
                    emoji: renderEmoji('quest'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksRolls'),
                code: `/roll`,
                end: ``,
            },
            p0: [
                {
                    amount: `250`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p1: [
                {
                    amount: `500`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p2: [
                {
                    amount: `750`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
            p3: [
                {
                    amount: `1 000`,
                    emoji: renderEmoji('lootcoin'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksOrnament'),
                code: ``,
                end: ``,
            },
            p0: [
                {
                    amount: ``,
                    emoji: renderEmoji('no'),
                },
            ],
            p1: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p2: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p3: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksChannels'),
                code: ``,
                end: ``,
            },
            p0: [
                {
                    amount: ``,
                    emoji: renderEmoji('no'),
                },
            ],
            p1: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p2: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p3: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksGiveaways'),
                code: ``,
                end: ``,
            },
            p0: [
                {
                    amount: ``,
                    emoji: renderEmoji('no'),
                },
            ],
            p1: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p2: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p3: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksVisualRolls1'),
                code: `/roll`,
                end: getLocale(lang, 'premium', 'perksVisualRolls2'),
            },
            p0: [
                {
                    amount: ``,
                    emoji: renderEmoji('no'),
                },
            ],
            p1: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p2: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p3: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksUniverses'),
                code: ``,
                end: ``,
            },
            p0: [
                {
                    amount: ``,
                    emoji: renderEmoji('no'),
                },
            ],
            p1: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p2: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p3: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksAutoAdventures'),
                code: ``,
                end: ``,
            },
            p0: [
                {
                    amount: ``,
                    emoji: renderEmoji('no'),
                },
            ],
            p1: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p2: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p3: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
        },
        {
            name: {
                start: getLocale(lang, 'premium', 'perksBossSpawn1'),
                code: `/boss`,
                end: getLocale(lang, 'premium', 'perksBossSpawn2'),
            },
            p0: [
                {
                    amount: ``,
                    emoji: renderEmoji('no'),
                },
            ],
            p1: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p2: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
            p3: [
                {
                    amount: ``,
                    emoji: renderEmoji('yes'),
                },
            ],
        },
        {
            name: {
                start: ``,
                code: ``,
                end: ``,
            },
            p1: [
                {
                    amount: ``,
                    emoji: ``,
                    button: {
                        outline: true,
                        link: 'https://www.patreon.com/join/FakeRPG/checkout?rid=6430917',
                    },
                },
            ],
            p2: [
                {
                    amount: ``,
                    emoji: ``,
                    button: {
                        outline: true,
                        link: 'https://www.patreon.com/join/FakeRPG/checkout?rid=6430935',
                    },
                },
            ],
            p3: [
                {
                    amount: ``,
                    emoji: ``,
                    button: {
                        outline: false,
                        link: 'https://www.patreon.com/join/FakeRPG/checkout?rid=6430936',
                    },
                },
            ],
        },
    ]
    return (
        <Table id='pricing-table' hover>
            <thead>
                <tr>
                <th>{getLocale(lang, 'premium', 'perksTitle')}</th>
                <th>{getLocale(lang, 'premium', 'nonPremium')}</th>
                <th>{getLocale(lang, 'premium', 'premium', [1])}</th>
                <th>{getLocale(lang, 'premium', 'premium', [2])}</th>
                <th>{getLocale(lang, 'premium', 'premium', [3])}</th>
                </tr>
            </thead>
            <tbody>
                {
                    rewards.map((reward, index) => {
                        return (
                            <tr>
                                <th scope="row">{reward.name.start}{reward.name.code ? <code>{reward.name.code}</code> : null}{reward.name.end}</th>
                                <td>{ reward.p0 &&
                                reward.p0.map((r, index) => {
                                    return (
                                        <div>
                                            {r.amount} {r.emoji && <img className={r.amount ? `ml-1` : ''} src={r.emoji} alt='reward emoji' /> }
                                        </div>
                                    )
                                })
                                }</td>
                                <td>{reward.p1.map((r, index) => {
                                    return (
                                        <div className='d-flex justify-content-center align-items-center'>
                                            {r.button && <Button href={r.button.link} target='_newblank' color='primary' className={r.button.outline && 'btn-simple'} block>{getLocale(lang, 'premium', 'becomePremium', [1])}</Button>} {r.amount} {r.emoji && <img className={r.amount ? `ml-1` : ''} src={r.emoji} alt='reward emoji' /> }
                                        </div>
                                    )
                                })}</td>
                                <td>{reward.p2.map((r, index) => {
                                    return (
                                        <div>
                                            {r.button && <Button href={r.button.link} target='_newblank' color='primary' className={r.button.outline && 'btn-simple'} block>{getLocale(lang, 'premium', 'becomePremium', [2])}</Button>} {r.amount} {r.emoji && <img className={r.amount ? `ml-1` : ''} src={r.emoji} alt='reward emoji' /> }
                                        </div>
                                    )
                                })}</td>
                                <td>{reward.p3.map((r, index) => {
                                    return (
                                        <div>
                                            {r.button && <Button href={r.button.link} target='_newblank' color='primary' className={r.button.outline && 'btn-simple'} block>{getLocale(lang, 'premium', 'becomePremium', [3])}</Button>} {r.amount} {r.emoji && <img className={r.amount ? `ml-1` : ''} src={r.emoji} alt='reward emoji' /> }
                                        </div>
                                    )
                                })}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    );
};

export default PricingTable;