import React from 'react';
import { Nav, NavLink, NavItem, Badge } from 'reactstrap';

const ItemsNav = ({ tabs, tabList }) => {
  const [verticalTabs, setVerticalTabs] = tabs;
  let tabsNav = tabList.map((tab, key) => {
    return (
      <NavItem style={{ minWidth: `${parseInt(100 / tabList.length)}%` }} key={key}>
        <NavLink data-toggle='tab' href='#' className={verticalTabs === tab.id ? 'active' : ''} onClick={(e) => setVerticalTabs(tab.id)}>
          {tab.name} {tab.id === 'coins' ? <Badge color='primary'>new</Badge> : null}
        </NavLink>
      </NavItem>
    );
  });
  return (
    <Nav className='nav-shop-items nav-pills-info justify-content-around' pills>
      {tabsNav}
    </Nav>
  );
};

export default ItemsNav;
