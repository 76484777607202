import React, { Fragment, useContext, useState } from 'react';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Modal, Row } from 'reactstrap';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import { equipItem } from '../../helpers/API';
import { nitroEmoji, shopcoin } from '../../helpers/env';
import { numberWithSpaces } from '../../helpers/functions';
import { getLocale } from '../../helpers/lang';

const OrnamentCard = ({ ornament, money, collection, buyItem }) => {
  const { user, setUser } = useContext(AuthenticatedContext);
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const owned = collection.includes(ornament.id);
  const toggleModal = () => {
    setModal(!modal);
  };
  const confirmOrderItem = async (ornament) => {
    const owned = collection.includes(ornament.id);
    if (owned) {
      equipItem('ornaments', ornament.id);
      setSuccess(`${ornament.name} equiped!`);
    } else {
      const res = await buyItem('ornaments', ornament.id);
      if (res.status === 'success') {
        collection.push(ornament.id);
        setSuccess(getLocale(user.lang, 'items', 'skinAddedToCollection', [ornament.name]));
        setError(null);
        setUser({ ...user, shopcoin: user.shopcoin - ornament.price });
      } else {
        setError(`Uh oh: ${res && res.message ? res.message : 'something happened.'}`);
        setSuccess(null);
      }
    }
    setModal(null);
  };
  return (
    <Card className='card-ornament'>
      <CardBody className='d-flex flex-column justify-content-between'>
        <div className='d-flex'>
          <img className='ornament-image' src={ornament.left} alt={`${ornament.name} left`} />
          <img className='ornament-image' src={ornament.right} alt={`${ornament.name} right`} />
        </div>
        <div className='mt-3'>
          {ornament.stock <= 5 && ornament.stock > 0 ? <Alert color='warning'>{getLocale(user.lang, 'items', 'almostSoldOut', [ornament.stock])}</Alert> : ''}
          {ornament.stock <= 0 ? <Alert color='danger'>{getLocale(user.lang, 'items', 'outOfStock')}</Alert> : ''}
          {success ? <Alert color='success'>{success}</Alert> : ''}
          {error ? <Alert color='danger'>{error}</Alert> : null}
          <div className='text-right'>
            <b>{ornament.name}</b>
          </div>
        </div>
      </CardBody>
      <Modal isOpen={modal} toggle={toggleModal}>
        <Card className='paypal-card'>
          <CardHeader>
            <CardTitle tag='h2'>{getLocale(user.lang, 'items', 'confirmTitle')}</CardTitle>
          </CardHeader>
          <CardBody>
            {owned ? (
              <Fragment>
                <div className='d-flex justify-content-center mb-3'>
                  <img className='ornament-image' src={ornament.left} alt={`${ornament.name} left`} />
                  <img className='ornament-image' src={ornament.right} alt={`${ornament.name} right`} />
                </div>
                {getLocale(user.lang, 'items', 'iWantToEquip')} <b>{ornament.name}</b>
              </Fragment>
            ) : (
              <Fragment>
                <div className='d-flex justify-content-center mb-3'>
                  <img className='ornament-image' src={ornament.left} alt={`${ornament.name} left`} />
                  <img className='ornament-image' src={ornament.right} alt={`${ornament.name} right`} />
                </div>
                {getLocale(user.lang, 'items', 'confirmDescription1')} <b>{ornament.name}</b> {getLocale(user.lang, 'items', 'confirmDescription2')}{' '}
                <b>
                  {numberWithSpaces(ornament.price)} {shopcoin}
                </b>
              </Fragment>
            )}
          </CardBody>
          <CardFooter className='pt-0'>
            <Row>
              <Col>
                <Button className='btn-simple' color='danger' size='lg' onClick={() => setModal(false)} block>
                  {getLocale(user.lang, 'global', 'cancel')}
                </Button>
              </Col>
              <Col>
                <Button color='info' size='lg' onClick={() => confirmOrderItem(ornament)} block>
                  {getLocale(user.lang, 'global', 'confirm')}
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Modal>
      <Button
        className='d-flex justify-content-center align-items-center'
        color={owned ? 'info' : 'primary'}
        onClick={toggleModal}
        disabled={!owned && (ornament.stock <= 0 || money < ornament.price)}
        block
      >
        {ornament.id === 35 ? (
          owned ? (
            'Equip'
          ) : (
            <Fragment>
              <div className='mr-1'>{nitroEmoji}</div> Claim with Nitro
            </Fragment>
          )
        ) : owned ? (
          'Equip'
        ) : (
          <Fragment>
            <div className='mr-1'>{numberWithSpaces(ornament.price)}</div> {shopcoin}
          </Fragment>
        )}
      </Button>
    </Card>
  );
};

export default OrnamentCard;
