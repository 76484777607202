import React, { useContext, useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Row, TabContent, TabPane } from 'reactstrap';
import ItemsNav from '../../components/Items/ItemsNav';
import OrnamentCard from '../../components/Items/OrnamentCard';
import SkinCard from '../../components/Items/SkinCard';
import Loading from '../../components/Loading';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import { getItems } from '../../helpers/API';
import { capitalize } from '../../helpers/functions';
import { getLocale } from '../../helpers/lang';

const Collection = () => {
  const { user, setUser } = useContext(AuthenticatedContext);
  const [verticalTabs, setVerticalTabs] = useState(getLocale(user.lang, 'collection', 'mySkins'));
  const [skins, setSkins] = useState(null);
  const [ornaments, setOrnaments] = useState(null);
  const tabList = [
    {
      name: getLocale(user.lang, 'items', 'skins'),
      id: getLocale(user.lang, 'collection', 'mySkins'),
    },
    {
      name: getLocale(user.lang, 'items', 'ornaments'),
      id: getLocale(user.lang, 'collection', 'myOrnaments'),
    },
  ];
  const loadSkins = async () => {
    setSkins(await getItems('skins', true));
  };
  const loadOrnaments = async () => {
    setOrnaments(await getItems('ornaments', true));
  };
  if (verticalTabs === getLocale(user.lang, 'collection', 'mySkins') && !skins) {
    // load skins
    loadSkins();
  } else if (verticalTabs === getLocale(user.lang, 'collection', 'myOrnaments') && !ornaments) {
    loadOrnaments();
    // load ornaments
  }
  if (skins) {
    var skinsTab = skins.map((skin, key) => {
      return <SkinCard skin={skin} money={user.shopcoin} collection={user.skin.character.owned} key={key} />;
    });
    if (skinsTab.length === 0) {
      skinsTab = <h2 className='w-100 text-center'>You don't have any skins yet.</h2>;
    }
  }
  if (ornaments) {
    var ornamentsTab = ornaments.map((ornament, key) => {
      return <OrnamentCard ornament={ornament} money={user.shopcoin} collection={user.ornaments} key={key} />;
    });
    if (ornamentsTab.length === 0) {
      ornamentsTab = <h2 className='w-100 text-center'>You don't have any ornaments yet.</h2>;
    }
  }
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <ItemsNav tabs={[verticalTabs, setVerticalTabs]} tabList={tabList} />
            <CardTitle className='mb-0 mt-3' tag='h2'>
              {capitalize(verticalTabs)}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={verticalTabs}>
              <TabPane className={verticalTabs === getLocale(user.lang, 'collection', 'mySkins') ? 'd-flex flex-wrap' : ''} tabId={getLocale(user.lang, 'collection', 'mySkins')}>
                {skinsTab ? skinsTab : <Loading />}
              </TabPane>
              <TabPane
                className={verticalTabs === getLocale(user.lang, 'collection', 'myOrnaments') ? 'd-flex flex-wrap' : ''}
                tabId={getLocale(user.lang, 'collection', 'myOrnaments')}
              >
                {ornamentsTab ? ornamentsTab : <Loading />}
              </TabPane>
              {/* <TabPane tabId='titles'>{titles ? titles : <Loading />}</TabPane> */}
              {/* <TabPane tabId='themes'>
                Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas. <br />
                <br />
                Dynamically innovate resource-leveling customer service for state of the art customer service.
              </TabPane> */}
            </TabContent>
          </CardBody>
        </Card>
        {/* <ItemCard /> */}
      </Col>
    </Row>
  );
};

export default Collection;
