import React, { useContext, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button } from 'reactstrap';
import SortingTable from '../../components/SortingTable/SortingTable.js';
import { getOrders } from '../../helpers/API.js';
import moment from 'moment';
import Loading from '../../components/Loading.js';
import { getLocale } from '../../helpers/lang.js';
import { AuthenticatedContext } from '../../contexts/Authenticated.js';

const Orders = () => {
  const [orders, setOrders] = useState(null);
  const { user } = useContext(AuthenticatedContext);
  const devises = [
    {
      currency: 'EUR',
      symbol: '€',
    },
    {
      currency: 'USD',
      symbol: '$',
    },
    {
      currency: 'GBP',
      symbol: '£',
    },
    {
      currency: 'CHF',
      symbol: 'Fr',
    },
  ];
  const loadOrders = async () => {
    const rawOrders = await getOrders();
    const tab = [];
    rawOrders.forEach((order) => {
      if (order.type === 'pass') order.amount = 0;
      tab.push({
        data: [
          { text: order.pay_id, string: order.pay_id },
          { text: order.pack, string: Number(order.amount) },
          { text: moment(new Date(order.date._seconds * 1000)).format('lll'), string: order.date._seconds },
          {
            className: 'text-center',
            text: order.error ? <i className='tim-icons icon-simple-remove text-danger'></i> : <i className='tim-icons icon-check-2 text-success'></i>,
            string: order.error ? 'fail' : 'success',
          },
          { className: 'text-center', text: `${Number(order.price)} ${devises.find((c) => c.currency === order.currency).symbol || order.currency}`, string: Number(order.price) },
        ],
      });
    });
    if (tab.length === 0) {
      tab.push({
        data: [
          { text: '', string: 'Nothing' },
          { text: '', string: 'Nothing' },
          { text: '', string: 'Nothing' },
          {
            className: 'text-center',
            text: '',
            string: 'Nothing',
          },
          { className: 'text-center', text: '', string: 'Nothing' },
        ],
      });
    }
    setOrders(tab);
  };
  if (!orders) {
    loadOrders();
  }
  return (
    <Row>
      <Col className='mb-5' md='12'>
        <Card>
          <CardHeader className='d-flex justify-content-between align-items-center'>
            <CardTitle tag='h1' className='mb-0'>
              {getLocale(user.lang, 'orders', 'title')}
            </CardTitle>
            <div>
              <Button color='primary' target='_newblank' rel='noreferrer' href='https://discord.gg/fake'>
                {getLocale(user.lang, 'global', 'helpButton')}
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {orders ? (
              <SortingTable
                thead={[
                  { text: getLocale(user.lang, 'orders', 'id') },
                  { text: getLocale(user.lang, 'orders', 'pack') },
                  { text: getLocale(user.lang, 'orders', 'date') },
                  { className: 'text-center', text: getLocale(user.lang, 'orders', 'status') },
                  { className: 'text-center', text: getLocale(user.lang, 'orders', 'price') },
                ]}
                tbody={orders}
              />
            ) : (
              <div className='text-center'>
                <Loading />
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Orders;
