import React, { Component } from 'react';
import { getLocale } from '../../helpers/lang';

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
  componentWillMount() {
    this.getTimeUntil(this.props.deadline);
  }
  componentDidMount() {
    setInterval(() => this.getTimeUntil(this.props.deadline), 1000);
  }
  leading0(num) {
    return num < 10 ? '0' + num : num;
  }
  getTimeUntil(deadline) {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      this.setState({ hours: 0, minutes: 0, seconds: 0 });
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      this.setState({ hours, minutes, seconds });
    }
    if (deadline.getTime() !== 0 && time <= 0) {
      // window.location.reload(false);
    }
  }
  render() {
    return (
      <div className='d-flex flex-wrap'>
        <div>{getLocale(this.props.lang, 'items', 'rotationTitle')} </div>
        <div className='text-center ml-2'>
          {this.leading0(this.state.hours)} {getLocale(this.props.lang, 'items', 'hours')}
        </div>
        <div className='text-center ml-2'>
          {this.leading0(this.state.minutes)} {getLocale(this.props.lang, 'items', 'minutes')}
        </div>
        <div className='text-center ml-2'>
          {this.leading0(this.state.seconds)} {getLocale(this.props.lang, 'items', 'seconds')}
        </div>
      </div>
    );
  }
}
export default Clock;
