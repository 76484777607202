import moment from 'moment';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { numberWithSpaces } from '../../../../helpers/functions';

const TotalSpent = ({ total, vip }) => {
  return (
    <Card className='card-admin-stats'>
      <CardHeader>
        <CardTitle tag='h3'>💶 Dépense totale (VIP {vip})</CardTitle>
      </CardHeader>
      <CardBody className='pt-0'>
        <Row className='h-100'>
          <Col className='d-flex justify-content-center align-items-center display-1'>{numberWithSpaces(total.total.toFixed(2), true)} €</Col>
        </Row>
      </CardBody>
      <CardFooter className='text-center text-muted'>{moment(total.date).format('LLL')}</CardFooter>
    </Card>
  );
};

export default TotalSpent;
