import React, { useState } from 'react';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Input, Label, Row } from 'reactstrap';
import { createOrder } from '../../../helpers/API';
import { noEmoji, shopcoin, yesEmoji } from '../../../helpers/env';

const ManualOrder = ({ user }) => {
  const [euroAmount, setEuroAmount] = useState(0);
  const [shopAmount, setShopAmount] = useState(0);
  const [credit, setCredit] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const sendOrder = async () => {
    if (shopAmount <= 0) {
      return setError('Nombre de shopcoin invalide');
    }
    const res = await createOrder({
      amount: shopAmount,
      price: euroAmount,
      manual_transaction: !credit,
      user: {
        id: user.id,
        avatar: user.avatar,
        username: user.username,
      },
    });
    if (res.status === 'success') {
      return setSuccess(`Commande crée.`);
    } else {
      return setError(`Erreur dans la création de la commande : ${res.message ? res.message : 'raison inconnue.'}`);
    }
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h3'>Créer une commande</CardTitle>
      </CardHeader>
      <CardBody className='text-center'>
        {error && <Alert color='danger'>{error}</Alert>}
        {success && <Alert color='success'>{success}</Alert>}
        <Label for='shopAmount' className='fs-1'>
          {shopcoin}
        </Label>
        <Input type='number' className='fs-1 text-center' name='shopAmount' id='shopAmount' value={shopAmount} onChange={(e) => setShopAmount(Number(e.target.value))} />
        <Label for='euroAmount' className='mt-3 fs-1'>
          💶
        </Label>
        <Input type='number' className='fs-1 text-center' name='euroAmount' id='euroAmount' value={euroAmount} onChange={(e) => setEuroAmount(Number(e.target.value))} />

        <div className='mt-3' onClick={() => setCredit(!credit)}>
          {credit ? yesEmoji : noEmoji}
        </div>
        <Button color='info' className='mt-3' onClick={sendOrder}>
          Créer
        </Button>
      </CardBody>
    </Card>
  );
};

export default ManualOrder;
