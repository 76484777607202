import React from 'react';
import { defaultAvatar } from '../../helpers/env';

const Avatar = ({ user }) => {
  if (!user || !user.username) {
    user = {
      username: 'default',
      avatar: '',
    };
  }
  return (
    <div className='photo'>
      <img
        alt={`${user.username} avatar`}
        src={user.avatar}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = defaultAvatar;
        }}
      />
    </div>
  );
};

export default Avatar;
