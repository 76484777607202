import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap';
import Login from '../Login/Login';
import flagFr from '../../assets/img/emojis/flag_fr.svg';
import flagEn from '../../assets/img/emojis/flag_en.svg';
import { Link } from 'react-router-dom';
import { getLocale } from '../../helpers/lang';
import { langsList } from '../../helpers/env';
import { getLang } from '../../helpers/functions';
import { useLangStore } from '../../helpers/store';

const HomeNavbar = () => {
  const [lang, setLang] = useLangStore((state) => [state.lang, state.setLang]);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const setLocaleLang = (lang) => {
    if (langsList.includes(lang)) {
      setLang(lang);
      localStorage.setItem('lang', lang);
    }
  };
  return (
    <Navbar id='navbar-home' expand='md' dark>
      {/* <NavbarBrand href='/'>reactstrap</NavbarBrand> */}
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className='ml-auto' navbar>
          <NavItem>
            <Link className='nav-link' to='/'>
              {getLocale(lang, 'menu', 'howToPlay')}
            </Link>
          </NavItem>
          {/* <NavItem>
            <Link className='nav-link' to='/'>{getLocale(lang, 'menu', 'features')}</Link>
          </NavItem>
          <NavItem>
            <Link className='nav-link' to='/'>{getLocale(lang, 'menu', 'hallOfFame')}</Link>
          </NavItem> */}
          <NavItem>
            <NavLink href='https://discord.gg/fake'>{getLocale(lang, 'menu', 'supportServer')}</NavLink>
          </NavItem>
          <NavItem>
            <Link className='nav-link premium-link' to='premium'>
              <div className='badge-p1'></div>
              {getLocale(lang, 'menu', 'premium')}
            </Link>
          </NavItem>
          <NavItem>
            <Login lang={lang} />
          </NavItem>
          <NavItem>
            <div className='p-3'>
              <img className={`lang${lang === 'fr' ? '' : ' inactive'}`} src={flagFr} onClick={() => setLocaleLang('fr')} />{' '}
              <img className={`lang${lang === 'en' ? '' : ' inactive'}`} src={flagEn} onClick={() => setLocaleLang('en')} />
            </div>
          </NavItem>
        </Nav>

        {/* <NavbarText>Simple Text</NavbarText> */}
      </Collapse>
      <span className={isOpen ? 'd-none w-100 text-right' : 'login-link'}>
        <Login lang={lang} />
      </span>
    </Navbar>
  );
};

export default HomeNavbar;
