import React, { Fragment, useContext, useState } from 'react';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Modal, Row } from 'reactstrap';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import { bossEmoji, dailyEmoji, shopcoin } from '../../helpers/env';
import { numberWithSpaces } from '../../helpers/functions';
import { getLocale } from '../../helpers/lang';
import Loading from '../Loading';

const ServiceCard = ({ service, money, buyItem }) => {
  const { user, setUser } = useContext(AuthenticatedContext);
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  //   const [description, setDescription] = useState('');
  let description = null;
  const toggleModal = () => {
    setModal(!modal);
  };
  const confirmOrderItem = async (service) => {
    const res = await buyItem('services', service.id);
    if (res.status === 'success') {
      setSuccess(getLocale(user.lang, 'items', 'serviceDone', [service.name[user.lang]]));
      setError(null);
      setUser({ ...user, shopcoin: user.shopcoin - service.price });
    } else {
      setError(`Uh oh: ${res && res.message ? res.message : 'something happened.'}`);
      setSuccess(null);
    }
    setModal(null);
  };

  const findDescription = () => {
    switch (service.value.type) {
      case 'recovery': {
        switch (service.value.data) {
          case 'reward': {
            description = (
              <Fragment>
                <div>
                  {getLocale(user.lang, 'items', 'currentStreak')} {user.stats.reward.inARow} {dailyEmoji}
                </div>
                <div>
                  {getLocale(user.lang, 'items', 'maxStreak')} {user.stats.reward.maxInARow} {dailyEmoji}
                </div>
              </Fragment>
            );
            break;
          }
          case 'boss': {
            description = (
              <Fragment>
                <div>
                  {getLocale(user.lang, 'items', 'currentStreak')} {user.stats.boss.inARow} {bossEmoji}
                </div>
                <div>
                  {getLocale(user.lang, 'items', 'maxStreak')} {user.stats.boss.maxInARow} {bossEmoji}
                </div>
              </Fragment>
            );
            break;
          }
          case 'attributes': {
            description = (
              <Fragment>
                <div>{service.description[user.lang] ? service.description[user.lang] : ''}</div>
              </Fragment>
            );
            break;
          }
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
  };
  if (!description) {
    findDescription();
  }
  return (
    <Card className='card-ornament'>
      <CardBody className='d-flex flex-column justify-content-between'>
        <div className='d-flex justify-content-center'>{service.image ? <img className='ornament-image' src={service.image} alt={service.name[user.lang]} /> : null}</div>
        <div className='mt-3'>
          {success ? <Alert color='success'>{success}</Alert> : ''}
          {error ? <Alert color='danger'>{error}</Alert> : null}
          <div>
            <b>{service.name[user.lang]}</b>
            <div className='service-description'>{description ? description : <Loading />}</div>
          </div>
        </div>
      </CardBody>
      <Modal isOpen={modal} toggle={toggleModal}>
        <Card className='paypal-card'>
          <CardHeader>
            <CardTitle tag='h2'>{getLocale(user.lang, 'items', 'confirmTitle')}</CardTitle>
          </CardHeader>
          <CardBody>
            <Fragment>
              {service.image ? (
                <div className='d-flex justify-content-center'>
                  <img className='ornament-image' src={service.image} alt={service.name[user.lang]} />
                </div>
              ) : null}
              {getLocale(user.lang, 'items', 'confirmDescription1')} <b>{service.name[user.lang]}</b> {getLocale(user.lang, 'items', 'confirmDescription2')}{' '}
              <b>
                {numberWithSpaces(service.price)} {shopcoin}
              </b>
            </Fragment>
          </CardBody>
          <CardFooter className='pt-0'>
            <Row>
              <Col>
                <Button className='btn-simple' color='danger' size='lg' onClick={() => setModal(false)} block>
                  {getLocale(user.lang, 'global', 'cancel')}
                </Button>
              </Col>
              <Col>
                <Button color='info' size='lg' onClick={() => confirmOrderItem(service)} block>
                  {getLocale(user.lang, 'global', 'confirm')}
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Modal>
      <Button className='d-flex justify-content-center align-items-center' color='primary' onClick={toggleModal} disabled={money < service.price} block>
        <div className='mr-1'>{numberWithSpaces(service.price)}</div> {shopcoin}
      </Button>
    </Card>
  );
};

export default ServiceCard;
