import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { API } from '../helpers/env';
import { numberWithSpaces } from '../helpers/functions';

const Paypal = (props) => {
  const paypal = useRef();
  const { plan, lang, vipAmount } = props;

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: 'CAPTURE',
            purchase_units: [
              {
                description: props.type === 'pass' ? props.title + ' ' + plan.title[lang || 'en'] : `${vipAmount} Shopcoins`,
                amount: {
                  currency_code: 'EUR',
                  value: props.type === 'pass' ? plan.price * plan.months : props.price,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          sendOrder(data);
        },
        onError: (err) => {
          props.setOrder({ status: 'error' });
        },
      })
      .render(paypal.current);
  }, []);

  const sendOrder = async (order) => {
    props.setOrder({ loading: true });
    const res = await axios.post(`${API}order/new`, {
      order: order,
      pack: {
        amount: props.sales && props.sales > 0 ? props.sales : props.amount,
        type: props.type,
      },
    });
    props.setOrder({ ...res.data, loading: false });
  };
  return <div ref={paypal}></div>;
};

export default Paypal;
