const strings = {
  fr: {
    global: {
      confirm: `Confirmer`,
      cancel: `Annuler`,
      helpButton: `Besoin d'aide ?`,
      colon: ' :',
    },
    vip: {
      nextLevel: `Prochain niveau :`,
      claimed: `obtenu`,
      current: `en cours`,
      claim: `obtenir`,
      reward: `récompense :`,
      silverChestOpen: `Coffre en argent ouvert !`,
      goldenChestOpen: `Coffre en or ouvert !`,
      legendaryChestOpen: `Coffre légendaire ouvert !`,
      rewardClaimed: `Récompense obtenue !`,
    },
    pack: {
      monthlyPass: `Pass Mensuel`,
      monthlyPassDescription1: `Le pass te permet de gagner `,
      monthlyPassDescription2: `chaque jour pendant 30 jours, n'oublie pas de les réclamer chaque jour avec`,
      monthlyPassDescription3: `pour les tous les obtenir !`,
      monthlyPassPrice: `Reçois %VAR% %VAR% dans 30 jours`,
      packTitle: `Pack de %VAR%`,
      extra: `BONUS`,
      savePerYear: `Économise %VAR%€ chaque année`,
      pricePerMonth: `%VAR%€/mois`,
      billedOnce: `Facturation unique. Pas de renouvellement automatique.`,
      subscribeButton: `Je m'abonne`,
      promotion: `Promotion`,
      bestValue: `Meilleur prix`,
      promotionEnds: `la promotion termine`,
      vipExtra: `VIP BONUS`,
    },
    items: {
      rotationTitle: `Actualisation de la rotation des skins dans`,
      hours: `heures`,
      minutes: `minutes`,
      seconds: `secondes`,
      confirmTitle: `Merci de confirmer`,
      confirmDescription1: `Je veux acheter`,
      confirmDescription2: `pour`,
      skins: `Skins`,
      ornaments: `Ornements`,
      services: `Services`,
      permanentSkins: `Skins permanents`,
      equip: `Équiper`,
      skinAddedToCollection: `%VAR% ajouté à ta collection !`,
      skinEquiped: `%VAR% équipé !`,
      outOfStock: `Rupture de stock`,
      almostSoldOut: `Il n'en reste plus que %VAR% en stock`,
      original: `Original`,
      originalDescription: `Les skins originaux sont des illustrations originales réalisées pour Fake RPG`,
      new: `Nouveau`,
      newDescription: `Les nouveaux skins sont des illustrations sorties il y a moins de deux semaines.`,
      iWantToEquip: `Je veux équiper`,
      serviceDone: `%VAR% utilisé !`,
      currentStreak: `Ta série actuelle :`,
      maxStreak: `Ta série maximum :`,
    },
    collection: {
      noSkins: `Tu n'as pas encore de skins.`,
      noOrnament: `Tu n'as pas encore d'ornements.`,
      mySkins: `Mes skins`,
      myOrnaments: `Mes ornaments`,
    },
    exchange: {
      balance: `Solde :`,
      description: `Je veux échanger :`,
      button: `Je veux`,
      changeTips1: `Changer pour`,
      changeTips2: `ici !`,
      confirmDescription1: `Je veux échanger`,
      confirmDescription2: `en`,
      historyTitle: `Historique d'échanges`,
      date: `Date`,
      from: `De`,
      to: `Vers`,
      status: `Statut`,
      successful: `Échange terminé :`,
      failed: `Échange refusé :`,
    },
    orders: {
      title: `Historique de commandes`,
      id: `#ID`,
      pack: `Pack`,
      date: `Date`,
      status: `Statut`,
      price: `Prix`,
    },
    menu: {
      howToPlay: `Comment jouer`,
      features: `Fonctionnalités`,
      hallOfFame: `Meilleurs joueurs`,
      supportServer: `Serveur d'aide`,
      premium: `Premium`,
      login: `Boutique`,
    },
    footer: {
      terms: `Conditions d'utilisation`,
      privacy: `Politique de confidentialité`,
    },
    // getLocale(lang, 'premium', '')
    homepage: {
      title: `Joue à Fake RPG`,
      subtitle: `Joue à l'idle game Fake RPG dans l'univers de ton choix :`,
      feature1: `PvP`,
      feature2: `Rolls`,
      feature3: `Guildes`,
      feature4: `Quetes`,
      feature5: `Combats de boss`,
      feature6: `Aventures`,
      feature7: `Classements`,
      feature8: `Univers parallèles`,
      feature9: `Items & butins`,
      feature10: `Et bien d'autres fonctionnalités...`,
      addToDiscord: `Invite moi`,
      tryItFirst: `Rejoins-nous`,
      usedByServers: `Utilisé par %VAR%+ serveurs.`,
      spotlightAdventures: `Lance toi dans une aventure`,
      spotlightAdventuresDescription: `Monte les paliers d'aventure et découvre l'histoire du jeu, obtiens des récompense et deviens meilleur ! 💪`,
      spotlightAdventuresButton: `Démarre l'aventure`,
      spotlightProfile: `Améliore ton profil`,
      spotlightProfileDescription: `Monte de niveaux, augmente ta puissance et joue avec (ou contre) tes amis 😈`,
      spotlightProfileButton: `Joue avec tes amis`,
      spotlightGuild: `Crée une guilde avec tes amis`,
      spotlightGuildDescription: `Monte une guilde et organisez des évenements de guilde comme des raids JcE ou lancez-vous dans la conquête du classement JcJ ⚔️`,
      spotlightGuildButton: `Rejoint une guilde`,
    },
    premium: {
      title: `Premium`,
      subtitle1: `Fait passer ton aventure au niveau supérieur`,
      subtitle2: `Libère tout le potentiel de ton aventure en bénéficiant d'avantages premium.`,
      monthlySubscription: `Abonnement mensuel`,
      popular: `Populaire`,
      perks1: `sur les récompenses`,
      perks2: `commande(s) par interval de 2 heures`,
      perks3: `quêtes`,
      perks4: `Roll augmentés jusque`,
      perks5: `avantages supplémentaires...`,
      allPerks: `Tous les avantages`,
      becomePremium: `Devenir premium %VAR%`,
      usedByPlayers: `Essayé par %VAR%+ joueurs.`,
      perksTitle: `Comparaison des premiums`,
      nonPremium: `Non premium`,
      premium: `Premium %VAR%`,
      perksReward1: `Récompenses de `,
      perksReward2: ``,
      perksAmountPerHour1: `Nombre de `,
      perksAmountPerHour2: ` par interval de 2 heures`,
      perksQuestsPerDay: `Nombre de quêtes quotidiennes + hebdomadaires par jour`,
      perksRolls: `Nombre de jetons utilisable par commande `,
      perksOrnament: `Ornement exclusif`,
      perksChannels: `Accès au canaux premium`,
      perksGiveaways: `Accès au giveaways premium`,
      perksVisualRolls1: `Visualisation du système de malchance sur la commande `,
      perksVisualRolls2: ``,
      perksUniverses: `Forcer l'univers de son choix sur tous les serveurs`,
      perksAutoAdventures: `Mode automatique sur les aventures`,
      perksBossSpawn1: `Heure exacte d'apparition des boss avec la commande `,
      perksBossSpawn2: ``,
    },
  },
  en: {
    global: {
      confirm: `confirm`,
      cancel: `cancel`,
      helpButton: `Need help?`,
      colon: ':',
    },
    vip: {
      nextLevel: `Next level:`,
      claimed: `claimed`,
      current: `current`,
      claim: `claim`,
      reward: `reward:`,
      silverChestOpen: `Silver Chest open!`,
      goldenChestOpen: `Golden Chest open!`,
      legendaryChestOpen: `Legendary Chest open!`,
      rewardClaimed: `Reward claimed!`,
    },
    pack: {
      monthlyPass: `Monthly Pass`,
      monthlyPassDescription1: `The pass allows you to earn `,
      monthlyPassDescription2: ` every day for 30 days, don't forget to claim them every day with`,
      monthlyPassDescription3: `to get them all!`,
      monthlyPassPrice: `Get %VAR% %VAR% in 30 days`,
      packTitle: `Pack of %VAR%`,
      extra: `EXTRA`,
      savePerYear: `Save %VAR%€ per year`,
      pricePerMonth: `%VAR%€/month`,
      billedOnce: `Billed one time. No automatic renewal.`,
      subscribeButton: `Subscribe Now`,
      promotion: `Sales`,
      bestValue: `Best value`,
      promotionEnds: `the offer ends`,
      vipExtra: `VIP EXTRA`,
    },
    items: {
      rotationTitle: `Skin rotation refresh in`,
      hours: `hours`,
      minutes: `minutes`,
      seconds: `seconds`,
      confirmTitle: `Please confirm`,
      confirmDescription1: `I want to buy`,
      confirmDescription2: `for`,
      skins: `Skins`,
      ornaments: `Ornaments`,
      services: `Services`,
      permanentSkins: `Permanent skins`,
      equip: `Equip`,
      skinAddedToCollection: `%VAR% added to your collection!`,
      skinEquiped: `%VAR% equiped!`,
      outOfStock: `Out of stock`,
      almostSoldOut: `Only %VAR% left in stock`,
      original: `Original`,
      originalDescription: `Original skins are original artwork made for Fake RPG`,
      new: `New`,
      newDescription: `New skins are artwork released less than two weeks ago`,
      iWantToEquip: `I want to equip`,
      serviceDone: `%VAR% done!`,
      currentStreak: `Your current streak:`,
      maxStreak: `Your max streak:`,
    },
    collection: {
      noSkins: `You don't have any skins yet.`,
      noOrnament: `You don't have any ornaments yet.`,
      mySkins: `My skins`,
      myOrnaments: `My ornaments`,
    },
    exchange: {
      balance: `Balance:`,
      description: `I want to exchange:`,
      button: `I want`,
      changeTips1: `Change to`,
      changeTips2: `here!`,
      confirmDescription1: `I want to exchange`,
      confirmDescription2: `into`,
      historyTitle: `Exchange history`,
      date: `Date`,
      from: `From`,
      to: `To`,
      status: `Status`,
      successful: `Exchange successful:`,
      failed: `Exchange failed:`,
    },
    orders: {
      title: `Order history`,
      id: `#ID`,
      pack: `Pack`,
      date: `Date`,
      status: `Status`,
      price: `Price`,
    },
    menu: {
      howToPlay: `How to play`,
      features: `Features`,
      hallOfFame: `Hall of Fame`,
      supportServer: `Support server`,
      premium: `Premium`,
      login: `Shop`,
    },
    footer: {
      terms: `Terms of Service`,
      privacy: `Privacy Policy`,
    },
    homepage: {
      title: `This is Fake RPG`,
      subtitle: `Play the idle game Fake RPG in the universe of your choice:`,
      feature1: `PvP`,
      feature2: `Rolls`,
      feature3: `Guilds`,
      feature4: `Quests`,
      feature5: `Boss fights`,
      feature6: `Adventures`,
      feature7: `Ladderboards`,
      feature8: `Cross-universes`,
      feature9: `Collectibles & Loot`,
      feature10: `And many more features...`,
      addToDiscord: `Invite me`,
      tryItFirst: `Join us`,
      usedByServers: `Used by %VAR%+ servers.`,
      spotlightAdventures: `Start a new adventure`,
      spotlightAdventuresDescription: `Climb the adventure levels and discover the game's story, get rewards and become better! 💪`,
      spotlightAdventuresButton: `Start the adventure`,
      spotlightProfile: `Improve your profile`,
      spotlightProfileDescription: `Level up, increase your power and play with (or against) your friends 😈`,
      spotlightProfileButton: `Play with your friends`,
      spotlightGuild: `Create a guild with your friends`,
      spotlightGuildDescription: `Build a guild and organize guild events like PvE raids or challenge for PvP rankings ⚔️`,
      spotlightGuildButton: `Join a guild`,
    },
    premium: {
      title: `Premium`,
      subtitle1: `Take your journey to the next level`,
      subtitle2: `Unlock the full potential of your journey by getting premium perks`,
      monthlySubscription: `Monthly subscription`,
      popular: `Popular`,
      perks1: `on rewards`,
      perks2: `command(s) by 2-hour interval`,
      perks3: `quests`,
      perks4: `Rolls increased up to`,
      perks5: `additional perks...`,
      allPerks: `All perks`,
      becomePremium: `Become premium %VAR%`,
      usedByPlayers: `Tried by %VAR%+ players.`,
      perksTitle: `Premiums comparison`,
      nonPremium: `Non-premium`,
      premium: `Premium %VAR%`,
      perksReward1: ``,
      perksReward2: ` rewards`,
      perksAmountPerHour1: `Number of `,
      perksAmountPerHour2: ` per 2-hour interval`,
      perksQuestsPerDay: `Number of daily + weekly quests per day`,
      perksRolls: `Amount of coins usable per command `,
      perksOrnament: `Exclusive ornament`,
      perksChannels: `Access to premium channels`,
      perksGiveaways: `Access to premium giveaways`,
      perksVisualRolls1: `Visualization of the bad luck system on the `,
      perksVisualRolls2: ` command`,
      perksUniverses: `Force the universe of your choice on all servers`,
      perksAutoAdventures: `Automatic mode on adventures`,
      perksBossSpawn1: `Exact time for bosses spawn with the `,
      perksBossSpawn2: ` command`,
    },
  },
};

export const getLocale = (language, category, string, vars) => {
  let locale = strings[language && (language === 'fr' || language === 'en') ? language : 'en'][category][string];
  let count = 0;
  locale = locale.replace(/%VAR%/g, () => (vars[count] !== null ? vars[count++] : '%VAR%'));
  return locale;
};
