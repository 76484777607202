import React, { useEffect, useState } from 'react';
// import './App.css';
import Login from './views/Login/Login';
import axios from 'axios';
import characterImage from './assets/img/banners/bg-character.png';
import mainBackgroundImage from './assets/img/banners/bg-main.png';
import windowBackgroundImage from './assets/img/banners/bg-window.png';
import pokemonImg from './assets/img/emojis/universes/pokemon.png';
import lolImg from './assets/img/emojis/universes/lol.png';
import dofusImg from './assets/img/emojis/universes/dofus.png';
import wowImg from './assets/img/emojis/universes/wow.png';
import fakeImg from './assets/img/servers/fake.png';
import dofusbookImg from './assets/img/servers/dofusbook.png';
import onegeekImg from './assets/img/servers/onegeek.png';
import kryptuneaImg from './assets/img/servers/kryptunea.png';
import disciplesdezeusImg from './assets/img/servers/disciplesdezeus.png';
import radinImg from './assets/img/servers/radin.png';
import adventuresImg from './assets/img/features/adventures.png';
import profileImg from './assets/img/features/profile.png';
import guildsImg from './assets/img/features/guilds.png';
import MainFeature from './components/Homepage/MainFeature';


import { Button, Col } from 'reactstrap';
import { getLocale } from './helpers/lang';
import { getLang } from './helpers/functions';
import { useLangStore } from './helpers/store';

axios.defaults.withCredentials = true;
const App = () => {
  const lang = useLangStore(state => state.lang);
  useEffect(() => {
    let characterBackground = document.getElementById('character-bg');
    let mainBackground = document.getElementById('main-bg');
    let windowBackground = document.getElementById('window-bg');
    window.addEventListener('scroll', () => {
      let value = window.scrollY;
      characterBackground.style.bottom = value * -0.1 + 'px';
      mainBackground.style.top = value * 0.05 + 'px';
      windowBackground.style.top = value * 0.3 + 'px';
    });
    document.addEventListener('mousemove', (e) => {
      [characterBackground, windowBackground, mainBackground].forEach(layer => {
        const speed = layer.getAttribute('data-speed');
        const x = (window.innerWidth - e.pageX * speed) / 100;
        const y = (window.innerHeight - e.pageY * speed) / 100;
        layer.style.transform = `scale(1.025) translateX(${x}px) translateY(${y}px)`;
      })
    })
  }, []);
  useEffect(() => {
    // const lang = getLang();
    // getLocale(lang);
    console.log('lang changed')
  }, [lang]);
  const universesEmojis = [
    pokemonImg,
    lolImg,
    dofusImg,
    wowImg,
  ];
  const serversImages = [
    fakeImg,
    dofusbookImg,
    onegeekImg,
    kryptuneaImg,
    disciplesdezeusImg,
    radinImg,
  ];
  const features = [
    {
      title: `spotlightAdventures`,
      description: `spotlightAdventuresDescription`,
      button: {
        text: `spotlightAdventuresButton`,
        color: `primary`,
        outline: false,
      },
      image: {
        src: adventuresImg,
        alt: `Aventures`,
      },
      order: 0,
    },
    {
      title: `spotlightProfile`,
      description: `spotlightProfileDescription`,
      button: {
        text: `spotlightProfileButton`,
        color: `primary`,
        outline: false,
      },
      image: {
        src: profileImg,
        alt: `Profile`,
      },
      order: 1,
    },
    {
      title: `spotlightGuild`,
      description: `spotlightGuildDescription`,
      button: {
        text: `spotlightGuildButton`,
        color: `primary`,
        outline: false,
      },
      image: {
        src: guildsImg,
        alt: `Guilds`,
      },
      order: 0,
    },
  ];
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={windowBackgroundImage} id='window-bg' data-speed='0.25' className='bg-images bg-background' alt='window background' />
        <img src={mainBackgroundImage} id='main-bg' data-speed='0.75' className='bg-images bg-background' alt='inkeep background' />
        <img src={characterImage} id='character-bg' data-speed='1.5' className='bg-images bg-character' alt='character background' />
        <div>
          <div className='character'>
            <Col className='App-description' lg={6}>
              <div>
                <div className='titles'>
                <h1>{getLocale(lang, 'homepage', 'title')}</h1>
                <h2>{getLocale(lang, 'homepage', 'subtitle')}</h2>
                <div className='universes-list'>
                {universesEmojis.map((emoji, index) => {
                  return <img src={emoji} key={index} alt={`universe ${index}`} />
                })}
                </div>
                </div>
                <div className='add-buttons'>
                  <Button 
                    href='https://discord.com/oauth2/authorize?client_id=726245823389892699&permissions=1342597200&scope=applications.commands%20bot'
                    target='_newblank'
                    color='default'>{getLocale(lang, 'homepage', 'addToDiscord')}</Button>
                   
                  <Button
                    href='https://discord.gg/fake'
                    target='_newblank'
                    color='primary'
                    className='btn-simple'>
                    {getLocale(lang, 'homepage', 'tryItFirst')}
                  </Button>
                </div>
                <div className='d-flex align-items-end justify-content-between'>
                  <ul>
                    <li>{getLocale(lang, 'homepage', 'feature1')}</li>
                    <li>{getLocale(lang, 'homepage', 'feature2')}</li>
                    <li>{getLocale(lang, 'homepage', 'feature3')}</li>
                    <li>{getLocale(lang, 'homepage', 'feature4')}</li>
                    <li>{getLocale(lang, 'homepage', 'feature5')}</li>
                  </ul>
                  <ul>
                    <li>{getLocale(lang, 'homepage', 'feature6')}</li>
                    <li>{getLocale(lang, 'homepage', 'feature7')}</li>
                    <li>{getLocale(lang, 'homepage', 'feature8')}</li>
                    <li>{getLocale(lang, 'homepage', 'feature9')}</li>
                    <li>{getLocale(lang, 'homepage', 'feature10')}</li>
                  </ul>
                </div>
              </div>
            </Col>
          </div>
        </div>
        {/* <Login /> */}
        {/* <Logout /> */}
      </header>
      <section className='section-separator'>
        <Col className='title' lg={6}>
          <div>
          <h2>{getLocale(lang, 'homepage', 'usedByServers', ['2,000'])}</h2>
          </div>
        </Col>
        <Col className='servers-list'>
        {serversImages.map((emoji, index) => {
          return <img src={emoji} key={index} alt={`server ${index}`} />
        })}
        </Col>
      </section>
      {
        features.map((feature, index) => {
          return (
            <section className='main-features'>
              <MainFeature feature={feature} key={index} lang={lang} />
            </section>
          )
        })
      }
    </div>
  );
}

export default App;
