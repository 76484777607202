/* eslint-disable default-case */
import axios from 'axios';
import shopcoinImg from '../assets/img/coins/gems.png';
import fakecoinImg from '../assets/img/coins/fakecoin.png';
import lootcoinImg from '../assets/img/coins/lootcoin.png';
import legendarycoinImg from '../assets/img/coins/legendarycoin.png';
import randomSkinImg from '../assets/img/emojis/random-skin.jpg';
import ornamentVip4LeftImg from '../assets/img/ornaments/vip4_left.webp';
import ornamentVip4RightImg from '../assets/img/ornaments/vip4_right.webp';
import ornamentVip9LeftImg from '../assets/img/ornaments/vip9_left.webp';
import ornamentVip9RightImg from '../assets/img/ornaments/vip9_right.webp';
import energyImg from '../assets/img/emojis/energy.webp';
import energyMaxImg from '../assets/img/emojis/energy_max.png';
import silverChestImg from '../assets/img/emojis/silver_chest.png';
import goldChestImg from '../assets/img/emojis/gold_chest.png';
import legendaryChestImg from '../assets/img/emojis/legendary_chest.png';
import premium1 from '../assets/img/premium/p1.png';
import premium2 from '../assets/img/premium/p2.png';
import premium3 from '../assets/img/premium/p3.png';
import premium4 from '../assets/img/premium/p4.png';

import levelImg from '../assets/img/emojis/level.png';
import attackImg from '../assets/img/emojis/attack.png';
import xpImg from '../assets/img/emojis/xp.png';
import honorImg from '../assets/img/emojis/honor.png';
import hpImg from '../assets/img/emojis/hp.svg';
import trophyImg from '../assets/img/emojis/trophy.svg';
import dailyImg from '../assets/img/emojis/daily.svg';
import rewardImg from '../assets/img/emojis/reward.svg';
import betImg from '../assets/img/emojis/bet.png';
import dofusOneImg from '../assets/img/emojis/emerald.webp';
import dofusTwoImg from '../assets/img/emojis/crimson.webp';
import dofusThreeImg from '../assets/img/emojis/turquoise.webp';
import dofusFourImg from '../assets/img/emojis/ochre.webp';
import dofusFiveImg from '../assets/img/emojis/ivory.webp';
import dofusSixImg from '../assets/img/emojis/ebony.webp';
import rouletteImg from '../assets/img/emojis/roulette.png';
import yesImg from '../assets/img/emojis/yes.gif';
import noImg from '../assets/img/emojis/no.gif';
import nitroImg from '../assets/img/emojis/nitro.png';
import bossImg from '../assets/img/emojis/boss.png';

import defaultAvatarImg from '../assets/img/default_avatar.png';
import rstarImg from '../assets/img/rstar.png';

import bronzeImg from '../assets/img/pvp/bronze.png';
import ironImg from '../assets/img/pvp/iron.png';
import goldImg from '../assets/img/pvp/gold.png';
import platiniumImg from '../assets/img/pvp/platinium.png';
import diamondImg from '../assets/img/pvp/diamond.png';
import legendImg from '../assets/img/pvp/legend.png';

import o_4k_left from '../assets/img/ornaments/790245906929942561.png';
import o_4k_right from '../assets/img/ornaments/790245936210509852.png';
import o_6k_left from '../assets/img/ornaments/790245945791086662.png';
import o_6k_right from '../assets/img/ornaments/790245953293516830.png';
import o_8k_left from '../assets/img/ornaments/790245962315464734.png';
import o_8k_right from '../assets/img/ornaments/790245970143084544.png';
import o_10k_left from '../assets/img/ornaments/790245980175466526.png';
import o_10k_right from '../assets/img/ornaments/790245979952644147.png';
import o_12k_left from '../assets/img/ornaments/790246002584977418.png';
import o_12k_right from '../assets/img/ornaments/790246002325979136.png';
import o_14k_left from '../assets/img/ornaments/790246032876765236.png';
import o_14k_right from '../assets/img/ornaments/790246033078353950.png';
import o_16k_left from '../assets/img/ornaments/790246046562254918.png';
import o_16k_right from '../assets/img/ornaments/790246046802509884.png';
import o_18k_left from '../assets/img/ornaments/790895058257051657.png';
import o_18k_right from '../assets/img/ornaments/790895058235817985.png';
import o_20k_left from '../assets/img/ornaments/790886726260359168.png';
import o_20k_right from '../assets/img/ornaments/790886725987205133.png';
import o_brakmar_left from '../assets/img/ornaments/789712715685691432.png';
import o_brakmar_right from '../assets/img/ornaments/789712616888205352.png';
import o_bonta_left from '../assets/img/ornaments/789711134391402536.png';
import o_bonta_right from '../assets/img/ornaments/789711126073049108.png';
import o_challenges_left from '../assets/img/ornaments/790651340895944734.png';
import o_challenges_right from '../assets/img/ornaments/790651340950470666.png';
import o_sram_left from '../assets/img/ornaments/790246060236079165.png';
import o_sram_right from '../assets/img/ornaments/790246060823412746.png';
import o_xelor_left from '../assets/img/ornaments/790246074178469908.png';
import o_xelor_right from '../assets/img/ornaments/790246074207174706.png';
import o_ecaflip_left from '../assets/img/ornaments/790651372273664050.png';
import o_ecaflip_right from '../assets/img/ornaments/790651372537249822.png';
import o_enutrof_left from '../assets/img/ornaments/790651384851595285.png';
import o_enutrof_right from '../assets/img/ornaments/790651385163153408.png';
import o_om475_left from '../assets/img/ornaments/790651399985299456.png';
import o_om475_right from '../assets/img/ornaments/790651399461535805.png';
import o_baleine_left from '../assets/img/ornaments/790651356842819584.png';
import o_baleine_right from '../assets/img/ornaments/790651356544237618.png';
import o_chafercrit_left from '../assets/img/ornaments/746385259930714203.png';
import o_chafercrit_right from '../assets/img/ornaments/797197939910639616.png';
import o_chafer_left from '../assets/img/ornaments/746385107023036536.png';
import o_chafer_right from '../assets/img/ornaments/797198458997964830.png';
import o_saintvalentin_left from '../assets/img/ornaments/805193261173178418.png';
import o_saintvalentin_right from '../assets/img/ornaments/805193261245136926.png';
import o_bp1_left from '../assets/img/ornaments/868226267869245522.png';
import o_bp1_right from '../assets/img/ornaments/868226268187992094.png';
import o_bp2_left from '../assets/img/ornaments/880521486740357170.png';
import o_bp2_right from '../assets/img/ornaments/880521487373725706.png';
import o_nitro_left from '../assets/img/ornaments/888511943579680809.gif';
import o_nitro_right from '../assets/img/ornaments/888511945530036284.gif';

import dofusEmoji from '../assets/img/emojis/universes/dofus.png';
import eliotropeEmoji from '../assets/img/emojis/families/eliotrope.png';
import huppermageEmoji from '../assets/img/emojis/families/huppermage.png';
import roublardEmoji from '../assets/img/emojis/families/roublard.png';
import sacrieurEmoji from '../assets/img/emojis/families/sacrieur.png';
import { numberWithSpaces } from './functions';

export const API = 'https://fake.gg:5001/';
export const LOGIN_URL =
  API === 'https://localhost:5001/'
    ? 'https://discord.com/oauth2/authorize?response_type=code&scope=identify%20email%20guilds&client_id=736324168588591235'
    : 'https://discord.com/oauth2/authorize?response_type=code&scope=identify%20email%20guilds&client_id=726245823389892699';
export const getToken = () => {
  return JSON.parse(localStorage.getItem('token'));
};

export const delTokenAndLogOut = () => {
  axios.get(`${API}user/logout`);
};

export const langsList = ['en', 'fr'];
export const defaultAvatar = defaultAvatarImg;
export const shopcoin = <img className='coin' alt='shopcoin' src={shopcoinImg} />;
export const shopcoinLarge = <img style={{ width: '48px' }} className='coin' alt='shopcoin' src={shopcoinImg} />;
export const fakecoin = <img className='coin' alt='fakecoin' src={fakecoinImg} />;
export const fakecoinLarge = <img style={{ width: '48px' }} className='coin' alt='fakecoin' src={fakecoinImg} />;
export const fakecoinMedium = <img style={{ width: '38px' }} className='coin' alt='fakecoin' src={fakecoinImg} />;
export const lootcoin = <img className='coin' alt='lootcoin' src={lootcoinImg} />;
export const lootcoinLarge = <img style={{ width: '48px' }} className='coin' alt='lootcoin' src={lootcoinImg} />;
export const lootcoinMedium = <img style={{ width: '38px' }} className='coin' alt='lootcoin' src={lootcoinImg} />;
export const legendarycoin = <img className='coin' alt='legendarycoin' src={legendarycoinImg} />;
export const levelEmoji = <img className='coin' alt='level' src={levelImg} />;
export const attackEmoji = <img className='coin' alt='attack' src={attackImg} />;
export const xpEmoji = <img className='coin' alt='xp' src={xpImg} />;
export const honorEmoji = <img className='coin' alt='honor' src={honorImg} />;
export const hpEmoji = <img className='coin' alt='hp' src={hpImg} />;
export const trophyEmoji = <img className='coin' alt='trophy' src={trophyImg} />;
export const dofusOneEmoji = <img className='coin' alt='emerald dofus' src={dofusOneImg} />;
export const dofusTwoEmoji = <img className='coin' alt='crimson dofus' src={dofusTwoImg} />;
export const dofusThreeEmoji = <img className='coin' alt='turquoise dofus' src={dofusThreeImg} />;
export const dofusFourEmoji = <img className='coin' alt='ochre dofus' src={dofusFourImg} />;
export const dofusFiveEmoji = <img className='coin' alt='ivory dofus' src={dofusFiveImg} />;
export const dofusSixEmoji = <img className='coin' alt='ebony dofus' src={dofusSixImg} />;
export const rouletteEmoji = <img className='coin' alt='roulette' src={rouletteImg} />;
export const dailyEmoji = <img className='coin' alt='daily' src={dailyImg} />;
export const rewardEmoji = <img className='coin' alt='reward' src={rewardImg} />;
export const betEmoji = <img className='coin' alt='bet' src={betImg} />;
export const yesEmoji = <img className='coin' alt='yes' src={yesImg} />;
export const noEmoji = <img className='coin' alt='no' src={noImg} />;
export const nitroEmoji = <img className='coin' alt='nitro' src={nitroImg} />;
export const bossEmoji = <img className='coin' alt='boss' src={bossImg} />;

export const resetEmoji = <img className='coin' alt='reset' src={rstarImg} />;
export const bronzeEmoji = <img className='coin' alt='bronze' src={bronzeImg} />;
export const ironEmoji = <img className='coin' alt='iron' src={ironImg} />;
export const goldEmoji = <img className='coin' alt='gold' src={goldImg} />;
export const platiniumEmoji = <img className='coin' alt='platinium' src={platiniumImg} />;
export const diamondEmoji = <img className='coin' alt='diamond' src={diamondImg} />;
export const legendEmoji = <img className='coin' alt='legend' src={legendImg} />;

export const getImageFromReward = (reward, large) => {
  switch (reward.type) {
    case 'lootcoin':
      return <img style={{ width: large ? '48px' : '24px' }} className='coin' alt='lootcoin' src={lootcoinImg} />;
    case 'fakecoin':
      return <img style={{ width: large ? '48px' : '24px' }} className='coin' alt='fakecoin' src={fakecoinImg} />;
    case 'randomSkin':
      return <img style={{ width: large ? '48px' : '24px' }} className='coin' alt='random skin' src={randomSkinImg} />;
    case 'ornament':
      switch (reward.id) {
        case 3:
          return (
            <div>
              <img style={{ width: large ? '48px' : '24px' }} className='coin' alt={`ornament left vip ${reward.id + 1}`} src={ornamentVip4LeftImg} />
              <img style={{ width: large ? '48px' : '24px' }} className='coin' alt={`ornament right vip ${reward.id + 1}`} src={ornamentVip4RightImg} />
            </div>
          );
        case 8:
          return (
            <div>
              <img style={{ width: large ? '48px' : '24px' }} className='coin' alt={`ornament left vip ${reward.id + 1}`} src={ornamentVip9LeftImg} />
              <img style={{ width: large ? '48px' : '24px' }} className='coin' alt={`ornament right vip ${reward.id + 1}`} src={ornamentVip9RightImg} />
            </div>
          );
      }
      break;
    case 'energy':
      return <img style={{ width: large ? '48px' : '24px' }} className='coin' alt='energy' src={energyImg} />;
    case 'energyMax':
      return <img style={{ width: large ? '48px' : '24px' }} className='coin' alt='energy' src={energyMaxImg} />;
    case 'premium':
      return <img style={{ width: large ? '48px' : '24px' }} className='coin' alt='premium' src={premium4} />;
    case 'chestSilver':
      return <img style={{ width: large ? '48px' : '24px' }} className='coin' alt='silver chest' src={silverChestImg} />;
    case 'chestGold':
      return <img style={{ width: large ? '48px' : '24px' }} className='coin' alt='gold chest' src={goldChestImg} />;
    case 'chestLegendary':
      return <img style={{ width: large ? '48px' : '24px' }} className='coin' alt='legendary chest' src={legendaryChestImg} />;
    case 'legendarycoin':
      return <img style={{ width: large ? '48px' : '24px' }} className='coin' alt='legendary chest' src={legendarycoinImg} />;
  }
};

export const getDescriptionFromReward = (reward) => {
  switch (reward.type) {
    case 'lootcoin':
      return (
        <span>
          Get {numberWithSpaces(reward.amount, true)} {getImageFromReward(reward, false)}
        </span>
      );
    case 'fakecoin':
      return (
        <span>
          Get {numberWithSpaces(reward.amount, true)} {getImageFromReward(reward, false)}
        </span>
      );
    case 'randomSkin':
      return `Get ${reward.amount} random skin`;
    case 'ornament':
      return `Get a unique ornament`;
    case 'energy':
      return `Get ${reward.amount} energy to use now`;
    case 'energyMax':
      return `Get +${reward.amount} max energy forever`;
    case 'premium':
      return `Get ${reward.amount} days of your current premium level + 1 (up to premium 4)`;
    case 'chestSilver':
      return `Get a silver chest including some rewards`;
    case 'chestGold':
      return `Get a golden chest including multiple rewards`;
    case 'chestLegendary':
      return `Get a legendary chest including huge rewards`;
  }
};

export const ornaments = [
  {
    id: 1,
    name: '4k',
    left: o_4k_left,
    right: o_4k_right,
  },
  {
    id: 2,
    name: '6k',
    left: o_6k_left,
    right: o_6k_right,
  },
  {
    id: 3,
    name: '8k',
    left: o_8k_left,
    right: o_8k_right,
  },
  {
    id: 4,
    name: '10k',
    left: o_10k_left,
    right: o_10k_right,
  },
  {
    id: 5,
    name: '12k',
    left: o_12k_left,
    right: o_12k_right,
  },
  {
    id: 6,
    name: '14k',
    left: o_14k_left,
    right: o_14k_right,
  },
  {
    id: 7,
    name: '16k',
    left: o_16k_left,
    right: o_16k_right,
  },
  {
    id: 8,
    name: '18k',
    left: o_18k_left,
    right: o_18k_right,
  },
  {
    id: 9,
    name: '20k',
    left: o_20k_left,
    right: o_20k_right,
  },
  {
    id: 10,
    name: 'Brakmar',
    left: o_brakmar_left,
    right: o_brakmar_right,
  },
  {
    id: 11,
    name: 'Bonta',
    left: o_bonta_left,
    right: o_bonta_right,
  },
  {
    id: 12,
    name: 'Challenges',
    left: o_challenges_left,
    right: o_challenges_right,
  },
  {
    id: 13,
    name: 'Sram',
    left: o_sram_left,
    right: o_sram_right,
  },
  {
    id: 14,
    name: 'Xelor',
    left: o_xelor_left,
    right: o_xelor_right,
  },
  {
    id: 15,
    name: 'Ecaflip',
    left: o_ecaflip_left,
    right: o_ecaflip_right,
  },
  {
    id: 16,
    name: 'Enutrof',
    left: o_enutrof_left,
    right: o_enutrof_right,
  },
  {
    id: 17,
    name: 'om475',
    left: o_om475_left,
    right: o_om475_right,
  },
  {
    id: 18,
    name: 'Baleine',
    left: o_baleine_left,
    right: o_baleine_right,
  },
  {
    id: 19,
    name: 'Chafer crit',
    left: o_chafercrit_left,
    right: o_chafercrit_right,
  },
  {
    id: 20,
    name: 'Chafer',
    left: o_chafer_left,
    right: o_chafer_right,
  },
  {
    id: 21,
    name: 'Saint Valentin',
    left: o_saintvalentin_left,
    right: o_saintvalentin_right,
  },
  {
    id: 22,
    name: 'Battlepass Season 1 : Rainbow',
    left: o_bp1_left,
    right: o_bp1_right,
  },
  {
    id: 23,
    name: 'Battlepass Season 2 : Snowflake',
    left: o_bp2_left,
    right: o_bp2_right,
  },
  {
    id: 35,
    name: 'Nitro',
    left: o_nitro_left,
    right: o_nitro_right,
  },
];
export const pvpRanks = [bronzeEmoji, ironEmoji, goldEmoji, platiniumEmoji, diamondEmoji, legendEmoji];
export const universes = {
  lol: {
    emoji: '<:LoL:833057255796375624>',
    name: 'League of Legends',
    image: <img className='coin' alt='league of legends' src={'https://cdn.discordapp.com/emojis/833057255796375624.png'} />,
  },
  dofus: {
    emoji: '<:Dofus:833057255774748732>',
    name: 'Dofus',
    image: <img className='coin' alt='dofus' src={dofusEmoji} />,
  },
};
export const families = [
  {
    emoji: '',
    name: '',
    image: '',
  },
  {
    emoji: '<:eliotrope:804084116882456626>',
    name: 'Eliotrope',
    image: <img className='coin' alt='Eliotrope' src={eliotropeEmoji} />,
  },
  {
    emoji: '<:huppermage:804084116815085568>',
    name: 'Huppermage',
    image: <img className='coin' alt='Huppermage' src={huppermageEmoji} />,
  },
  {
    emoji: '<:roublard:804084116723204118>',
    name: 'Roublard',
    image: <img className='coin' alt='Roublard' src={roublardEmoji} />,
  },
  {
    emoji: '<:sacrieur:771469960576368660>',
    name: 'Sacrieur',
    image: <img className='coin' alt='Sacrieur' src={sacrieurEmoji} />,
  },
];
