import React, { useState } from 'react';
import { Alert, Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { shopcoin } from '../../helpers/env';
import { numberWithSpaces } from '../../helpers/functions';
import { getLocale } from '../../helpers/lang';

const MonthlyPass = ({ selectedPlan, setSelectedPlan, toggle, plans, lang, isSales }) => {
  return (
    <Card className='monthly-pass'>
      <CardHeader>
        <CardTitle tag='h2'>Monthly Pass</CardTitle>
      </CardHeader>
      <Alert className='br-0' color='primary'>
        {getLocale(lang, 'pack', 'monthlyPassDescription1')} 270 {shopcoin} {getLocale(lang, 'pack', 'monthlyPassDescription2')} <kbd>/play</kbd>{' '}
        {getLocale(lang, 'pack', 'monthlyPassDescription3')}
      </Alert>
      <CardBody>
        <div className='plan-selector'>
          {plans.map((plan, key) => (
            <div className={`plan plan-${key} ${selectedPlan === key && 'active'}`} key={key} onClick={() => setSelectedPlan(key)}>
              <div className='d-flex justify-content-between'>
                <div className='plan-title'>
                  {isSales && plan.salesOnly ? isSales.emoji : ''} {isSales ? plan.salesTitle[lang] : plan.title[lang]}
                </div>
                {key === 0 && (
                  <div>
                    <Badge color='success'>{isSales && plan.salesOnly ? getLocale(lang, 'pack', 'promotion') : getLocale(lang, 'pack', 'bestValue')}</Badge>
                  </div>
                )}
              </div>
              <div className='d-flex justify-content-between'>
                <div className='plan-price'>
                  {getLocale(lang, 'pack', 'pricePerMonth', [plan.price])}
                  {Math.floor(plans[plans.length - 1].price * 12 - plan.price * 12) > 0 && (
                    <div className='text-muted'>{getLocale(lang, 'pack', 'savePerYear', [Math.floor(plans[plans.length - 1].price * 12 - plan.price * 12)])}</div>
                  )}
                </div>
                <div>
                  {numberWithSpaces(plan.amount)} {shopcoin}
                </div>
              </div>
            </div>
          ))}
          <hr />
          <div className='plan-total d-flex justify-content-between'>
            <div>
              {selectedPlan < plans.length - 1 ? (
                <div className='text-success'>
                  {plans[selectedPlan].months * plans[selectedPlan].price}€ <s className='text-muted'>{plans[selectedPlan].months * plans[plans.length - 1].price}€</s>
                </div>
              ) : (
                <div>{plans[selectedPlan].months * plans[selectedPlan].price}€</div>
              )}
              <div className='text-muted'>{getLocale(lang, 'pack', 'billedOnce')}</div>
            </div>
            <div>
              {selectedPlan < plans.length - 1 && (
                <Badge color='success'>
                  -{Math.floor(((plans[selectedPlan].months * plans[plans.length - 1].price) / (plans[selectedPlan].months * plans[selectedPlan].price) - 1) * 100)}%
                </Badge>
              )}
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <Button className='price' color='info' block onClick={toggle}>
          {getLocale(lang, 'pack', 'subscribeButton')}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default MonthlyPass;
