import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import PricingPlans from '../../components/Homepage/PricingPlans';
import p1Img from '../../assets/img/premium/p1.png';
import PricingTable from '../../components/Homepage/PricingTable';
import ElonnaImg from '../../assets/img/players/elonna.png';
import GwoudodoImg from '../../assets/img/players/gwoudodo.png';
import JackytuningImg from '../../assets/img/players/jackytuning.png';
import LibelluleImg from '../../assets/img/players/libellule.png';
import SymbelzImg from '../../assets/img/players/symbelz.png';
import TresouiImg from '../../assets/img/players/tresoui.png';
import { getLocale } from '../../helpers/lang';
import { useLangStore } from '../../helpers/store';

const Premium = () => {
  const lang = useLangStore(state => state.lang);
  const playersImages = [ElonnaImg, GwoudodoImg, JackytuningImg, LibelluleImg, SymbelzImg, TresouiImg];
  return (
    <div className='App'>
      <header className='App-header premium-header'>
        <section className='titles'>
          <div>
            <img src={p1Img} alt='premium' />
            <h1>{getLocale(lang, 'premium', 'title')}</h1>
          </div>
          <h2>{getLocale(lang, 'premium', 'subtitle1')}</h2>
          <h3 className='text-muted'>{getLocale(lang, 'premium', 'subtitle2')}</h3>
          <PricingPlans lang={lang} />
        </section>
      </header>
      <section className='section-separator'>
        <Col className='title' lg={6}>
          <div>
            <h2>{getLocale(lang, 'premium', 'usedByPlayers', ['100'])}</h2>
          </div>
        </Col>
        <Col className='servers-list'>
          {playersImages.map((emoji, index) => {
            return <img src={emoji} key={index} alt={`player ${index}`} />;
          })}
        </Col>
      </section>
      <section>
        <PricingTable lang={lang} />
      </section>
    </div>
  );
};

export default Premium;
