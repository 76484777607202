import React, { Fragment, useContext, useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Row, TabContent, TabPane, Button } from 'reactstrap';
import Clock from '../../components/Items/Clock';
import ItemsNav from '../../components/Items/ItemsNav';
import OrnamentCard from '../../components/Items/OrnamentCard';
import SkinCard from '../../components/Items/SkinCard';
import Loading from '../../components/Loading';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import { buyAnItem, getItems } from '../../helpers/API';
import { capitalize } from '../../helpers/functions';
import SpotlightSkins from '../../components/Items/SpotlightSkins';
import ServiceCard from '../../components/Items/ServiceCard';
import { getLocale } from '../../helpers/lang';
import { fakecoin, fakecoinLarge, fakecoinMedium, lootcoin, lootcoinLarge, lootcoinMedium } from '../../helpers/env';
import CoinCard from '../../components/Items/CoinCard';

import token1 from '../../assets/img/packs/token1.png';
import token2 from '../../assets/img/packs/token2.png';
import token3 from '../../assets/img/packs/token3.png';
import token4 from '../../assets/img/packs/token4.png';
import token5 from '../../assets/img/packs/token5.png';

const Items = () => {
  const { user } = useContext(AuthenticatedContext);
  const [verticalTabs, setVerticalTabs] = useState('coins');
  const [skins, setSkins] = useState(null);
  const [ornaments, setOrnaments] = useState(null);
  const [services, setServices] = useState(null);
  const [deadline, setDeadline] = useState(new Date(0));

  const coinsList = [
    {
      id: 1,
      name: `Poignée de fakecoins`,
      type: 'fakecoin',
      amount: 500000,
      price: 400,
      bonus: 1,
      image: token1,
    },
    {
      id: 2,
      name: `Bourse de fakecoins`,
      type: 'fakecoin',
      amount: 3437500,
      price: 2500,
      bonus: 10,
      image: token2,
    },
    {
      id: 3,
      name: `Sac de fakecoins`,
      type: 'fakecoin',
      amount: 7500000,
      price: 5000,
      bonus: 20,
      image: token3,
    },
    {
      id: 4,
      name: `Chapeau de fakecoins`,
      type: 'fakecoin',
      amount: 16250000,
      price: 10000,
      bonus: 30,
      image: token4,
    },
    {
      id: 5,
      name: `Chaudron de fakecoins`,
      type: 'fakecoin',
      amount: 52500000,
      price: 30000,
      bonus: 40,
      image: token5,
    },
    {
      id: 6,
      name: `Poignée de jetons`,
      type: 'lootcoin',
      amount: 1000,
      price: 400,
      bonus: 1,
      image: token1,
    },
    {
      id: 7,
      name: `Bourse de jetons`,
      type: 'lootcoin',
      amount: 6875,
      price: 2500,
      bonus: 10,
      image: token2,
    },
    {
      id: 8,
      name: `Sac de jetons`,
      type: 'lootcoin',
      amount: 15000,
      price: 5000,
      bonus: 20,
      image: token3,
    },
    {
      id: 9,
      name: `Chapeau de jetons`,
      type: 'lootcoin',
      amount: 32500,
      price: 10000,
      bonus: 30,
      image: token4,
    },
    {
      id: 10,
      name: `Chaudron de jetons`,
      type: 'lootcoin',
      amount: 105000,
      price: 30000,
      bonus: 40,
      image: token5,
    },
  ];

  const tabList = [
    {
      name: `Coins`,
      id: `coins`,
    },
    {
      name: getLocale(user.lang, 'items', 'skins'),
      id: getLocale(user.lang, 'items', 'permanentSkins'),
    },
    {
      name: getLocale(user.lang, 'items', 'ornaments'),
      id: getLocale(user.lang, 'items', 'ornaments'),
    },
    {
      name: getLocale(user.lang, 'items', 'services'),
      id: getLocale(user.lang, 'items', 'services'),
    },
  ];

  const buyItem = async (item, id) => {
    const status = await buyAnItem(item, id);
    return status;
  };

  const loadSkins = async () => {
    setSkins(await getItems('skins'));
  };
  const loadOrnaments = async () => {
    setOrnaments(await getItems('ornaments'));
  };
  const loadServices = async () => {
    setServices(await getItems('services'));
  };
  if (verticalTabs === getLocale(user.lang, 'items', 'permanentSkins') && !skins) {
    // load skins
    loadSkins();
  } else if (verticalTabs === getLocale(user.lang, 'items', 'ornaments') && !ornaments) {
    loadOrnaments();
    // load ornaments
  } else if (verticalTabs === getLocale(user.lang, 'items', 'services') && !services) {
    loadServices();
    // load titles
  }
  if (skins) {
    var skinsTab = skins.map((skin, key) => {
      return <SkinCard skin={skin} money={user.shopcoin} collection={user.skin.character.owned} buyItem={buyItem} key={key} />;
    });
  }
  if (ornaments) {
    var ornamentsTab = ornaments.map((ornament, key) => {
      return <OrnamentCard ornament={ornament} money={user.shopcoin} collection={user.ornaments} buyItem={buyItem} key={key} />;
    });
  }
  if (services) {
    var servicesTab = services.map((service, key) => {
      return <ServiceCard service={service} money={user.shopcoin} buyItem={buyItem} key={key} />;
    });
  }
  return (
    <Fragment>
      {/* <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle className='my-0' tag='h2'>
                <Clock deadline={deadline} lang={user.lang} />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <SpotlightSkins money={user.shopcoin} collection={user.skin.character.owned} buyItem={buyItem} setDeadline={setDeadline} />
            </CardBody>
          </Card>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <ItemsNav tabs={[verticalTabs, setVerticalTabs]} tabList={tabList} />
              <CardTitle className='mb-0 mt-3' tag='h2'>
                {capitalize(verticalTabs)}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={verticalTabs}>
                <TabPane className={verticalTabs === `coins` ? 'd-flex flex-column gap-1' : ''} tabId={`coins`}>
                  <h2 className='d-flex justify-content-center align-items-center gap-10 text-white mb-0 mt-5'>{fakecoinMedium} Fakecoin</h2>
                  <div className='d-flex flex-wrap'>
                    {coinsList
                      .filter((coin) => coin.type === 'fakecoin')
                      .map((coin, key) => {
                        return <CoinCard coin={coin} key={key} buyItem={buyItem} />;
                      })}
                  </div>
                  <h2 className='d-flex justify-content-center align-items-center gap-10 text-white mb-0 mt-5'>{lootcoinMedium} Jetons</h2>
                  <div className='d-flex flex-wrap'>
                    {coinsList
                      .filter((coin) => coin.type === 'lootcoin')
                      .map((coin, key) => {
                        return <CoinCard coin={coin} key={key} buyItem={buyItem} />;
                      })}
                  </div>
                </TabPane>
                <TabPane
                  className={verticalTabs === getLocale(user.lang, 'items', 'permanentSkins') ? 'd-flex flex-wrap' : ''}
                  tabId={getLocale(user.lang, 'items', 'permanentSkins')}
                >
                  {skinsTab ? skinsTab : <Loading />}
                </TabPane>
                <TabPane className={verticalTabs === getLocale(user.lang, 'items', 'ornaments') ? 'd-flex flex-wrap' : ''} tabId={getLocale(user.lang, 'items', 'ornaments')}>
                  {ornamentsTab ? ornamentsTab : <Loading />}
                </TabPane>
                <TabPane className={verticalTabs === getLocale(user.lang, 'items', 'services') ? 'd-flex flex-wrap' : ''} tabId={getLocale(user.lang, 'items', 'services')}>
                  {/* <h2 className='text-center'>There is nothing here yet...</h2> */}
                  {servicesTab ? servicesTab : <Loading />}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Items;
