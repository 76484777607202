import React, { Fragment } from 'react';
import { Badge, Button, Card, CardBody, CardFooter } from 'reactstrap';
import pack1 from '../../assets/img/packs/pack1.png';
import pack2 from '../../assets/img/packs/pack2.png';
import pack3 from '../../assets/img/packs/pack3.png';
import pack4 from '../../assets/img/packs/pack4.png';
import pack5 from '../../assets/img/packs/pack5.png';
import { shopcoin } from '../../helpers/env';
import { numberWithSpaces } from '../../helpers/functions';
import { getLocale } from '../../helpers/lang';

const packs = ['', pack1, pack2, pack3, pack4, pack5];
const SinglePack = (props) => {
  const toggle = props.toggle;
  return (
    <Card className={`pack pack-card-${props.num}`}>
      <div className='d-flex justify-content-end extra'>
        <div className='d-flex flex-column'>
          {props.extra && props.extra > 0 && (
            <Badge className={props.bonus && props.bonus > 0 ? 'inactive' : ''} color='primary'>
              {props.bonus && props.bonus > 0 ? (
                <s>
                  {props.extra}% {getLocale(props.lang, 'pack', 'extra')}
                </s>
              ) : (
                <Fragment>
                  {props.extra}% {getLocale(props.lang, 'pack', 'extra')}
                </Fragment>
              )}
            </Badge>
          )}
          {props.bonus && props.bonus > 0 ? (
            <Badge color='success'>
              {props.bonus + (props.extra ? props.extra : 0)}% {getLocale(props.lang, 'pack', 'extra')}
            </Badge>
          ) : null}
          {props.vip && props.vip > 0 ? (
            <Badge color='info'>
              +{props.vip}% {getLocale(props.lang, 'pack', 'vipExtra')}
            </Badge>
          ) : null}
        </div>
      </div>
      <CardBody className='text-center pb-0'>
        <div className='flex-1 d-flex align-items-center'>
          <img className='pack-img' src={packs[props.num]} alt={`pack ${props.num}`} />
        </div>
        {/* {props.sales && props.sales > 0 ? (
          <div className='amount mt-3'>
            <del>{numberWithSpaces(props.amount)}</del>
          </div>
        ) : null} */}
        <div className='amount'>
          {props.sales && props.sales > 0 ? (
            <Fragment>
              <div className='amount mt-3 fw-bold'>
                <del className='text-muted'>
                  {numberWithSpaces(props.amount)} {shopcoin}
                </del>
                <br />
                {props.vip && props.vip > 0 ? (
                  <span className='fs-1'>
                    <span className='text-success'>{numberWithSpaces(props.sales, true)}</span>
                    {props.vip && props.vip > 0 ? (
                      <Fragment>
                        <span className='font-weight-bold'> + </span>
                        <span className='text-info font-weight-bold'>{numberWithSpaces(parseInt(props.sales * (props.vip / 100)), true)}</span>
                      </Fragment>
                    ) : null}{' '}
                    {shopcoin}
                  </span>
                ) : null}
                <div className='text-success'>
                  {numberWithSpaces(parseInt(props.sales * (1 + props.vip / 100)), true)} {shopcoin}
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className={props?.vip > 0 ? 'fs-1' : 'd-none'}>
                {numberWithSpaces(props.amount, true)}{' '}
                {props.vip && props.vip > 0 ? (
                  <Fragment>
                    <span> + </span>
                    <span className='text-info font-weight-bold'>{numberWithSpaces(parseInt(props.amount * (props.vip / 100)), true)}</span>
                  </Fragment>
                ) : null}{' '}
                {shopcoin}
              </div>
              <div>
                {numberWithSpaces(parseInt(props.amount * (1 + props.vip / 100)), true)} {shopcoin}
              </div>
            </Fragment>
          )}{' '}
        </div>
      </CardBody>
      <CardFooter className='d-flex'>
        <Button className='price' onClick={toggle} color='primary' block>
          {props.price} {props.currency}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default SinglePack;
