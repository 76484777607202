import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthenticatedProvider } from './contexts/Authenticated';
import GlobalLayout from './layouts/Global/Global.js';
import HomeLayout from './layouts/Global/Home.js';
import AdminLayout from './layouts/Global/Admin.js';
// import './assets/scss/black-dashboard-pro-react.scss';
import './assets/css/black-dashboard-pro-react.css';
import './assets/css/nucleo-icons.css';
import Login from './views/Login/Login';
import './assets/demo/demo.css';
import Logout from './components/Auth/Logout';
import './App.css';
import axios from 'axios';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ReactGA from 'react-ga';
const TRACKING_ID = 'G-YNQQ42J620'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
axios.defaults.withCredentials = true;

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route exact path='/' render={(props) => <Login />} /> */}
      <Route exact path='/' render={(props) => <HomeLayout {...props} />} />
      <Route exact path='/premium' render={(props) => <HomeLayout {...props} />} />
      <Route exact path='/terms' render={(props) => <HomeLayout {...props} />} />
      <Route exact path='/privacy' render={(props) => <HomeLayout {...props} />} />
      {/* <Route path='/login' render={(props) => <Redirect {...props} />} /> */}
      <AuthenticatedProvider>
        <Route path='/shop' render={(props) => <GlobalLayout {...props} />} />
        <Route path='/admin' render={(props) => <AdminLayout {...props} />} />
        <Route path='/logout' render={(props) => <Logout />} />
      </AuthenticatedProvider>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
