import React, { Fragment, useContext, useState } from 'react';
import { Alert, Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Modal, Row, Tooltip } from 'reactstrap';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import { equipItem } from '../../helpers/API';
import { families, shopcoin, universes } from '../../helpers/env';
import { numberWithSpaces } from '../../helpers/functions';
import { getLocale } from '../../helpers/lang';

const SkinCard = ({ skin, money, collection, buyItem }) => {
  const { user, setUser } = useContext(AuthenticatedContext);
  const [modal, setModal] = useState(false);
  const [tooltipOriginalOpen, setTooltipOriginalOpen] = useState(false);
  const [tooltipNewOpen, setTooltipNewOpen] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const family = families.find((f) => f.emoji === skin.emoji);
  const universe = universes[skin.universe.name.toLowerCase()];
  const owned = collection.includes(skin.id);
  const toggleModal = () => {
    setModal(!modal);
  };

  const confirmOrderItem = async (skin) => {
    const owned = collection.includes(skin.id);
    if (owned) {
      equipItem(skin.rotation ? 'rotation' : 'skins', skin.id);
      setSuccess(getLocale(user.lang, 'items', 'skinEquiped', [skin.name]));
    } else {
      const res = await buyItem('skins', skin.id);
      if (res.status === 'success') {
        collection.push(skin.id);
        setSuccess(getLocale(user.lang, 'items', 'skinAddedToCollection', [skin.name]));
        setError(null);
        setUser({ ...user, shopcoin: user.shopcoin - skin.price });
      } else {
        setError(`Uh oh: ${res && res.message ? res.message : 'something happened.'}`);
        setSuccess(null);
      }
    }
    setModal(null);
  };
  const release = new Date(skin.release._seconds * 1000);
  const now = new Date();
  // is there 2 weeks beetween now and release?
  const isNew = now.getTime() < release.getTime() + 2 * 7 * 24 * 60 * 60 * 1000;
  return (
    <Card className='card-skin'>
      <CardBody className='d-flex flex-column justify-content-between'>
        <img className='skin-image' src={skin.image} alt={skin.name} />
        <div className='mt-3'>
          {skin.stock <= 5 && skin.stock > 0 ? <Alert color='warning'>{getLocale(user.lang, 'items', 'almostSoldOut', [skin.stock])}</Alert> : ''}
          {skin.stock <= 0 ? <Alert color='danger'>{getLocale(user.lang, 'items', 'outOfStock')}</Alert> : ''}
          {success ? <Alert color='success'>{success}</Alert> : ''}
          {error ? <Alert color='danger'>{error}</Alert> : null}
          <div className='text-right'>
            {skin.original ? (
              <Fragment>
                <Badge id={`original-${skin.id}`} color='success'>
                  {getLocale(user.lang, 'items', 'original')}
                </Badge>
                <Tooltip flip target={`original-${skin.id}`} isOpen={tooltipOriginalOpen} toggle={() => setTooltipOriginalOpen(!tooltipOriginalOpen)}>
                  {getLocale(user.lang, 'items', 'originalDescription')}
                </Tooltip>
              </Fragment>
            ) : null}
            {isNew ? (
              <Fragment>
                <Badge id={`new-${skin.id}`} color='info'>
                  {getLocale(user.lang, 'items', 'new')}
                </Badge>
                <Tooltip flip target={`new-${skin.id}`} isOpen={tooltipNewOpen} toggle={() => setTooltipNewOpen(!tooltipNewOpen)}>
                  {getLocale(user.lang, 'items', 'newDescription')}
                </Tooltip>
              </Fragment>
            ) : null}
          </div>
          <div className='text-right'>
            {universe.image} {universe.name}
          </div>
          <div className='title skin-title text-right'>
            {family.image} {skin.name}
          </div>
        </div>
      </CardBody>
      <Modal className='skin-preview' isOpen={modal} toggle={toggleModal}>
        <Card className='paypal-card'>
          <CardHeader>
            <CardTitle tag='h2'>{getLocale(user.lang, 'items', 'confirmTitle')}</CardTitle>
          </CardHeader>
          <CardBody>
            {owned ? (
              <Fragment>
                <div className='d-flex justify-content-center mb-3 skin-img'>
                  <img src={skin.image} alt={skin.name} />
                </div>
                {getLocale(user.lang, 'items', 'iWantToEquip')} {family.image} <b>{skin.name}</b>
              </Fragment>
            ) : (
              <Fragment>
                <div className='d-flex justify-content-center mb-3 skin-img'>
                  <img src={skin.image} alt={skin.name} />
                </div>
                {getLocale(user.lang, 'items', 'confirmDescription1')} {family.image} <b>{skin.name}</b> {getLocale(user.lang, 'items', 'confirmDescription2')}{' '}
                <b>
                  {numberWithSpaces(skin.price)} {shopcoin}
                </b>
              </Fragment>
            )}
          </CardBody>
          <CardFooter className='pt-0'>
            <Row>
              <Col>
                <Button className='btn-simple' color='danger' size='lg' onClick={() => setModal(false)} block>
                  {getLocale(user.lang, 'global', 'cancel')}
                </Button>
              </Col>
              <Col>
                <Button color='info' size='lg' onClick={() => confirmOrderItem(skin)} block>
                  {getLocale(user.lang, 'global', 'confirm')}
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Modal>
      <Button color={owned ? 'info' : 'primary'} onClick={toggleModal} disabled={!owned && (skin.stock <= 0 || money <= skin.price)} block>
        {owned ? (
          getLocale(user.lang, 'items', 'equip')
        ) : (
          <Fragment>
            {numberWithSpaces(skin.price)} {shopcoin}
          </Fragment>
        )}
      </Button>
    </Card>
  );
};

export default SkinCard;
