import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col } from 'reactstrap';
import { attackEmoji, fakecoin, hpEmoji, legendarycoin, levelEmoji, lootcoin, ornaments, pvpRanks, resetEmoji, shopcoin, trophyEmoji } from '../../../helpers/env';
import { numberWithSpaces } from '../../../helpers/functions';
import moment from 'moment';
import 'moment/locale/fr';
import { getExchangesByUid, getOrdersByUid } from '../../../helpers/API';
import SortingTable from '../../SortingTable/SortingTable';
import Loading from '../../Loading';
import Roulette from './DataCards/Roulette';
import Daily from './DataCards/Daily';
import Mission from './DataCards/Mission';
import TotalSpent from './DataCards/TotalSpent';
import { useHistory } from 'react-router';
import ManualOrder from './ManualOrder';
import LoginHistory from './DataCards/loginHistory';
moment().format();
moment.locale('fr');

const PlayerPage = ({ player, directLink }) => {
  const history = useHistory();
  if (history.location.pathname === '/admin/players') {
    history.push(`${history.location.pathname}/${player.id}`);
  }
  const customUsername = () => {
    const found = ornaments.find((o) => o.id === player.ornements.equip.id);
    if (!found)
      return (
        <div className='username text-center'>
          {player.username}
          {player.title.equip ? `, ${player.title.equip}` : ''}
        </div>
      );
    return (
      <div className='username d-flex flex-column'>
        <div className='d-flex justify-content-center align-items-center'>
          <img className='ornament' alt={`${found.name} left`} src={found.left} />
          {player.username}
          <img className='ornament' alt={`${found.name} right`} src={found.right} />
        </div>
        {player.title.equip ? (
          <div className='text-center' style={{ fontSize: '1.25rem' }}>
            {player.title.equip}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const [orders, setOrders] = useState(null);
  const [exchanges, setExchanges] = useState(null);
  const [totalSpent, setTotalSpent] = useState({
    total: 0,
    date: new Date(0),
  });
  const devises = [
    {
      currency: 'EUR',
      symbol: '€',
    },
    {
      currency: 'USD',
      symbol: '$',
    },
    {
      currency: 'GBP',
      symbol: '£',
    },
    {
      currency: 'CHF',
      symbol: 'Fr',
    },
  ];
  const loadExchanges = async () => {
    const rawExchanges = await getExchangesByUid(player.id);
    const tab = [];
    rawExchanges.forEach((exchange) => {
      tab.push({
        data: [
          // { text: exchange.id, string: exchange.id },
          { text: moment(exchange.date._seconds * 1000).format('lll'), string: exchange.date._seconds },
          {
            className: 'text-center',
            text: (
              <Fragment>
                {numberWithSpaces(exchange.amount)} {shopcoin}
              </Fragment>
            ),
            string: Number(exchange.amount),
          },
          {
            className: 'text-center',
            text:
              exchange.type === 'fakecoin' ? (
                <Fragment>
                  {numberWithSpaces(exchange.fake)} {fakecoin}
                </Fragment>
              ) : (
                <Fragment>
                  {numberWithSpaces(exchange.loot)} {lootcoin}
                </Fragment>
              ),
            string: exchange.type === 'fakecoin' ? Number(exchange.fake) : Number(exchange.loot),
          },
          {
            className: 'text-center',
            text: <i className='tim-icons icon-check-2 text-success'></i>,
            string: 'success',
          },
        ],
      });
    });
    if (tab.length === 0) {
      tab.push({
        data: [
          // { text: '', string: 'Nothing' },
          { text: '', string: 'Nothing' },
          { className: 'text-center', text: '', string: 'Nothing' },
          {
            className: 'text-center',
            text: '',
            string: 'Nothing',
          },
          { className: 'text-center', text: '', string: 'Nothing' },
        ],
      });
    }
    setExchanges(tab);
  };
  const loadOrders = async () => {
    const rawOrders = await getOrdersByUid(player.id);
    const tab = [];
    let total = 0;
    let lastOrder = new Date(0);
    rawOrders.forEach((order) => {
      total += Number(order.price);
      if (lastOrder.getTime() === 0) {
        lastOrder = new Date(order.update_time);
      }
      if (order.type === 'pass') order.amount = 0;
      tab.push({
        data: [
          { text: order.pay_id, string: order.pay_id },
          { text: moment(new Date(order.update_time)).format('lll'), string: order.update_time },
          { text: order.description, string: Number(order.amount) },
          {
            className: 'text-center',
            text: `${Number(order.price)} ${devises.find((c) => c.currency === order.currency_code).symbol || order.currency}`,
            string: Number(order.price),
          },
          {
            className: 'text-center',
            text: order.manual_transaction ? <i className='tim-icons icon-simple-remove text-danger'></i> : <i className='tim-icons icon-check-2 text-success'></i>,
            string: order.manual_transaction ? 'fail' : 'success',
          },
        ],
      });
    });
    setTotalSpent({ total: total, date: lastOrder });
    if (tab.length === 0) {
      tab.push({
        data: [
          { text: '', string: 'Nothing' },
          { text: '', string: 'Nothing' },
          { text: '', string: 'Nothing' },
          {
            className: 'text-center',
            text: '',
            string: 'Nothing',
          },
          { className: 'text-center', text: '', string: 'Nothing' },
        ],
      });
    }
    setOrders(tab);
  };
  if (!orders) {
    loadOrders();
  }
  if (!exchanges) {
    loadExchanges();
  }

  const [logHistory, setLogHistory] = useState(null);
  const loadLoginHistory = async () => {
    const rawHistory = player.shop.history.reverse();
    const tab = [];
    rawHistory.forEach((history) => {
      tab.push({
        data: [
          {
            text: (
              <Fragment>
                <div className='login-history' to={`players/${player.id}`}>
                  <div className='photo mr-3'>
                    <img alt={`${player.username}'s avatar`} src={player.avatar} />
                  </div>{' '}
                  <div>{player.username}</div>
                </div>
              </Fragment>
            ),
            string: player.username,
          },
          { text: moment(history._seconds * 1000).format('lll'), string: history._seconds },
        ],
      });
    });
    if (tab.length === 0) {
      tab.push({
        data: [
          { text: '', string: 'Nothing' },
          { text: '', string: 'Nothing' },
        ],
      });
    }
    setLogHistory(tab);
  };
  useEffect(() => {
    loadLoginHistory();
  }, []);
  return (
    <Fragment>
      <Col className='d-flex flex-column align-items-center' xl={3}>
        <img className='mb-3' src={player.avatar} alt={`${player.username}'s avatar`} />
        <div className='display-4'>{customUsername()}</div>
        {player.guild && player.guild.id !== 0 && player.guild.name ? (
          <div style={{ fontSize: '1.25rem' }}>
            {player.guild.rank === 1 ? '👑' : ''} {player.guild.name}
          </div>
        ) : null}
        <div className='text-muted italic'>{player.dofus.date._seconds > 0 ? `Actif le ${moment(new Date(player.dofus.date._seconds * 1000)).format('LLL')}` : `Inactif`}</div>
        <div className='d-flex justify-content-between align-items-center py-2 w-100'>
          <div className='d-flex flex-column align-items-center'>
            <span>{resetEmoji}</span>
            {player.stats.reset.count}
          </div>
          <div className='d-flex flex-column align-items-center'>
            {levelEmoji}
            {player.level.level}
          </div>
          <div className='d-flex flex-column align-items-center'>
            {attackEmoji}
            {numberWithSpaces(player.attack)}
          </div>
          <div className='d-flex flex-column align-items-center'>
            {hpEmoji}
            {numberWithSpaces(parseInt(300 * player.level.level * (player.ratio ? player.ratio : 1)))}
          </div>
          <div className='d-flex flex-column align-items-center'>
            {pvpRanks[player.pvp.rank]}
            {numberWithSpaces(player.pvp.points)}
          </div>
          <div className='d-flex flex-column align-items-center'>
            <span>{trophyEmoji}</span>
            {numberWithSpaces(player.success.points)}
          </div>
        </div>
        <div className='d-flex justify-content-evenly align-items-center py-2 w-100'>
          <div className='d-flex flex-column align-items-center'>
            <span>{shopcoin}</span>
            {numberWithSpaces(player.shopcoin)}
          </div>
          <div className='d-flex flex-column align-items-center'>
            {fakecoin}
            {numberWithSpaces(parseInt(player.money))}
          </div>
          <div className='d-flex flex-column align-items-center'>
            {lootcoin}
            {numberWithSpaces(player.lootbox)}
          </div>
          <div className='d-flex flex-column align-items-center'>
            {legendarycoin}
            {numberWithSpaces(player.legendaryCoin)}
          </div>
        </div>
        <ManualOrder user={player} />
      </Col>
      <Col>
        <div className='d-flex flex-wrap'>
          <TotalSpent total={totalSpent} vip={player.vip && player.vip.level ? player.vip.level : 0} />
          <Roulette dofus={player.dofus} stats={player.stats.dofus} />
          <Daily daily={player.daily} stats={player.stats.reward} />
          <Mission idle={player.idle} stats={player.stats.mission} />
        </div>
        <Card>
          <CardHeader>
            <CardTitle tag='h2'>Commandes</CardTitle>
          </CardHeader>
          <CardBody>
            {orders ? (
              <SortingTable
                thead={[{ text: '#ID' }, { text: 'Date' }, { text: 'Pack' }, { className: 'text-center', text: 'Price' }, { className: 'text-center', text: 'Status' }]}
                tbody={orders}
              />
            ) : (
              <div className='text-center'>
                <Loading />
              </div>
            )}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle tag='h2'>Derniers échanges</CardTitle>
          </CardHeader>
          <CardBody>
            {exchanges ? (
              <SortingTable
                thead={[
                  // { text: '#ID' },
                  { text: 'Date' },
                  { className: 'text-center', text: 'From' },
                  { className: 'text-center', text: 'To' },
                  { className: 'text-center', text: 'Status' },
                ]}
                tbody={exchanges}
              />
            ) : (
              <div className='text-center'>
                <Loading />
              </div>
            )}
          </CardBody>
        </Card>
        <LoginHistory logHistory={logHistory} />
      </Col>
    </Fragment>
  );
};

export default PlayerPage;
