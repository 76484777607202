import React, { Fragment, useContext, useState } from 'react';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, Row, Tooltip } from 'reactstrap';
import { AuthenticatedContext } from '../../contexts/Authenticated';
import { createExchange, getExchanges } from '../../helpers/API';
import { fakecoin, lootcoin, shopcoin } from '../../helpers/env';
import { numberWithSpaces } from '../../helpers/functions';
import moment from 'moment';
import 'moment/locale/fr';
import SortingTable from '../../components/SortingTable/SortingTable';
import Loading from '../../components/Loading';
import { getLocale } from '../../helpers/lang';
moment().format();
moment.locale('fr');

const Exchange = () => {
  const [shopAmount, setShopAmount] = useState(0);
  const [fakeAmount, setFakeAmount] = useState(0);
  const [lootAmount, setLootAmount] = useState(0);
  const [currentCoin, setCurrentCoin] = useState(0);
  const [trade, setTrade] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [firstTimeTyping, setFirstTimeTyping] = useState(true);
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [exchanges, setExchanges] = useState(null);
  const [exchangesData, setExchangesData] = useState(null);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const formatter = new Intl.NumberFormat('fr-FR');
  const { user, setUser } = useContext(AuthenticatedContext);
  const ratio = {
    fake: 112,
    loot: 0.14,
  };

  const toggleModal = () => {
    if (currentCoin === 1) {
      setFakeAmount(parseInt(shopAmount * ratio.fake));
    } else {
      const shop = (lootAmount / ratio.loot) % 10 ? parseInt(lootAmount / ratio.loot) + 1 : lootAmount / ratio.loot;
      setShopAmount(shop);
      setLootAmount(parseInt(shop * ratio.loot));
    }
    setModal(!modal);
  };
  const handleAmount = (e) => {
    if (firstTimeTyping) {
      setFirstTimeTyping(false);
      setTooltipOpen(true);
    }
    const value = Number(e.target.value.replace(/\s+/g, ''));
    if (isNaN(value)) return;
    if (e.target.name === 'shopcoin') {
      setShopAmount(value);
      setFakeAmount(parseInt(value * ratio.fake));
      setLootAmount(parseInt(value * ratio.loot));
    } else if (e.target.name === 'fakecoin') {
      setShopAmount((value / ratio.fake) % 10 ? parseInt(value / ratio.fake) + 1 : value / ratio.fake);
      setFakeAmount(value);
      setLootAmount(parseInt((value / ratio.fake) * ratio.loot));
    } else if (e.target.name === 'lootcoin') {
      setShopAmount((value / ratio.loot) % 10 ? parseInt(value / ratio.loot) + 1 : value / ratio.loot);
      setFakeAmount(parseInt((value / ratio.loot) * ratio.fake));
      setLootAmount(value);
    }
  };

  const recalculateAmount = () => {
    const name = currentCoin ? 'fakecoin' : 'lootcoin';
    if (name === 'lootcoin') {
      setFakeAmount(parseInt(shopAmount * ratio.fake));
    } else if (name === 'fakecoin') {
      const shop = (lootAmount / ratio.loot) % 10 ? parseInt(lootAmount / ratio.loot) + 1 : lootAmount / ratio.loot;
      setShopAmount(shop);
      setLootAmount(parseInt(shop * ratio.loot));
    }
    setCurrentCoin(currentCoin ? 0 : 1);
  };

  const sendExchange = async () => {
    setModal(false);
    const res = await createExchange(shopAmount, currentCoin ? 'fakecoin' : 'lootcoin');
    if (res.status === 'success') {
      setError(null);
      setSuccess(getLocale(user.lang, 'exchange', 'successful'));
      setTrade({ amount: currentCoin ? fakeAmount : lootAmount, type: currentCoin });
      loadExchanges([
        {
          amount: shopAmount,
          fake: currentCoin ? fakeAmount : 0,
          loot: currentCoin ? 0 : lootAmount,
          type: currentCoin ? 'fake' : 'loot',
          date: { _seconds: new Date().getTime() / 1000 },
        },
        ...exchangesData,
      ]);
      console.log([
        {
          amount: shopAmount,
          fake: currentCoin ? fakeAmount : 0,
          loot: currentCoin ? 0 : lootAmount,
          type: currentCoin ? 'fake' : 'loot',
          date: { _seconds: new Date().getTime() / 1000 },
        },
        ...exchangesData,
      ]);
      if (currentCoin) {
        setUser({
          ...user,
          shopcoin: user.shopcoin - shopAmount,
          money: user.money + fakeAmount,
        });
      } else {
        setUser({
          ...user,
          shopcoin: user.shopcoin - shopAmount,
          lootbox: user.lootbox + lootAmount,
        });
      }
    } else if (res.status === 'error') {
      setSuccess(null);
      setError(`Exchange failed: ${res.message}`);
    }
  };

  const loadExchanges = async (data) => {
    const rawExchanges = data ? data : await getExchanges();
    const tab = [];
    rawExchanges.forEach((exchange) => {
      tab.push({
        data: [
          { text: moment(exchange.date._seconds * 1000).format('lll'), string: exchange.date._seconds },
          {
            className: 'text-center',
            text: (
              <Fragment>
                {numberWithSpaces(exchange.amount, true)} {shopcoin}
              </Fragment>
            ),
            string: Number(exchange.amount),
          },
          {
            className: 'text-center',
            text:
              exchange.type === 'fakecoin' ? (
                <Fragment>
                  {numberWithSpaces(exchange.fake, true)} {fakecoin}
                </Fragment>
              ) : (
                <Fragment>
                  {numberWithSpaces(exchange.loot, true)} {lootcoin}
                </Fragment>
              ),
            string: exchange.type === 'fakecoin' ? Number(exchange.fake) : Number(exchange.loot),
          },
          {
            className: 'text-center',
            text: <i className='tim-icons icon-check-2 text-success'></i>,
            string: 'success',
          },
        ],
      });
    });
    if (tab.length === 0) {
      tab.push({
        data: [
          { text: '', string: 'Nothing' },
          { className: 'text-center', text: '', string: 'Nothing' },
          {
            className: 'text-center',
            text: '',
            string: 'Nothing',
          },
          { className: 'text-center', text: '', string: 'Nothing' },
        ],
      });
    }
    setExchanges(tab);
    setExchangesData(rawExchanges);
  };

  if (!exchanges) {
    loadExchanges();
  }
  return (
    <Fragment>
      <Row>
        <Col sm={6} className='mx-auto'>
          <Card id='exchange'>
            <CardHeader>
              <CardTitle tag='h3'>
                {getLocale(user.lang, 'exchange', 'balance')} {numberWithSpaces(user.shopcoin, true)} {shopcoin}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {success && (
                <Alert className='d-flex align-items-center' color='success'>
                  {success} +{trade ? numberWithSpaces(trade.amount, true) : ''} {trade && trade.type ? fakecoin : lootcoin}
                </Alert>
              )}
              {error && <Alert color='danger'>{error}</Alert>}
              <div className='mw-50 mx-auto d-flex flex-column text-center'>
                <div>{getLocale(user.lang, 'exchange', 'description')}</div>
                <InputGroup className='m-0'>
                  <Input name='shopcoin' type='text' onChange={(e) => handleAmount(e)} value={formatter.format(shopAmount)} />
                  <InputGroupAddon addonType='append' className='shopcoin-menu'>
                    <InputGroupText>{shopcoin}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <div className='my-3'>
                  <i className='tim-icons icon-double-right' style={{ transform: 'rotate(90deg)' }} />
                </div>
                <InputGroup className='m-0'>
                  <Tooltip className='d-flex align-items-center' isOpen={tooltipOpen} target='coins-menu' toggle={toggleTooltip}>
                    {getLocale(user.lang, 'exchange', 'changeTips1')} {fakecoin} {getLocale(user.lang, 'exchange', 'changeTips2')}
                  </Tooltip>
                  <Input
                    name={currentCoin ? 'fakecoin' : 'lootcoin'}
                    type='text'
                    value={formatter.format(currentCoin ? fakeAmount : lootAmount)}
                    onChange={(e) => handleAmount(e)}
                  />
                  <InputGroupAddon addonType='append' id='coins-menu' onClick={() => recalculateAmount()}>
                    <InputGroupText>{currentCoin ? fakecoin : lootcoin}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <Modal isOpen={modal} toggle={toggleModal}>
                  <Card className='paypal-card'>
                    <CardHeader>
                      <CardTitle tag='h2'>{getLocale(user.lang, 'items', 'confirmTitle')}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      {getLocale(user.lang, 'exchange', 'confirmDescription1')}{' '}
                      <b>
                        {numberWithSpaces(shopAmount, true)} {shopcoin}
                      </b>{' '}
                      {getLocale(user.lang, 'exchange', 'confirmDescription2')}{' '}
                      <b>
                        {currentCoin ? numberWithSpaces(parseInt(shopAmount * ratio.fake), true) : numberWithSpaces(parseInt(shopAmount * ratio.loot), true)}{' '}
                        {currentCoin ? fakecoin : lootcoin}
                      </b>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col>
                          <Button className='btn-simple' color='danger' size='lg' onClick={() => setModal(false)} block>
                            {getLocale(user.lang, 'global', 'cancel')}
                          </Button>
                        </Col>
                        <Col>
                          <Button color='info' size='lg' onClick={() => sendExchange()} block>
                            {getLocale(user.lang, 'global', 'confirm')}
                          </Button>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Modal>
                <Button
                  className='my-3 d-flex justify-content-center align-items-center'
                  onClick={toggleModal}
                  color='info'
                  disabled={shopAmount <= user.shopcoin && shopAmount > 0 && (currentCoin ? fakeAmount > 0 : lootAmount > 0) ? false : true}
                >
                  {getLocale(user.lang, 'exchange', 'button')}{' '}
                  {currentCoin ? numberWithSpaces(parseInt(shopAmount * ratio.fake), true) : numberWithSpaces(parseInt(shopAmount * ratio.loot), true)}{' '}
                  {currentCoin ? fakecoin : lootcoin}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardHeader className='d-flex justify-content-between align-items-center'>
                <CardTitle tag='h2' className='my-0'>
                  {getLocale(user.lang, 'exchange', 'historyTitle')}
                </CardTitle>
                <div>
                  <Button color='primary' target='_newblank' rel='noreferrer' href='https://discord.gg/fake'>
                    {getLocale(user.lang, 'global', 'helpButton')}
                  </Button>
                </div>
              </CardHeader>
              {exchanges && exchangesData && exchanges.length === exchangesData.length ? (
                <SortingTable
                  thead={[
                    // { text: '#ID' },
                    { text: getLocale(user.lang, 'exchange', 'date') },
                    { className: 'text-center', text: getLocale(user.lang, 'exchange', 'from') },
                    { className: 'text-center', text: getLocale(user.lang, 'exchange', 'to') },
                    { className: 'text-center', text: getLocale(user.lang, 'exchange', 'status') },
                  ]}
                  tbody={exchanges}
                />
              ) : (
                <div className='text-center'>
                  <Loading />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Exchange;
