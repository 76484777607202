import React, { Fragment, useState } from 'react';
import { Button, Card, CardBody, Col, Form, Input, Row } from 'reactstrap';
import PlayerCard from '../../components/Admin/Players/PlayerCard';
import PlayerPage from '../../components/Admin/Players/PlayerPage';
import { getPlayerByUid, getPlayers } from '../../helpers/API';
import { useHistory } from 'react-router-dom';

const Players = (props) => {
  const [search, setSearch] = useState('');
  const [players, setPlayers] = useState([]);
  const history = useHistory();

  const searchUsers = async (e) => {
    e.preventDefault();
    if (search.length > 0) {
      const player = await getPlayers(search);
      if (player.status !== 'error') {
        setPlayers(player);
        history.push('/admin/players');
      }
    }
  };
  const getPlayerUid = async (uid) => {
    const player = await getPlayerByUid(uid);
    if (player.status !== 'error') {
      setPlayers(player);
    }
  };
  if (!props.match.isExact && players.length !== 1) {
    const uid = props.location.pathname.match(/[0-9]+/);
    if (uid.length === 1) {
      if (props.location.state && props.location.state.player) {
        setPlayers([props.location.state.player]);
        props.location.state.player = null;
      } else if (search.length === 0) {
        getPlayerUid(uid[0]);
      }
    }
  }
  if (players.length > 1) {
    var playersCards = players.map((player, key) => {
      return <PlayerCard key={key} player={player} />;
    });
  }
  return (
    <Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Form onSubmit={(e) => searchUsers(e)} autoComplete='off'>
                <Input className='text-center' value={search} name='search' style={{ fontSize: '1rem' }} type='text' onChange={(e) => setSearch(e.target.value)} />
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody className='d-flex flex-wrap'>
              {players.length > 1 ? playersCards : ''}
              {players.length === 1 ? <PlayerPage player={players[0]} directLink={true} /> : ''}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Players;
